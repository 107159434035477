import { Role, Subscription } from '../types/core';

export const SubscriptionDisplayNames: Record<Subscription, string> = {
  [Subscription.EMPLOYMENT]: 'Employment',
  [Subscription.EMPLOYMENT_US]: 'Employment US',
  [Subscription.HR]: 'HR',
  [Subscription.TAX]: 'Tax',
  [Subscription.TAX_US]: 'Tax US',
  [Subscription.TAX_UK]: 'Tax UK',
  [Subscription.DIAGRAM]: 'Diagram',
  [Subscription.INSURANCE_US]: 'Insurance US'
};

export const RoleDisplayNames: Record<Role, string> = {
  [Role.ADMIN]: 'Admin',
  [Role.BASIC]: 'Basic',
  [Role.COMPANY_ADMIN]: 'Company Admin',
  [Role.DATA_ENTRY]: 'Data Entry',
  [Role.INTERNAL]: 'Internal',
  [Role.RESTRICTED]: 'Restricted',
  [Role.SUPPORT]: 'Support',
  [Role.FREE_TRIAL]: 'Free Trial'
};

export const INTERNAL_API_KEY = 'INTERNAL_API_KEY';
