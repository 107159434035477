export enum ErrorType {
  Unauthorized,
  ApiFetchError
}

export interface IInternalAPIError extends Error {
  status: number;
  type: ErrorType;
  message: string;
  errorType?: string;
  url?: string;
}
