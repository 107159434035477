import { CuratorErrorType, ICuratorError } from './CuratorError';

export class CuratorEntityFetchError extends Error implements ICuratorError {
  public readonly status: number = 500;
  public readonly type: CuratorErrorType = CuratorErrorType.Load;
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, CuratorEntityFetchError.prototype);
  }
}
