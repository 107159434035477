import { Grid, Box, Typography, Checkbox } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EntitySelector } from '../../../components/form/EntitySelector';
import { TextField } from '../../../components/form/TextField';
import { getJurisdictions, getSectors } from '../state/actions';
import { selectJurisdictionsAsSelectOptions, selectSectorsAsSelectOptions } from '../state/selectors';
import { AccountEntity, AccountForm } from '../state/types';

type AccountFormProps = {
  account: AccountEntity | AccountForm;
  setAccount: (account: AccountEntity | AccountForm) => void;
};

export const AccountFormComponent: React.FC<AccountFormProps> = ({
  account,
  setAccount
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSectors.request());
    dispatch(getJurisdictions.request());
  }, [dispatch]);

  const sectors = useSelector(selectSectorsAsSelectOptions);
  const jurisdictions = useSelector(selectJurisdictionsAsSelectOptions);

  const handleChange = (value: any, name: string) => {
    if (name === 'id') {
      setAccount({
        ...account,
        [name]: value,
        tax: `${value}_TaxCA`,
        taxUS: `${value}_TaxUS`,
        employment: `${value}_LECA`,
        employmentUS: `${value}_LEUS`,
        hr: `${value}_HRCA`,
        insuranceUS: `${value}_InsuranceUS`,
        diagram: `${value}_Diagram`,
      });
    } else {
      setAccount({
        ...account,
        [name]: value
      });
    }
  };

  return (
    <>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={6}>
          <Box width="75%">
            <TextField
              label="ID"
              value={account.id}
              onChange={(value) => handleChange(value, 'id')}
              required={true}
            />
          </Box>
          <Box width="75%">
            <TextField
              label="Display Name"
              value={account.name}
              onChange={(value) => handleChange(value, 'name')}
              required={true}
            />
          </Box>
          <Box width="75%" mt={2}>
            <Typography variant="body1">Sector</Typography>
            <EntitySelector
              handleChange={(value) => handleChange(value.value, 'sector')}
              value={sectors.find((s) => s.value === account.sector) || null}
              objectList={sectors}
              title="Sector"
              isClearable={false}
            />
          </Box>
          <Box width="75%" mt={2}>
            <Typography variant="body1">Jurisdiction *</Typography>
            <EntitySelector
              handleChange={(value) => handleChange(value.value, 'jurisdiction')}
              value={jurisdictions.find((s) => s.value === account.jurisdiction) || null}
              objectList={jurisdictions}
              title="Jurisdiction"
              isClearable={false}
            />
          </Box>
          <Box mt={2} display="flex" alignItems="center">
            <Typography variant="h6">Do Not Email</Typography>
            <Checkbox
              checked={account.doNotEmail || false}
              onChange={(event) => handleChange(event.target.checked, 'doNotEmail')}
            />
          </Box>
        </Grid>
        <Grid item={true} xs={6}>
          <Box>
            <TextField
              label="Tax CA Account Subscription ID"
              value={account.tax}
              onChange={(value) => handleChange(value, 'tax')}
              required={true}
            />
          </Box>
          <Box>
            <TextField
              label="Tax US Account Subscription ID"
              value={account.taxUS}
              onChange={(value) => handleChange(value, 'taxUS')}
              required={true}
            />
          </Box>
          <Box>
            <TextField
              label="Employment CA Account Subscription ID"
              value={account.employment}
              onChange={(value) => handleChange(value, 'employment')}
              required={true}
            />
          </Box>
          <Box>
            <TextField
              label="Employment US Account Subscription ID"
              value={account.employmentUS}
              onChange={(value) => handleChange(value, 'employmentUS')}
              required={true}
            />
          </Box>
          <Box>
            <TextField
              label="HR Account Subscription ID"
              value={account.hr}
              onChange={(value) => handleChange(value, 'hr')}
              required={true}
            />
          </Box>
          <Box>
            <TextField
              label="Insurance US Account Subscription ID"
              value={account.insuranceUS}
              onChange={(value) => handleChange(value, 'insuranceUS')}
              required={true}
            />
          </Box>
          <Box>
            <TextField
              label="Diagram Account Subscription ID"
              value={account.diagram}
              onChange={(value) => handleChange(value, 'diagram')}
              required={true}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
