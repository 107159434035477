import { Epic } from 'redux-observable';
import { filter, first, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { ErrorType } from '../../../lib/errors/types';
import { Services } from '../../../services';
import { RootAction, failActions, successActions } from '../../../store/actions';
import { RootState } from '../../../store/root';

import { storeGenericError, clearGenericError } from './actions';

const catchApiErrorMessage: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(failActions)),
    first((action) => action.payload.type === ErrorType.ApiFetchError),
    map((action) => {
      return storeGenericError(action.payload);
    })
  );

const clearApiErrorMessage: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(successActions)),
    map(() => {
      return clearGenericError();
    })
  );

export const errorEpics = [
  catchApiErrorMessage,
  clearApiErrorMessage
];
