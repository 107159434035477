import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store/root';
import { createSelectTranslatedString } from '../state/selectors';

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  translatedString: createSelectTranslatedString(props)(state)
});

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  id?: string,
  language?: string
};

type TranslatedStringProps = StateProps & OwnProps;

class TranslatedStringContainerComponent extends React.Component<TranslatedStringProps> {
  render() {
    return (
      <>
        {this.props.translatedString}
      </>
    );
  }
}

export const TranslatedStringContainer =
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps)(TranslatedStringContainerComponent);
