import { createAsyncAction, createAction } from 'typesafe-actions';

import { ApiError } from '../../../lib/errors/ApiError';
import { Environment } from '../../../types/core';
import {
  CreateMLServiceConfig,
  DeleteMLServiceDetails,
  DeleteMLServiceConfig,
  MLServiceResponse,
  UpdateMLServiceConfig,
  PromoteMLServiceFeature,
  MLServicePromoteTargetResponse
} from '../types';

export const initMLServicesFeatureList = createAction('@@INTERNAL/ML_SERVICE/LOAD_FEATURE_LIST')<Environment>();

export const loadMLServicesForFeature = createAction('@@INTERNAL/ML_SERVICE/LOAD_SERVICES_FOR_FEATURE')<string>();

export const clearMLServices = createAction('@@INTERNAL/ML_SERVICE/CLEAR_SERVICES')<void>();

export const fetchMLServices = createAsyncAction(
  '@@INTERNAL/ML_SERVICE/FETCH_SERVICES/REQUEST',
  '@@INTERNAL/ML_SERVICE/FETCH_SERVICES/SUCCESS',
  '@@INTERNAL/ML_SERVICE/FETCH_SERVICES/FAILURE',
)<string, MLServiceResponse, ApiError>();

export const fetchPromoteTargetMLServices = createAsyncAction(
  '@@INTERNAL/ML_SERVICE/FETCH_PROMOTE_TARGET_SERVICES/REQUEST',
  '@@INTERNAL/ML_SERVICE/FETCH_PROMOTE_TARGET_SERVICES/SUCCESS',
  '@@INTERNAL/ML_SERVICE/FETCH_PROMOTE_TARGET_SERVICES/FAILURE',
)<string, MLServicePromoteTargetResponse, ApiError>();

export const openCreateMLService = createAction('@@INTERNAL/ML_SERVICE/OPEN_CREATE_SERVICE')<void>();
export const closeCreateMLService = createAction('@@INTERNAL/ML_SERVICE/CLOSE_CREATE_SERVICE')<void>();

export const createMLService = createAction('@@INTERNAL/ML_SERVICE/CREATE_SERVICE')<CreateMLServiceConfig>();
export const updateMLService = createAction('@@INTERNAL/ML_SERVICE/UPDATE_SERVICE')<UpdateMLServiceConfig>();

export const postCreateMLService = createAsyncAction(
  '@@INTERNAL/ML_SERVICE/POST_CREATE_SERVICE/REQUEST',
  '@@INTERNAL/ML_SERVICE/POST_CREATE_SERVICE/SUCCESS',
  '@@INTERNAL/ML_SERVICE/POST_CREATE_SERVICE/FAILURE'
)<CreateMLServiceConfig, void, ApiError>();

export const postUpdateMLService = createAsyncAction(
  '@@INTERNAL/ML_SERVICE/POST_UPDATE_SERVICE/REQUEST',
  '@@INTERNAL/ML_SERVICE/POST_UPDATE_SERVICE/SUCCESS',
  '@@INTERNAL/ML_SERVICE/POST_UPDATE_SERVICE/FAILURE'
)<UpdateMLServiceConfig, void, ApiError>();

export const deleteMLService = createAction('@@INTERNAL/ML_SERVICE/DELETE_SERVICE')<DeleteMLServiceDetails>();
export const confirmDeleteMLService = createAction('@@INTERNAL/ML_SERVICE/DELETE_SERVICE/CONFIRM')<DeleteMLServiceConfig>();
export const cancelDeleteMLService = createAction('@@INTERNAL/ML_SERVICE/DELETE_SERVICE/CANCEL')<void>();

export const postDeleteMLService = createAsyncAction(
  '@@INTERNAL/ML_SERVICE/POST_DELETE_SERVICE/REQUEST',
  '@@INTERNAL/ML_SERVICE/POST_DELETE_SERVICE/SUCCESS',
  '@@INTERNAL/ML_SERVICE/POST_DELETE_SERVICE/FAILURE'
)<DeleteMLServiceConfig, void, ApiError>();

export const promoteAllMLServices = createAction('@@INTERNAL/ML_SERVICE/PROMOTE_ALL')<void>();
export const confirmPromoteAllMLServices = createAction('@@INTERNAL/ML_SERVICE/PROMOTE_ALL/CONFIRM')<boolean>();
export const cancelPromoteAllMLServices = createAction('@@INTERNAL/ML_SERVICE/PROMOTE_ALL/CANCEL')<void>();
export const postPromoteAllMLServices = createAsyncAction(
  '@@INTERNAL/ML_SERVICE/PROMOTE_ALL/REQUEST',
  '@@INTERNAL/ML_SERVICE/PROMOTE_ALL/SUCCESS',
  '@@INTERNAL/ML_SERVICE/PROMOTE_ALL/FAILURE'
)<void, void, ApiError>();

export const promoteFeatureMLServices = createAction('@@INTERNAL/ML_SERVICE/PROMOTE_FEATURE')<PromoteMLServiceFeature>();
export const confirmPromoteFeatureMLServices = createAction('@@INTERNAL/ML_SERVICE/PROMOTE_FEATURE/CONFIRM')<PromoteMLServiceFeature>();
export const cancelPromoteFeatureMLServices = createAction('@@INTERNAL/ML_SERVICE/PROMOTE_FEATURE/CANCEL')<void>();
export const postPromoteFeatureMLServices = createAsyncAction(
  '@@INTERNAL/ML_SERVICE/PROMOTE_FEATURE/REQUEST',
  '@@INTERNAL/ML_SERVICE/PROMOTE_FEATURE/SUCCESS',
  '@@INTERNAL/ML_SERVICE/PROMOTE_FEATURE/FAILURE'
)<PromoteMLServiceFeature, void, ApiError>();

export const refreshMLServiceListing = createAction('@@INTERNAL/ML_SERVICE/REFRESH_LISTING')<void>();

export const clearAllMLServiceErrors = createAction('@@INTERNAL/ML_SERVICE/CLEAR_ALL_ERRORS')<void>();

export const showMLServiceMessage = createAction('@@INTERNAL/ML_SERVICE/SHOW_MESSAGE')<string>();
export const clearAllMLServiceMessages = createAction('@@INTERNAL/ML_SERVICE/CLEAR_ALL_MESSAGES')<void>();
