import { Grid } from '@material-ui/core';
import * as React from 'react';
import { MarkdownEditor } from './markdown-editor-component';

type MarkdownWrapperFormProps = {
  markdown: string,
  markdownFR: string,
  handleChange: (markdown: string) => void,
  handleChangeFR: (markdown: string) => void,
  title?: string,
  editButtonText?: string,
  titleFR?: string,
  editButtonTextFR?: string,
};

export const MarkdownWrapper: React.FC<MarkdownWrapperFormProps> = ({
  markdown,
  markdownFR,
  handleChange,
  handleChangeFR,
  title = 'English Markdown Body',
  editButtonText = 'Edit English Markdown',
  titleFR = 'French Markdown Body',
  editButtonTextFR = 'Edit French Markdown',
  
}) => {
  return (
    <>
      <Grid xs={6}>
        <MarkdownEditor
          title={title}
          markdown={markdown}
          handleChange={handleChange}
          editButtonText={editButtonText}
        />
      </Grid>
      <Grid xs={6}>
        <MarkdownEditor
          title={titleFR}
          markdown={markdownFR}
          handleChange={handleChangeFR}
          editButtonText={editButtonTextFR}
        />
      </Grid>
    </>
  );
};
