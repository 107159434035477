import { Box, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { findValueFromObject } from '../../../lib/util';
import { ActivationModuleFormComponent } from '../components/activation-module-form.component';
import { saveActivationModule } from '../state/actions';
import {
  selectActivationManagerModules,
  selectCurrentActivationManagerSubscription,
  selectRemainingActivationManagerTypes
} from '../state/selectors';
import { ActivationEntity, SAVE_MODE } from '../state/types';

export const EditActivationModuleContainer: React.FC = () => {
  const dispatch = useDispatch();
  const parentSubscription = useSelector(selectCurrentActivationManagerSubscription);
  const baseRemainingActivationManagerTypes = useSelector(selectRemainingActivationManagerTypes);
  const [remainingActivationManagerType,  setRemainingActivationManagerTypes2] = React.useState(baseRemainingActivationManagerTypes);
  const [activationModule, setActivationModule] = React.useState<ActivationEntity>(
    {
      _id: '',
      subscription: '',
      order: 0,
      title: '',
      title_fr: '',
      description: '',
      description_fr: '',
      body: '',
      body_fr: '',
      activationType: '',
      enabled: false,
    });
  const { id } = useParams<{id: string}>();
  const activationManagerModuleList = useSelector(selectActivationManagerModules);

  React.useEffect(() => {
    const initialActivationModule: ActivationEntity | undefined = findValueFromObject<ActivationEntity>('_id', id, activationManagerModuleList);
    if (initialActivationModule) {
      setActivationModule(initialActivationModule);
      // this is used so that the activation module while editing also includes its own type, since you could be making a mistake changing it
      setRemainingActivationManagerTypes2([...baseRemainingActivationManagerTypes, initialActivationModule.activationType]);
    }
  }, [activationManagerModuleList, baseRemainingActivationManagerTypes, id]);

  React.useEffect(() => {
    if (!parentSubscription) {
      dispatch(push('/activation-manager'));
    }
  }, [parentSubscription, dispatch]);

  const handleChange = (field: string, value: string | number | boolean) => {
    const updatedActivationModule = {
      ...activationModule,
      [field]: value
    };
    setActivationModule(updatedActivationModule);
  };

  const submitActivationModule = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    const moduleWithSubscription = {
      ...activationModule,
    } as ActivationEntity;
    dispatch(saveActivationModule(moduleWithSubscription, { mode: SAVE_MODE.EDIT }));
  }, [dispatch, activationModule]);

  return (
    <>
      <Box pb={3}>
        <Typography variant="h6">
          <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
          <BreadcrumbLink to={'/activation-manager'}>Activation Manager</BreadcrumbLink>
          / {id}
        </Typography>
      </Box>
      <MainContainerCardComponent>
        {
          activationModule._id ?
            (
              <ActivationModuleFormComponent
                remainingActivationTypes={remainingActivationManagerType}
                handleChange={handleChange}
                handleSubmit={submitActivationModule}
                activationModule={activationModule as ActivationEntity}
                subscription={parentSubscription!}
              />
            ) : (
            <Box>
              Activation Module not found
            </Box>
          )
        }
      </MainContainerCardComponent>
    </>
  );
};
