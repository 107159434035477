import { Box } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';

import { AccountListContainer } from './AccountListContainer';
import { AddAccountContainer } from './AddAccountContainer';
import EditAccountContainer from './EditAccountContainer';

const AccountManagerContainerComponent: React.FC<{}> = () => {
  return (
    <Box p={2}>
      <Switch>
        <Route exact={true} path="/account-manager/:env" component={AccountListContainer} />
        <Route path="/account-manager/:env/:accountId/edit" component={EditAccountContainer} />
        <Route path="/account-manager/:env/add" component={AddAccountContainer} />
      </Switch>
    </Box>
  );
};

export const AccountManagerContainer = connect()(AccountManagerContainerComponent);
