import { Box } from '@material-ui/core';
import React from 'react';

import { Environment } from '../../../types/core';
import { SelectOption } from '../../../types/util';

import { FeatureAvailabilityEnvironmentSelect } from './FeatureAvailabilityEnvironmentSelect';

type Props = {
  changeEnvironment: (value: Environment) => void,
  environments: SelectOption[],
  selectedEnvironment: SelectOption,
};

export const FeatureAvailabilityTopToolbar: React.FunctionComponent<Props> = ({ changeEnvironment, environments, selectedEnvironment }) => (
  <Box>
    <FeatureAvailabilityEnvironmentSelect
      onChange={changeEnvironment}
      environments={environments}
      selectedEnvironment={selectedEnvironment} />
  </Box>
);
