import { createAsyncAction } from 'typesafe-actions';

import { ApiError } from '../../../lib/errors/ApiError';
import { TranslatedString } from '../../../types/util';

export const getStringsFromIds = createAsyncAction(
  '@@INTERNAL/STRINGS/GET_STRINGS_FROM_IDS',
  '@@INTERNAL/STRINGS/GET_STRINGS_FROM_IDS_SUCCESS',
  '@@INTERNAL/STRINGS/GET_STRINGS_FROM_IDS_FAILURE'
)<string[], TranslatedString, ApiError>();
