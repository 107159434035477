import { Box, FormControl, Input, InputLabel, Typography } from '@material-ui/core';
import * as React from 'react';
import { useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';

import { SelectOption } from '../../../../../types/util';
import { AzureFormValues } from '../../../types';
import { createInputChangeHandler, createMultiSelectChangeHandler, idArrayToOptions } from '../form-helpers';

import { SpecificFormProps } from './createMLServiceTypeForm';

export const AzureServiceTypeForm: React.FunctionComponent<SpecificFormProps<AzureFormValues>> = ({ formValues, onChange, availableQuestions }) => {
  const availableQuestionOptions = useMemo(
    () => idArrayToOptions(availableQuestions.map(({ id }) => id).sort()),
    [availableQuestions]
  );
  return (
    <>
      <Box paddingY={1}>
        <FormControl fullWidth={true}>
          <InputLabel>Base Url</InputLabel>
          <Input value={formValues.baseUrl} onChange={createInputChangeHandler('baseUrl', onChange, formValues)} />
        </FormControl>
      </Box>
      <Box paddingY={1}>
        <FormControl fullWidth={true}>
          <InputLabel>Authorization Token</InputLabel>
          <Input value={formValues.authorizationToken}
                 onChange={createInputChangeHandler('authorizationToken', onChange, formValues)} />
        </FormControl>
      </Box>
      <Box paddingY={1}>
        <Typography color="textSecondary" variant="caption">Question Ids</Typography>
        <CreatableSelect<SelectOption>
          isMulti={true}
          options={availableQuestionOptions}
          value={idArrayToOptions(formValues.questionIds || [])}
          onChange={createMultiSelectChangeHandler('questionIds', onChange, formValues)}
        />
      </Box>
    </>
  );
};
