import { stringify } from 'qs';

import { CuratorEntityFetchError } from '../lib/errors/CuratorEntityFetchError';
import { UnauthorizedError } from '../lib/errors/UnauthorizedError';
import { CaseEntity } from '../types/case-types';
import {
  addFetchData,
  addPaginatedFetchData,
  PaginatedQuery,
  PaginationConfig, WithFetchMeta,
  WithPaginatedFetchMeta
} from '../types/fetch';

type StandardQueryOptions = { relations?: string[] };

export type CaseSearchQuery = {
  title?: string;
};

// @TODO: We should publish curator's API as a package and consume it here instead of copying -- next change we make here
//   should be to extract/publish the curator API into a package from the curator web package
export class CuratorApi {

  static generateQueryString(pagination: PaginationConfig, relations: string[] = []): string {
    const page = Math.max(1, pagination.page);
    const perPage = Math.max(5, pagination.perPage);
    return stringify({ page, per_page: perPage, relations }, { addQueryPrefix: true });
  }

  public getCase = this.createFetchStandardEntity<CaseEntity>('rest/case');

  public searchCases = this.createPaginatedFetchCaseQuery<CaseEntity, CaseSearchQuery>('case-search');

  private curatorToken: string = '';

  constructor(
    public readonly apiUrl: string
  ) {
  }

  public setToken(token: string): void {
    this.curatorToken = token;
  }

  protected baseHeaders(): Record<string, string> {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.curatorToken}`
    };
  }

  protected apiFetch(url: string, requestParams: RequestInit): Promise<Response> {
    return fetch(`${this.apiUrl}${url}`, {
      ...requestParams,
      headers: {
        ...this.baseHeaders(),
        ...(requestParams.headers || {})
      }
    }).then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          throw new UnauthorizedError(response.statusText);
        }
      }
      return response;
    });
  }

  protected createFetchStandardEntity<TEntity>(entityName: string) {
    return async (entityId: string, payload?: StandardQueryOptions): Promise<WithFetchMeta<TEntity>> => {
      const queryString = stringify(payload, { addQueryPrefix: true });
      const url = `/case-query/${entityName}/${entityId}${queryString}`;
      const response = await this.apiFetch(url, {});
      const responseBody: TEntity = await response.json();
      if (response.status >= 400) {
        throw new CuratorEntityFetchError(response.statusText);
      }
      return addFetchData<TEntity>(responseBody, payload);
    };
  }

  protected createPaginatedFetchCaseQuery<TEntity, TQuery = TEntity>(paginatedCommand: string) {
    return this.createPaginatedFetchStandardEntity<TEntity, TQuery>('case-query', paginatedCommand);
  }

  protected createPaginatedFetchStandardEntity<TEntity, TQuery>(apiSubRoute: string, paginatedCommand: string) {
    return async (payload: PaginatedQuery<TQuery>): Promise<WithPaginatedFetchMeta<TEntity>> => {
      const query = CuratorApi.generateQueryString(payload.pagination, payload.relations);
      const body = JSON.stringify({
        ...payload.filter
      });

      const response = await this.apiFetch(`/${apiSubRoute}/${paginatedCommand}${query}`, {
        method: 'POST',
        body
      });

      if (response.status >= 400) {
        throw new CuratorEntityFetchError(response.statusText);
      }

      const responsePagination = {
        page: Number(response.headers.get('X-Page')),
        perPage: Number(response.headers.get('X-Per-Page')),
        total: Number(response.headers.get('X-Total-Count'))
      };
      return addPaginatedFetchData<TEntity>((await response.json()), responsePagination, payload);
    };
  }
}
