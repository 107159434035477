import { createAsyncAction } from 'typesafe-actions';

import { ApiError } from '../../../lib/errors/ApiError';
import { FeatureEntity } from '../../../types/core';

export const fetchFeatures = createAsyncAction(
    '@@INTERNAL/BASE/GET_FEATURES/REQUEST',
    '@@INTERNAL/BASE/GET_FEATURES/SUCCESS',
    '@@INTERNAL/BASE/GET_FEATURES/FAILURE'
  )<undefined, FeatureEntity[], ApiError>();
