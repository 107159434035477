import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
  Typography
} from '@material-ui/core';
import * as R from 'ramda';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { BaseFormValues, MLServiceType, QuestionListItem } from '../../types';

import { createSwitchChangeHandler } from './form-helpers';
import { createMLServiceTypeForm } from './service-type-forms/createMLServiceTypeForm';

type Props<TFormValues> = {
  createMode: boolean;
  defaultAllowed?: boolean;
  onSubmit: (values: TFormValues) => void;
  onDelete?: () => void;
  initialData: Partial<TFormValues>;
  availableQuestions: QuestionListItem[];
  name?: string;
  onCancel?: () => void;
};

export function createMLServiceForm<TFormValues extends BaseFormValues>(type: MLServiceType, typeDisplayName: string, transformInitialDataToFormValues: (partial: Partial<TFormValues>) => TFormValues): React.FunctionComponent<Props<TFormValues>> {
  const ServiceForm = createMLServiceTypeForm<TFormValues>(type);
  return (
    {
      createMode,
      defaultAllowed,
      initialData,
      onDelete,
      onSubmit,
      onCancel,
      availableQuestions,
      name,
      children
    }
  ) => {
    const title = <Typography variant="h5">{typeDisplayName}</Typography>;
    const subHeader = <i>{name}</i>;

    const [formValues, setFormValues] = useState<TFormValues>(transformInitialDataToFormValues(initialData));

    useEffect(() => {
      setFormValues(transformInitialDataToFormValues(initialData));
    }, [initialData]);

    const onSubmitHandler = useCallback(() => {
      onSubmit(formValues);
    }, [onSubmit, formValues]);

    const onDeleteHandler = useCallback(() => {
      if (onDelete) {
        onDelete();
      }
    }, [onDelete]);

    const onCancelHandler = useCallback(() => {
      if (onCancel) {
        onCancel();
      }
    }, [onCancel]);

    const updateDisabled = R.equals(
      R.pick(Object.keys(formValues), initialData),
      formValues
    );

    return (
      <Box border={createMode ? 2 : 0} borderColor="lightgreen">
        <Card style={{ overflow: 'unset' }}>
          <CardHeader title={title} subheader={subHeader} />
          <CardContent>
            <Box paddingBottom={1}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={formValues.disabled}
                    onChange={createSwitchChangeHandler('disabled', setFormValues, formValues)}
                  />
                )}
                label="Disabled"
              />
              <FormControlLabel
                control={(
                  <Switch
                    checked={formValues.isDefault}
                    disabled={createMode && !defaultAllowed}
                    onChange={createSwitchChangeHandler('isDefault', setFormValues, formValues)}
                  />
                )}
                label="Default Service"
              />
            </Box>
            <ServiceForm
              formValues={formValues}
              onChange={setFormValues}
              availableQuestions={availableQuestions}
            />
            {children}
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              onClick={onSubmitHandler}
              disabled={updateDisabled}>
              {createMode ? 'Create' : 'Update'}
            </Button>
            {!createMode && (
              <Button color="secondary" onClick={onDeleteHandler}>Delete</Button>
            )}
            {createMode && (
              <Button color="secondary" onClick={onCancelHandler}>Cancel</Button>
            )}
          </CardActions>
        </Card>
      </Box>
    );
  };
}
