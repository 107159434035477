import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

import { Role } from '../../../types/core';
import { NAVIGATION_ITEMS } from '../constants';

import { ModuleItemComponent, IModuleItemProps } from './ModuleItemComponent';

export type ModuleListProps = {
  name: string,
  items: IModuleItemProps[],
  roles: Role[]
};

const generateModuleList = (modules: ModuleListProps[], roles: Role[]) =>
  modules.map((module: ModuleListProps) => {
    return module.roles.some((role) => roles.includes(role)) && (
      <Box key={module.name} mb={4}>
        <Typography variant="h5">{module.name}</Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {module.items.map((item) => (
            <ItemWrapper key={item.name}>
              <ModuleItemComponent {...item} />
            </ItemWrapper>
          ))}
        </Box>
      </Box>
    );
  });

type ModuleListComponentProps = {
  roles: Role[]
};

export const ModuleListComponent: React.FC<ModuleListComponentProps> = ({ roles }) => (
  <>
    {generateModuleList(NAVIGATION_ITEMS, roles)}
  </>
);

export const ItemWrapper = styled.div`
  margin: 1rem;
  width: 340px;
`;
