import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';

import { TextField } from '../../../components/form/TextField';
import { Subscription } from '../../../types/core';
import { Tag } from '../types';

type Props = {
  handleSubmit: (displayNameEnglish: string, displayNameFrench: string, subscriptions: string[]) => void;
  tag?: Tag
};

const MAX_DISPLAY_NAME_LENGTH = 25;

export const TagForm: React.FC<Props> = ({ tag, handleSubmit }) => {
  const [displayNameEnglish, setDisplayNameEnglish] = useState<string>(tag?.displayName_en || '');
  const [displayNameFrench, setDisplayNameFrench] = useState<string>(tag?.displayName_fr || '');
  const [subscriptions, setSubscriptions] = useState<string[]>(tag?.subscriptions || []);
  const isSubmitDisabled = displayNameEnglish.length === 0 || subscriptions.length === 0 || displayNameFrench.length > MAX_DISPLAY_NAME_LENGTH || displayNameEnglish.length > MAX_DISPLAY_NAME_LENGTH;

  const onSubscriptionCheckboxChange = (subscription: string) => {
    if (subscriptions.includes(subscription)) {
      return setSubscriptions(subscriptions.filter((value) => value !== subscription));
    }

    setSubscriptions([...subscriptions, subscription]);
  };

  return (
    <form onSubmit={(event) => {
      event.preventDefault();
      handleSubmit(displayNameEnglish, displayNameFrench, subscriptions);
    }}>
      <Grid container={true} spacing={2}>
        <Box flex={true}flexDirection="column" width="100%">
          <Box mb={2}>
            <TextField
              value={displayNameEnglish}
              label="Display Name (English)"
              onChange={setDisplayNameEnglish}
              required={true}
            />
            <Box mt={1} mb={1}>
              {displayNameEnglish.length > MAX_DISPLAY_NAME_LENGTH && <Typography color="error">Maximum length is {MAX_DISPLAY_NAME_LENGTH} characters</Typography>}
            </Box>
            <TextField
              value={displayNameFrench}
              label="Display Name (French)"
              onChange={setDisplayNameFrench}
              required={false}
            />
            <Box mt={1} mb={1}>
              {displayNameFrench.length > MAX_DISPLAY_NAME_LENGTH && <Typography color="error"> Maximum length is {MAX_DISPLAY_NAME_LENGTH} characters</Typography>}
            </Box>
          </Box>
          <Box mt={2}>
            <Grid container={true} spacing={2}>
              <Box display="flex" flexDirection="column" m={2}>
                <Typography variant="h6">Subscriptions</Typography>
                <Box display="flex" flexDirection="column">
                  { Object.values(Subscription).map((subscription) => {
                    return (
                      <FormControlLabel
                        key={subscription}
                        control={<Checkbox checked={subscriptions.includes(subscription)} onChange={() => onSubscriptionCheckboxChange(subscription)} />}
                        label={subscription}
                      />
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
        <Box m={2} width="100%">
          <Grid container={true} spacing={2}>
            <Button
              disabled={isSubmitDisabled}
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Grid>
        </Box>
      </Grid>
    </form>
  );
};
