import { Box, Button, Dialog, Typography } from '@material-ui/core';
import React, { PropsWithChildren, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreator, ActionType, PayloadActionCreator } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';

type RootActionType = ActionType<RootAction>;

type Props<TConfirmData> = {
  title: string,
  confirmData?: TConfirmData,
  onCancelAction: ActionCreator<RootActionType>,
  onConfirmAction: PayloadActionCreator<RootActionType, TConfirmData>,
};

export function MLServiceConfirmationModal<TConfirmData>(
  {
    title,
    confirmData,
    onCancelAction,
    onConfirmAction
  }: PropsWithChildren<Props<TConfirmData>>
) {
  const dispatch = useDispatch();
  const onCancel = useCallback(() => dispatch(onCancelAction()), [dispatch, onCancelAction]);
  const onConfirm = useCallback((data: TConfirmData) => {
    if (data) {
      dispatch(onConfirmAction(data));
    }
  }, [dispatch, onConfirmAction]);

  if (!confirmData) {
    return null;
  }

  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={onCancel}
    >
      <Box padding={4}>
        <Typography variant="h5">{title}</Typography>
        <Box marginTop={4}>
          {typeof confirmData === 'object' && Object.entries(confirmData).map(([key, value]) => typeof value === 'string' && (
            <Box key={key} alignItems="baseline" display="flex">
              <Typography>{`${key}: `}<strong>{value}</strong></Typography>
            </Box>
          ))}
        </Box>
        <Box display="flex" marginTop={5}>
          <Box marginRight={1}>
            <Button color="primary" onClick={() => onConfirm(confirmData)} variant="contained">
              Confirm
            </Button>
          </Box>
          <Button onClick={onCancel} variant="contained">
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
