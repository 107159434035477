import { AzureFormValues, MLServiceType } from '../../types';

import { createMLServiceForm } from './create-ml-service-form';

const transformInitialAzureFormData = (initialData: Partial<AzureFormValues>): AzureFormValues => ({
  disabled: !!initialData.disabled,
  isDefault: !!initialData.isDefault,
  questionIds: initialData.questionIds,
  baseUrl: initialData.baseUrl,
  authorizationToken: initialData.authorizationToken
});

export const AzureMLServiceForm = createMLServiceForm<AzureFormValues>(
  MLServiceType.Azure,
  'Azure Machine Learning',
  transformInitialAzureFormData
);
