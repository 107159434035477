import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';
import { RelatedStatuteSectionsManagerState } from '../types';

import * as relatedStatuteSectionsManagerActions from './actions';

export const relatedStatuteSectionsManagerInitialState = {
  availableDocuments: [],
  relatedStatuteSection: null
};

export const relatedStatuteSectionsReducer = createReducer<RelatedStatuteSectionsManagerState, RootAction>(relatedStatuteSectionsManagerInitialState)
  .handleAction(relatedStatuteSectionsManagerActions.fetchRelatedStatuteSectionsByDomain.success, (state, action) => {
    return {
      ...state,
      relatedStatuteSection: action.payload
    };
  })
  .handleAction(relatedStatuteSectionsManagerActions.fetchAvailableStatuteDocuments.success, (state, action) => {
    return {
      ...state,
      availableDocuments: action.payload
    };
  })
  .handleAction(relatedStatuteSectionsManagerActions.submitRelatedStatuteSection.success, (state, action) => {
    return {
      ...state,
      relatedStatuteSection: action.payload
    };
  })
  .handleAction(relatedStatuteSectionsManagerActions.deleteRelatedStatuteSection.success, (state, action) => {
    return {
      ...state,
      relatedStatuteSection: action.payload
    };
  });
