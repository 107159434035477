import { createAsyncAction } from 'typesafe-actions';

import { ApiError } from '../../../lib/errors/ApiError';
import { Environment } from '../../../types/core';

import { AccountEntity, AccountForm, JurisdictionEntity, SectorEntity } from './types';

type AccountForQuery = {
  account: string
  env: Environment
};

export const getAccounts = createAsyncAction(
  '@@INTERNAL/ACCOUNT_MANAGER/GET_ACCOUNTS',
  '@@INTERNAL/ACCOUNT_MANAGER/GET_ACCOUNTS_SUCCESS',
  '@@INTERNAL/ACCOUNT_MANAGER/GET_ACCOUNTS_FAILURE'
)<Environment, AccountEntity[], ApiError>();

export const deleteAccount = createAsyncAction(
  '@@INTERNAL/ACCOUNT_MANAGER/DELETE_ACCOUNT',
  '@@INTERNAL/ACCOUNT_MANAGER/DELETE_ACCOUNT_SUCCESS',
  '@@INTERNAL/ACCOUNT_MANAGER/DELETE_ACCOUNT_FAILURE'
)<AccountForQuery, void, ApiError>();

export const getAccount = createAsyncAction(
  '@@INTERNAL/ACCOUNT_MANAGER/GET_ACCOUNT',
  '@@INTERNAL/ACCOUNT_MANAGER/GET_ACCOUNT_SUCCESS',
  '@@INTERNAL/ACCOUNT_MANAGER/GET_ACCOUNT_FAILURE'
)<AccountForQuery, AccountEntity, ApiError>();

type AddAccountData = { accountData: AccountForm, env: Environment };
export const createAccount = createAsyncAction(
  '@@INTERNAL/ACCOUNT_MANAGER/CREATE_ACCOUNT',
  '@@INTERNAL/ACCOUNT_MANAGER/CREATE_ACCOUNT_SUCCESS',
  '@@INTERNAL/ACCOUNT_MANAGER/CREATE_ACCOUNT_FAILURE'
)<AddAccountData, void, ApiError>();

type EditAccountData = { accountData: AccountEntity, env: Environment };
export const editAccount = createAsyncAction(
  '@@INTERNAL/ACCOUNT_MANAGER/EDIT_ACCOUNT',
  '@@INTERNAL/ACCOUNT_MANAGER/EDIT_ACCOUNT_SUCCESS',
  '@@INTERNAL/ACCOUNT_MANAGER/EDIT_ACCOUNT_FAILURE'
)<EditAccountData, void, ApiError>();

export const getSectors = createAsyncAction(
  '@@INTERNAL/ACCOUNT_MANAGER/GET_SECTORS',
  '@@INTERNAL/ACCOUNT_MANAGER/GET_SECTORS_SUCCESS',
  '@@INTERNAL/ACCOUNT_MANAGER/GET_SECTORS_FAILURE'
)<void, SectorEntity[], ApiError>();

export const getJurisdictions = createAsyncAction(
  '@@INTERNAL/ACCOUNT_MANAGER/GET_JURISDICTIONS',
  '@@INTERNAL/ACCOUNT_MANAGER/GET_JURISDICTIONS_SUCCESS',
  '@@INTERNAL/ACCOUNT_MANAGER/GET_JURISDICTIONS_FAILURE'
)<void, JurisdictionEntity[], ApiError>();
