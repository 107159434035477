import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';
import { SelectOption } from '../../../types/util';
import { RelatedStatuteSectionsManagerState } from '../types';

const selectRelatedStatuteSectionsManagerState = (state: RootState): RelatedStatuteSectionsManagerState => state.relatedStatuteSectionsManager;

export const selectAvailableDocuments = createSelector(
  selectRelatedStatuteSectionsManagerState,
  (state) => state.availableDocuments
);

export const selectAvailableDocumentsAsSelectOption = createSelector(
  selectAvailableDocuments,
  (documents): Array<SelectOption<string>> => documents.map((doc) => {
    return {
      value: doc.document.id,
      label: `Title ${doc.document.title} - ${doc.document.titleName} (${doc.subscription})`
    };
  })
);

export const selectRelatedStatuteSection = createSelector(
  selectRelatedStatuteSectionsManagerState,
  (state) => state.relatedStatuteSection
);

export const selectStatuteSections = createSelector(
  selectRelatedStatuteSection,
  (relatedStatuteSections) => relatedStatuteSections ? relatedStatuteSections.sections : []
);
