import { Typography, Box } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { generateInternalCaseRelativeLink } from '../../../lib/util';
import { RootState } from '../../../store/root';
import { ModalState } from '../../../types/util';
import { QuestionReferenceFormComponent } from '../components/QuestionReferenceFormComponent';
import {
  getQuestionReferencesForQuestion,
  submitQuestionReferenceForQuestion,
  getQuestionsForFeature
} from '../state/actions';
import { selectQuestionReferencesList, selectCurrentQuestion, selectCurrentFeature } from '../state/selectors';
import { LANGUAGE_TYPE, QuestionReferenceEntity } from '../state/types';

import { CaseSearchContainer } from './CaseSearchContainer';

const mapStateToProps = (state: RootState) => ({
  questionReferencesList: selectQuestionReferencesList(state),
  question: selectCurrentQuestion(state),
  feature: selectCurrentFeature(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submitReference: (reference: QuestionReferenceEntity) => dispatch(submitQuestionReferenceForQuestion.request(reference)),
  pushDomainManger: () => dispatch(push('/domains')),
  getReferencesForQuestion: (questionId: string) => dispatch(getQuestionReferencesForQuestion.request(questionId)),
  getQuestionList: (feature: string) => dispatch(getQuestionsForFeature.request(feature))
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type RouteParams = {
  questionId: string
};

type AddQuestionReferenceProps = StateProps & DispatchProps & RouteComponentProps<RouteParams>;

type ComponentProps = {
  questionReference: QuestionReferenceEntity,
  caseSearchModal: ModalState
};

class AddQuestionReferenceComponent extends React.Component<AddQuestionReferenceProps, ComponentProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      questionReference: {
        id: '',
        title: {
          en: ''
        },
        link: {
          en: ''
        },
        excerpt: {
          en: ''
        },
        mongoQuestionId: '',
        questionId: ''
      },
      caseSearchModal: {
        open: false,
        handleCancel: this.handleCancel
      }
    };
  }

  handleCancel = () => {
    this.setState({
      ...this.state,
      caseSearchModal: {
        ...this.state.caseSearchModal,
        open: false
      }
    });
  }

  componentDidMount(): void {
    const mongoQuestionId = this.props.match.params.questionId;

    if (!mongoQuestionId) {
      this.props.pushDomainManger();
    } else {
      this.setState({
        ...this.state,
        questionReference: {
          ...this.state.questionReference,
          initialVoteCount: this.state.questionReference.initialVoteCount ? this.state.questionReference.initialVoteCount : 0,
          mongoQuestionId
        },
      });
      this.props.getReferencesForQuestion(mongoQuestionId);
    }
  }

  componentDidUpdate(prevProps: AddQuestionReferenceProps) {
    if (prevProps.match.params.questionId !== this.props.match.params.questionId) {
      this.props.getReferencesForQuestion(this.props.match.params.questionId);
    }
  }

  handleChange = (field: string, useInternationalization = true, lang: LANGUAGE_TYPE, value: string) => {
    this.setState({
      ...this.state,
      questionReference: {
        ...this.state.questionReference,
        [field]: useInternationalization ? { ...this.state.questionReference[field], [lang]: value } : value
      }
    });
  }

  handleSubmit = (questionId: string) => (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.props.submitReference({
      ...this.state.questionReference,
      questionId
    });
  }

  handleLinkClick = (lang: LANGUAGE_TYPE) => () => {
    this.setState({
      ...this.state,
      caseSearchModal: {
        ...this.state.caseSearchModal,
        lang,
        open: true,
      }
    });
  }

  handleLinkSelect = (curatorId: string) => {
    const link = generateInternalCaseRelativeLink(curatorId);
    const lang = this.state.caseSearchModal.lang === LANGUAGE_TYPE.EN ? 'en' : 'fr';
    this.setState({
      ...this.state,
      questionReference: {
        ...this.state.questionReference,
        link: {
          ...this.state.questionReference.link,
          [lang]: link
        }
      },
      caseSearchModal: {
        ...this.state.caseSearchModal,
        open: false
      }
    });
  }

  render() {
    return (
      <>
        <Box pb={3}>
          <Typography variant="h6">
            <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={'/domains'}>Domains</BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={`/domains/${this.props.feature && this.props.feature._id}/question-reference-manager`}>
              Question List
            </BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={`/question-reference-manager/${this.props.match.params.questionId}`}>
              References
            </BreadcrumbLink> / New Reference
          </Typography>
        </Box>
        <MainContainerCardComponent>
          <QuestionReferenceFormComponent
            questionReference={this.state.questionReference}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit(this.props.question && this.props.question.id ? this.props.question.id : '')}
            question={this.props.question}
            handleLinkClick={this.handleLinkClick}
          />
        </MainContainerCardComponent>
        <CaseSearchContainer modalState={this.state.caseSearchModal} handleSelect={this.handleLinkSelect} />
      </>
    );
  }
}

export const AddQuestionReferenceContainer =
  connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(AddQuestionReferenceComponent);
