import * as React from 'react';

import { BaseFormValues, MLServiceType } from '../../types';
import { AzureMLServiceForm } from '../service-forms/AzureMLServiceForm';
import { NoMLServiceForm } from '../service-forms/NoMLServiceForm';
import { ProphetMLServiceForm } from '../service-forms/ProphetMLServiceForm';
import { CreateMLServiceManagerProps } from '../types';

type CreateFormProps = {
  type: MLServiceType,
  name: string,
} & CreateMLServiceManagerProps;

export const MLServiceCreateForm: React.FunctionComponent<CreateFormProps> = (
  {
    type,
    feature,
    availableQuestions,
    onCreate,
    onCancel,
    name,
    defaultAllowed
  }
) => {

  function onSubmit<TFormValues extends BaseFormValues>(values: TFormValues) {
    onCreate({
      ...values,
      type,
      name,
      feature
    });
  }

  const baseProps = {
    createMode: true,
    defaultAllowed,
    feature,
    availableQuestions,
    name,
    onSubmit,
    onCancel,
    initialData: {}
  };

  switch (type) {
    case MLServiceType.Azure:
      return (
        <AzureMLServiceForm
          {...baseProps}
        />
      );
    case MLServiceType.NoService:
      return (
        <NoMLServiceForm
          {...baseProps}
        />
      );
    case MLServiceType.Prophet:
      return (
        <ProphetMLServiceForm
          {...baseProps}
        />
      );
  }
};
