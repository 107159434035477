import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const GlobalHeaderComponent = () => (
  <div>
    <AppBar position="static">
      <Toolbar>
        <Box mr={2} ml={-2}>
          <IconButton
            color="inherit"
            aria-label="Go Home"
            component={Link}
            {...{ to: '/' } as any}>
            <HomeIcon />
          </IconButton>
        </Box>
        <Box flexGrow={1}>
          <Typography variant="h6" color="inherit">
            Blue J Manage
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  </div>
);
