import { ChangeEvent } from 'react';
import { ValueType } from 'react-select/src/types';

import { SelectOption } from '../../../../types/util';

export function createInputChangeHandler<TValues>(key: keyof TValues, onChange: (values: TValues) => void, formValues: TValues) {
  return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...formValues,
      [key]: value
    });
  };
}

export function createSwitchChangeHandler<TValues>(key: keyof TValues, onChange: (values: TValues) => void, formValues: TValues) {
  return (_: any, checked: boolean) => {
    onChange({
      ...formValues,
      [key]: checked
    });
  };
}

export function createMultiSelectChangeHandler<TValues>(key: keyof TValues, onChange: (values: TValues) => void, formValues: TValues) {
  return (selectedOptions: ValueType<SelectOption>) => onChange({
    ...formValues,
    [key]: optionsToValueArray(selectedOptions instanceof Array ? selectedOptions : [])
  });
}

export function idArrayToOptions(ids: string[]) {
  return ids.map((id) => ({ value: id, label: id }));
}

export function optionsToValueArray(options: SelectOption[]): string[] {
  return options.map(({ value }) => value);
}
