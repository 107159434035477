import { Box, Button, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useEffect } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';

import { DataTable } from '../../../components/DataTable';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { Modal } from '../../../components/ui/Modal';
import {
  closeDeleteTagModal,
  deleteTag,
  getTags,
  openDeleteTagModal,
} from '../state/actions';
import {
  selectDeleteModalId,
  selectTags,
  createSelectTagTypes
} from '../state/selectors';
import { Tag } from '../types';

class TagManagerListTable extends DataTable<Tag> {}

type TagManagerListColumn = {
  name: string;
  title: string;
  getCellValue?: (row: Tag) => any;
};

const columns: TagManagerListColumn[] = [
  {
    name: 'id',
    title: 'ID'
  },
  {
    name: 'displayName_en',
    title: 'Display Name (EN)'
  },
  {
    name: 'displayName_fr',
    title: 'Display Name (FR)'
  },
  {
    name: 'subscriptions',
    title: 'Subscriptions',
    getCellValue: (row) => row.subscriptions.join(', ')
  }
];

const DeleteTagModalContents: React.FC<{
  typesTagged: string[]
}> = ({ typesTagged }) => {
  if (typesTagged.length === 0) {
    return <Typography>This tag is unused</Typography>;
  }

  return (
    <Box>
      <Typography color="secondary">This tag is currently assigned to:</Typography>
      <Box pl={1}>
        { typesTagged.map((line) => (
          <Typography key={line}>{line}</Typography>
        ))}
      </Box>
    </Box>
  );
};

export const TagManagerList: React.FC = () => {
  const tagManagerData = useSelector(selectTags);
  const deleteModalId = useSelector(selectDeleteModalId);
  const typesTagged = useSelector(createSelectTagTypes(deleteModalId));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTags.request());
  }, [dispatch]);
  return (
    <MainContainerCardComponent>
      <Box p={3}>
        <Button variant="contained" color="primary" onClick={() => dispatch(push(`${window.location.pathname}/create`))}>New Tag</Button>
      </Box>
      <Box>
        <TagManagerListTable
          columns={columns}
          data={tagManagerData}
          idPropGetter={(entity) => entity.id}
          actions={[
            {
              label: 'Edit',
              onClick: (id) => dispatch(push(`${window.location.pathname}/${id}`))
            },
            {
              label: 'Delete',
              onClick: (id) => {
                dispatch(openDeleteTagModal(id));
              },
              color: 'default'
            }
          ]}
        />
        <Box>
          <Modal
            title={`Delete Tag ${deleteModalId}`}
            onConfirm={() => dispatch(deleteTag.request(deleteModalId))}
            onCancel={() => dispatch(closeDeleteTagModal())}
            open={deleteModalId !== ''}
          >
            <Box>
              <DeleteTagModalContents typesTagged={typesTagged} />
            </Box>
          </Modal>
        </Box>
      </Box>
    </MainContainerCardComponent>
  );
};
