import { Box } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';

import { NoticeListContainerComponent } from './notice-manager-list.container';
import { AddNoticeContainer } from './add-notice.container';
import { EditNoticeContainer } from './edit-notice.container';

const NoticeManagerContainerComponent: React.FC<{}> = () => {

  return (
    <Box p={2}>
      <Switch>
        <Route exact={true} path="/notice-manager" component={NoticeListContainerComponent} />
        <Route path="/notice-manager/create" component={AddNoticeContainer} />
        <Route path="/notice-manager/:id" component={EditNoticeContainer} />
      </Switch>
    </Box>
  );
};

export const NoticeManagerContainer = connect()(NoticeManagerContainerComponent);
