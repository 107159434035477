import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store/root';
import { Environment } from '../../../types/core';
import { FeatureAvailabilityMatrix } from '../components/FeatureAvailabilityMatrix';
import { loadFeatureAvailabilityList } from '../state/actions';
import { selectFeatureNames } from '../state/selectors';

const mapStateToProps = (state: RootState) => ({
  features: selectFeatureNames(state),
});

const mapDispatchToProps = {
  onLoad: () => loadFeatureAvailabilityList(Environment.BETA)
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

const columns = [
  {
    name: 'featureName',
    title: 'Feature Name',
    getCellValue: (row: any) => row
  }
];

const FeatureAvailabilityListComponent: React.FunctionComponent<Props> = ({
  features,
  onLoad
}) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Box>
      {features.length === 0 ? (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          minHeight="420px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <FeatureAvailabilityMatrix
          columns={columns}
          rows={features}
        />
      )}
    </Box>
  );
};

export const FeatureAvailabilityList = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureAvailabilityListComponent);
