import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';

import * as questionReferenceManagerActions from './actions';
import { QuestionReferenceManagerState } from './types';

export const questionReferenceManagerInitialState: QuestionReferenceManagerState = {
  questionList: [],
  questionReferencesList: [],
  currentQuestion: undefined,
  currentFeature: undefined,
  curatorSearchResults: []
};

export const questionReferenceManagerReducer = createReducer<QuestionReferenceManagerState, RootAction>(questionReferenceManagerInitialState)
  .handleAction(questionReferenceManagerActions.getQuestionsForFeature.success, (state, action) => {
    return {
      ...state,
      questionList: action.payload
    };
  })
  .handleAction(questionReferenceManagerActions.getQuestionReferencesForQuestion.success, (state, action) => {
    return {
      ...state,
      questionReferencesList: action.payload
    };
  })
  .handleAction(questionReferenceManagerActions.clearQuestionList, (state) => {
    return {
      ...state,
      questionList: []
    };
  })
  .handleAction(questionReferenceManagerActions.clearQuestionReferencesList, (state) => {
    return {
      ...state,
      questionReferencesList: []
    };
  })
  .handleAction(questionReferenceManagerActions.countQuestionReferences.success, (state, action) => {
    const questionListWithNumReferences = state.questionList ?
      state.questionList.map((question) => {
        return {
          ...question,
          numReferences: action.payload[question._id]
        };
      }) : [];
    return {
      ...state,
      questionList: questionListWithNumReferences
    };
  })
  .handleAction(questionReferenceManagerActions.getQuestion.success, (state, action) => {
    return {
      ...state,
      currentQuestion: action.payload
    };
  })
  .handleAction(questionReferenceManagerActions.setCurrentFeature, (state, action) => {
    return {
      ...state,
      currentFeature: action.payload
    };
  })
  .handleAction(questionReferenceManagerActions.searchCuratorCases.request, (state) => {
    return {
      ...state,
      curatorSearchResults: [],
    };
  })
  .handleAction(questionReferenceManagerActions.searchCuratorCases.success, (state, action) => {
    return {
      ...state,
      curatorSearchResults: action.payload,
    };
  })
  .handleAction(questionReferenceManagerActions.searchCuratorCases.failure, (state) => {
    return {
      ...state,
      curatorSearchResults: [],
    };
  });
