import {
  Grid,
  Table,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Button, Dialog, Typography, Grid as MUIGrid } from '@material-ui/core';
import { propOr, uniq } from 'ramda';
import React from 'react';

import { RoleDisplayNames, SubscriptionDisplayNames } from '../../../lib/constants';
import { Environment, Role, Subscription } from '../../../types/core';
import { AvailabilityDiff } from '../types';

type Props = {
  confirmChanges: () => void;
  setModalOpen: (value: boolean) => void;
  modalOpen: boolean;
  selectedEnvironment: {
    label: string;
    value: string;
  };
  updateDiff: AvailabilityDiff;
};

type Row = {
  roles: {
    added: Role[],
    removed: Role[],
  },
  subscription: Subscription
};

type DiffComponentProps = {
  updateDiff: AvailabilityDiff,
  feature: string
};

const EnabledDisabledComponent: React.FunctionComponent<DiffComponentProps> = ({ updateDiff, feature }) => {
  const enabledSubs = updateDiff.enabledDisabled[feature].enabled;
  const disabledSubs = updateDiff.enabledDisabled[feature].disabled;
  return (enabledSubs.length || disabledSubs.length) ? (
    <MUIGrid container={true} key={feature}>
      {
        enabledSubs.length ? (
          <MUIGrid item={true} xs={true}>{'Enabled Subscriptions:'}
            <ul>
              {enabledSubs.map((sub) => (
                <li key={`${feature}-${sub}-enabled`}>{SubscriptionDisplayNames[sub]}</li>
              ))}
            </ul>
          </MUIGrid>
        ) : null
      }
      {
        disabledSubs.length ? (
          <MUIGrid item={true} xs={true}>{'Disabled Subscriptions:'}
            <ul>
              {disabledSubs.map((sub) => (
                <li key={`${feature}-${sub}-disabled`}>{SubscriptionDisplayNames[sub]}</li>
              ))}
            </ul>
          </MUIGrid>
        ) : null
      }
    </MUIGrid>
  ) : null;
};

const RolesComponent: React.FunctionComponent<DiffComponentProps> = ({ updateDiff, feature }) => {
  const columns = [
    {
      name: 'Subscription',
      value: 'subscription',
      getCellValue: ({ subscription }: Row) => SubscriptionDisplayNames[subscription]

    },
    {
      name: 'Added',
      value: 'added',
      getCellValue: ({ roles, subscription }: Row) => {
        return roles.added && roles.added.map((role) => <li
          key={`${feature}-${subscription}-${role}-added`}>{RoleDisplayNames[role]}</li>);
      }
    },
    {
      name: 'Removed',
      value: 'removed',
      getCellValue: ({ roles, subscription }: Row) => {
        return (
          <ul>
            {roles.removed && roles.removed.map((role) => <li
              key={`${feature}-${subscription}-${role}-removed`}>{RoleDisplayNames[role]}</li>)}
          </ul>
        );
      }
    }
  ];

  const rows = Object.keys(updateDiff.roles[feature] || {}).map((subscription) => {
    const subObject = updateDiff.roles[feature][subscription];
    return {
      subscription,
      roles: {
        added: propOr([], 'added', subObject),
        removed: propOr([], 'removed', subObject)
      }
    };
  });

  return rows.length ? (
    <Grid rows={rows} columns={columns}>
      <Table />
      <TableHeaderRow />
    </Grid>
  ) : null;
};

export const FeatureAvailabilityConfirmationModal: React.FunctionComponent<Props> = (
  {
    confirmChanges,
    modalOpen,
    setModalOpen,
    selectedEnvironment,
    updateDiff
  }
) => (
  <Dialog
    fullWidth={true}
    maxWidth="md"
    open={modalOpen}
    onClose={() => setModalOpen(false)}
  >
    <Box p={4}>
      <Box
        alignItems="baseline"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant={'h6'}>Commit Changes</Typography>
        <Box alignItems="baseline" display="flex">
          <Typography>Environment: </Typography>
          <Box color={selectedEnvironment.value === Environment.LIVE ? 'red' : 'auto'}
               fontWeight="bold">&nbsp;{selectedEnvironment.label}</Box>
        </Box>
      </Box>
      <Box height="500px" overflow="auto">
        {uniq(Object.keys(updateDiff.roles).concat(Object.keys(updateDiff.enabledDisabled))).map((feature: string) => (
          <Box mt={3} key={`commit-${feature}`}>
            <Box fontWeight="bold" marginBottom={2}>{feature}</Box>
            <EnabledDisabledComponent updateDiff={updateDiff} feature={feature} />
            <RolesComponent updateDiff={updateDiff} feature={feature} />
          </Box>
        ))}
      </Box>
      <Box display="flex" mt={5}>
        <Box mr={1}>
          <Button
            color="primary"
            onClick={() => {
              confirmChanges();
              setModalOpen(false);
            }}
            size="small"
            variant="contained"
          >
            Confirm
          </Button>
        </Box>
        <Button
          onClick={() => setModalOpen(false)}
          size="small"
          variant="contained"
        >
          Cancel
        </Button>
      </Box>
    </Box>
  </Dialog>
);
