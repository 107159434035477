import { Box, Typography, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { mongoIdPropGetter } from '../../../lib/util';
import { Environment } from '../../../types/core';
import { AccountListDataTable } from '../components/AccountListDataTable';
import { getAccounts } from '../state/actions';
import { accountManagerConnector, selectFormattedAccountList } from '../state/selectors';
import { AccountEntity } from '../state/types';

type AccountListTableColumn = {
  name: keyof AccountEntity,
  title: string,
  getCellValue?: (row: AccountEntity) => any
};

const columns: AccountListTableColumn[] = [
  {
    name: 'name',
    title: 'Account Name'
  },
  {
    name: 'sector',
    title: 'Sector'
  },
  {
    name: 'jurisdiction',
    title: 'Jurisdiction'
  },
  {
    name: 'userCount',
    title: '# Users'
  },
  {
    name: 'doNotEmail',
    title: 'Do Not Email'
  }
];

const dispatchers = {
  getAccountList: getAccounts.request,
  editAccount: (id: string) => push(`${window.location.pathname}/${id}/edit`),
  addAccount: () => push(`${window.location.pathname}/add`)
};

type DispatchProps = typeof dispatchers;

type RouteParams = {
  env: string
};

export type AccountManagerProps = DispatchProps & RouteComponentProps<RouteParams>;

const AccountListContainerComponent: React.FC<AccountManagerProps> = ({
  getAccountList,
  editAccount,
  addAccount,
  match
}) => {
  const env = match.params.env;
  React.useEffect(() => {
    getAccountList(env as Environment);
  }, [env, getAccountList]);

  const accountList = useSelector(selectFormattedAccountList);

  return (
    <Box p={2}>
      <Box pb={3}>
        <Typography variant="h6">
          <BreadcrumbLink to={'/'}>Home</BreadcrumbLink>
          &nbsp;/ <strong>Account Manager - {env === 'live' ? 'Production' : 'Beta'}</strong>
        </Typography>
      </Box>
      <MainContainerCardComponent>
        <Grid container={true} spacing={3} justify="space-between">
          <Grid item={true}>
            <Box mb={2}>
              {
                env === 'live' ? (
                  <Typography variant="h3" style={{color: '#AF2225'}}>Production Accounts</Typography>
                ) : (
                  <Typography variant="h3">Beta Accounts</Typography>
                )
              }
            </Box>
          </Grid>
          <Grid item={true}>
            <Button variant="contained" color="primary" onClick={addAccount}>Add Account</Button>
          </Grid>
        </Grid>
        <AccountListDataTable
          columns={columns}
          data={accountList}
          idPropGetter={mongoIdPropGetter}
          actions={[
            {
              label: 'Edit',
              onClick: editAccount,
              color: 'primary'
            }
          ]}
        />
      </MainContainerCardComponent>
    </Box>
  );
};

export const AccountListContainer = connect(accountManagerConnector, dispatchers)(AccountListContainerComponent);
