import * as React from 'react';
import Select from 'react-select';

import { SelectOption } from '../../types/util';

type SelectProps = {
  value: SelectOption | null,
  placeholder: string,
  handleChange: (input: any, action: any) => void,
  options: ReadonlyArray<SelectOption>,
  isDisabled?: boolean,
  isSearchable?: boolean,
  required?: boolean,
  isClearable?: boolean
};

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2
  })
};

export const SelectField = (
  {
    value,
    handleChange,
    options,
    placeholder,
    isDisabled = false,
    isSearchable = false,
    required = false,
    isClearable = false
  }: SelectProps
) => {
  return (
    <Select
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      options={options}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      isClearable={isClearable}
      required={required}
      styles={customStyles}
    />
  );
};
