import { Box, Button, Grid, Card, Typography, CardContent } from '@material-ui/core';
import * as React from 'react';

import { RichTextEditor } from '../../../components/form/RichTextEditor';
import { TextField } from '../../../components/form/TextField';
import { isValidLink } from '../../../lib/util';
import { TranslatedStringContainer } from '../../translation/containers/TranslatedStringContainer';
import { QuestionReferenceEntity, QuestionEntity, LANGUAGE_TYPE } from '../state/types';

type EditQuestionReferenceFormProps = {
  questionReference: QuestionReferenceEntity,
  handleChange: (field: keyof QuestionReferenceEntity, useInternationalization: boolean, language: LANGUAGE_TYPE, value: string) => void,
  handleSubmit: (event: React.SyntheticEvent) => void,
  question?: QuestionEntity,
  handleLinkClick: (lang: LANGUAGE_TYPE) => () => void,
  handleDelete?: () => void
};

export const QuestionReferenceFormComponent: React.FC<EditQuestionReferenceFormProps> = (
  {
    questionReference,
    handleChange,
    handleSubmit,
    question,
    handleLinkClick,
    handleDelete
  }
) => {
  const disabled = !questionReference.title.en || !questionReference.link.en || !questionReference.excerpt.en || !isValidLink(questionReference.link.en) || (!questionReference.initialVoteCount && (questionReference.initialVoteCount !== 0));
  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <Box>
            <TextField
              value={questionReference.title.en}
              label="Display Name (English)*"
              onChange={handleChange.bind(null, 'title', true, LANGUAGE_TYPE.EN)}
              required={true}
            />
            <TextField
              value={questionReference.title.fr}
              label="Display Name (French)"
              onChange={handleChange.bind(null, 'title', true, LANGUAGE_TYPE.FR)}
              required={false}
            />
          </Box>
          <Box>
            <TextField
              helperText="Must be a valid URL or an internal case beginning with '/case'"
              value={questionReference.link.en}
              label="Link (English)"
              onChange={handleChange.bind(null, 'link', true, LANGUAGE_TYPE.EN)}
              required={true}
            />
            <Button variant="outlined" color="primary" onClick={handleLinkClick(LANGUAGE_TYPE.EN)}>Find English Curator Case</Button>
            <TextField
              helperText="Must be a valid URL or an internal case beginning with '/case'"
              value={questionReference.link.fr}
              label="Link (French)"
              onChange={handleChange.bind(null, 'link', true, LANGUAGE_TYPE.FR)}
              required={false}
            />
            <Button variant="outlined" color="primary" onClick={handleLinkClick(LANGUAGE_TYPE.FR)}>Find French Curator Case</Button>
          </Box>
          <Box>
            <TextField
              helperText="Initial vote count"
              value={questionReference.initialVoteCount}
              label="Vote Count"
              onChange={handleChange.bind(null, 'initialVoteCount', false, LANGUAGE_TYPE.EN)}
              type={'number'}
              required={true}
            />
          </Box>
        </Grid>
        <Grid item={true} xs={6}>
          {question && (
            <Box mt={2}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Question Details</Typography>
                  <Typography variant="subtitle1">
                    <strong>Id:</strong> <TranslatedStringContainer id={question.id} />
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Display Id:</strong> <TranslatedStringContainer id={question.displayId} />
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Prompt:</strong> <TranslatedStringContainer id={question.prompt} />
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Feature:</strong> {question.domain}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          )}
        </Grid>
        <Grid xs={6}>
          <RichTextEditor
            id="englishEditor"
            title={'Excerpt (English)'}
            onEditorChange={handleChange.bind(null, 'excerpt', true, LANGUAGE_TYPE.EN)}
            value={questionReference.excerpt.en || ''}
          />
        </Grid>
        <Grid xs={6}>
          <RichTextEditor
            title={'Excerpt (French)'}
            id="frenchEditor"
            onEditorChange={handleChange.bind(null, 'excerpt', true, LANGUAGE_TYPE.FR)}
            value={questionReference.excerpt.fr || ''}
          />
        </Grid>
      </Grid>
      <Box pt={3}>
        <Grid container={true} spacing={3}>
          {handleDelete && (
            <Grid item={true}>
              <Button
                onClick={handleDelete}
                variant="outlined"
                color="primary">
                Delete
              </Button>
            </Grid>
          )}
          <Grid item={true}>
            <Button
              disabled={disabled}
              type="submit"
              variant="contained"
              color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
