import { createAction, createAsyncAction } from 'typesafe-actions';

import { ExportCodedCaseResult } from '../../../types/query-types';

export const exportAllCodedCasesRequest = createAsyncAction(
  '@@INTERNAL/CODED_CASE_MANAGER/EXPORT_ALL_CODED_CASES/REQUEST',
  '@@INTERNAL/CODED_CASE_MANAGER/EXPORT_ALL_CODED_CASES/SUCCESS',
  '@@INTERNAL/CODED_CASE_MANAGER/EXPORT_ALL_CODED_CASES/FAILURE',
)<void, ExportCodedCaseResult, void>();

export const startPollingJobId = createAction(
  '@@INTERNAL/CODED_CASE_MANAGER/START_POLLING_JOB_ID'
)<string>();

export const exportAllCodedCases = createAction('@@INTERNAL/CODED_CASE_MANAGER/EXPORT_ALL_CODED_CASES')<void>();
export const showCodedCaseModal = createAction('@@INTERNAL/CODED_CASE_MANAGER/SHOW_MODAL')<{title: string; message: string;}>();
export const resetCodedCaseModal = createAction('@@INTERNAL/CODED_CASE_MANAGER/RESET_MODAL')<void>();
export const pollJobIdData = createAsyncAction(
  '@@INTERNAL/CODED_CASE_MANAGER/POLL_JOB_ID_DATA/REQUEST',
  '@@BLUEJ/CODED_CASE_MANAGER/POLL_JOB_ID_DATA/SUCCESS',
  '@@BLUEJ/CODED_CASE_MANAGER/POLL_JOB_ID_DATA/FAILURE'
)<string, void, void>();

export const endPollingJobId = createAction(
  '@@BLUEJ/DATA_ENTRY_MANAGEMENT/END_POLLING_JOB_ID'
)<void>();
