import { Typography, Box } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { findValueFromObject, generateInternalCaseRelativeLink } from '../../../lib/util';
import { RootState } from '../../../store/root';
import { ModalState } from '../../../types/util';
import { QuestionReferenceFormComponent } from '../components/QuestionReferenceFormComponent';
import {
  getQuestionReferencesForQuestion,
  editQuestionReference, deleteQuestionReference
} from '../state/actions';
import {
  selectQuestionReferencesList, selectCurrentQuestion, selectCurrentFeature
} from '../state/selectors';
import { LANGUAGE_TYPE, QuestionReferenceEntity } from '../state/types';

import { CaseSearchContainer } from './CaseSearchContainer';

const mapStateToProps = (state: RootState) => ({
  questionReferencesList: selectQuestionReferencesList(state),
  question: selectCurrentQuestion(state),
  feature: selectCurrentFeature(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submitReference: (reference: QuestionReferenceEntity) => dispatch(editQuestionReference.request(reference)),
  pushDomainManger: () => dispatch(push('/domains')),
  getReferencesForQuestion: (questionId: string) => dispatch(getQuestionReferencesForQuestion.request(questionId)),
  deleteQuestionReference: (referenceId: string, mongoQuestionId: string) => dispatch(deleteQuestionReference.request({id: referenceId, mongoQuestionId}))
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type RouteParams = {
  questionId: string,
  referenceId: string
};

type EditQuestionReferenceProps = StateProps & DispatchProps & RouteComponentProps<RouteParams>;

type ComponentProps = {
  questionReference: QuestionReferenceEntity,
  caseSearchModal: ModalState
};

class EditQuestionReferenceContainerComponent extends React.Component<EditQuestionReferenceProps, ComponentProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      questionReference: {
        title: {
          en: ''
        },
        link: {
          en: ''
        },
        excerpt: {
          en: ''
        },
        questionId: '',
        featureId: '',
        mongoQuestionId: '',
        id: ''
      },
      caseSearchModal: {
        open: false,
        handleCancel: this.handleCancel
      }
    };
  }

  componentDidMount(): void {
    const referenceId = this.props.match.params.referenceId;
    const questionId = this.props.match.params.questionId;

    if (referenceId && questionId) {
      this.props.getReferencesForQuestion(questionId);
      this.setState({
        ...this.state,
        questionReference: {
          ...this.state.questionReference,
          mongoQuestionId: questionId,
          id: referenceId
        }
      });
    } else {
      this.props.pushDomainManger();
    }
  }

  componentDidUpdate(prevProps: any): void {
    if (this.props.questionReferencesList && prevProps.questionReferencesList !== this.props.questionReferencesList) {
      this.setState({
        ...this.state,
        questionReference: {
          ...this.state.questionReference,
          ...findValueFromObject('id', this.state.questionReference.id, this.props.questionReferencesList)
        }
      });
    }
  }

  handleCancel = () => {
    this.setState({
      ...this.state,
      caseSearchModal: {
        ...this.state.caseSearchModal,
        open: false
      }
    });
  }

  handleChange = (field: string, useInternalization = true , lang: LANGUAGE_TYPE, value: string) => {
    this.setState({
      ...this.state,
      questionReference: {
        ...this.state.questionReference,
        [field]: useInternalization ? { ...this.state.questionReference[field], [lang]: value } : value
      }
    });
  }

  handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.props.submitReference(this.state.questionReference);
  }

  handleDelete = () => {
    this.props.deleteQuestionReference(this.state.questionReference.id, this.state.questionReference.mongoQuestionId || '');
  }

  handleLinkClick = (lang: LANGUAGE_TYPE) => () => {
    this.setState({
      ...this.state,
      caseSearchModal: {
        ...this.state.caseSearchModal,
        lang,
        open: true,
      }
    });
  }

  handleLinkSelect = (curatorId: string) => {
    const link = generateInternalCaseRelativeLink(curatorId);
    const lang = this.state.caseSearchModal.lang === LANGUAGE_TYPE.EN ? 'en' : 'fr';
    this.setState({
      ...this.state,
      questionReference: {
        ...this.state.questionReference,
        link: {
          ...this.state.questionReference.link,
          [lang]: link
        }
      },
      caseSearchModal: {
        ...this.state.caseSearchModal,
        open: false
      }
    });
  }

  render() {
    return (
      <>
        <Box pb={3}>
          <Typography variant="h6">
            <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={'/domains'}>Domains</BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={`/domains/${this.props.feature && this.props.feature._id}/question-reference-manager`}>
              Question List
            </BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={`/question-reference-manager/${this.props.match.params.questionId}`}>
              References
            </BreadcrumbLink> / Edit Reference
          </Typography>
        </Box>
        <MainContainerCardComponent>
          <QuestionReferenceFormComponent
            questionReference={this.state.questionReference}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            question={this.props.question}
            handleLinkClick={this.handleLinkClick}
            handleDelete={this.handleDelete}
          />
        </MainContainerCardComponent>
        <CaseSearchContainer modalState={this.state.caseSearchModal} handleSelect={this.handleLinkSelect} />
      </>
    );
  }
}

export const EditQuestionReferenceContainer = connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(EditQuestionReferenceContainerComponent);
