import { Button, Card, CardContent, CardHeader, Typography, Box } from '@material-ui/core';
import * as React from 'react';

import { SubscriptionDisplayNames } from '../../../lib/constants';
import { FeatureType, Subscription } from '../../../types/core';
import { TranslatedStringContainer } from '../../translation/containers/TranslatedStringContainer';
import { DomainEntity } from '../state/types';

type DomainProps = {
  domain: DomainEntity
  handleReferenceClick: (featureId: string) => void;
  handleRelatedStatuteSectionsClick: (domain: string) => void;
  handleSimilarDecisionsWeightsClick: (featureId: string) => void;
  subscription: Subscription,
  handleMetadataClick: (domain: string) => void;
};

export const DomainComponent: React.FC<DomainProps> = (
  {
    domain,
    handleReferenceClick,
    handleRelatedStatuteSectionsClick,
    handleSimilarDecisionsWeightsClick,
    subscription,
    handleMetadataClick
  }
) => {
  const features = domain.features.map((feature) => {
    if (feature.featureType === FeatureType.Classifier || feature.featureType === FeatureType.Flow || feature.featureType === FeatureType.Calculator) {
      return (
        <React.Fragment key={feature._id}>
          <Typography variant="subtitle1">
            <TranslatedStringContainer id={feature.displayName} language="en" />
          </Typography>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Button onClick={handleReferenceClick.bind(null, feature._id)} variant="contained" color="primary">Question References</Button>
            <Button onClick={handleSimilarDecisionsWeightsClick.bind(null, feature._id)} variant="contained" color="primary">Similar Decisions Weight</Button>
          </Box>
        </React.Fragment>
      );
    }
    return null;
  });

  return (
    <Card>
      <CardHeader title={domain.id} titleTypographyProps={{variant: 'h6'}} subheader={SubscriptionDisplayNames[subscription]} />
      <CardContent>
        <Box display="flex" justifyContent="space-between" mb={2} overflow="visible">
          <Button
            onClick={() => handleRelatedStatuteSectionsClick(domain._id)}
            variant="contained"
            color="default"
          >
            Related Statute Sections
          </Button>
          <Button
            onClick={() => handleMetadataClick(domain._id)}
            variant="contained"
            color="default"
          >
            Domain Metadata
          </Button>
        </Box>
        {features}
      </CardContent>
    </Card>
  );
};
