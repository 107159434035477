import { Box } from '@material-ui/core';
import * as React from 'react';
import { Switch, Route } from 'react-router';

import { CodedCaseManagerLanding } from './coded-case-manager-landing';

export const CodedCaseManagerContainer: React.FC = () => {
  return (
    <Box p={2}>
      <Switch>
        <Route path="/coded-case-manager/" exact={true} component={CodedCaseManagerLanding} />
      </Switch>
    </Box>
  );
};
