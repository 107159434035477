import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';
import { StatuteSection } from '../types';

import {
  fetchAvailableStatuteDocuments,
  fetchRelatedStatuteSectionsByDomain,
  submitRelatedStatuteSection, deleteRelatedStatuteSection
} from './actions';
import {
  selectRelatedStatuteSection,
  selectAvailableDocuments
} from './selectors';

const fetchStatuteDocumentsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(fetchAvailableStatuteDocuments.request)),
    switchMap(() => {
      return from(bookcase.getAllAvailableDocuments())
        .pipe(
          map((documents) => {
            return fetchAvailableStatuteDocuments.success(documents);
          })
        );
    }),
    catchError(() => of(fetchAvailableStatuteDocuments.failure()))
  );

const fetchRelatedStatuteSectionsByDomainEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(fetchRelatedStatuteSectionsByDomain.request)),
    switchMap(({ payload }) => {
      return from(internalApi.getRelatedStatuteSection({ domain: payload }))
        .pipe(
          switchMap((res) => {
            return [fetchRelatedStatuteSectionsByDomain.success(res)];
          })
        );
    }),
    catchError(() => of(fetchRelatedStatuteSectionsByDomain.failure()))
  );

const submitRelatedStatuteSectionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(submitRelatedStatuteSection.request)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const relatedStatuteSection = selectRelatedStatuteSection(state);
      const documents = selectAvailableDocuments(state);
      const document = documents.find((d) => d.document.id === action.payload.documentId);
      if (document) {
        const currentRelatedSection: StatuteSection = {
          documentId: document.document.id,
          sectionNumber: action.payload.sectionNumber,
          title: document.document.title,
          type: document.document.type,
          addedOn: new Date().toISOString(),
          country: document.document.country
        };
        if (relatedStatuteSection) {
          return from(internalApi.updateRelatedStatuteSection({
            domain: action.payload.domain,
            sections: [
              ...relatedStatuteSection?.sections,
              currentRelatedSection
            ]
          }))
            .pipe(
              map((res) => submitRelatedStatuteSection.success(res))
            );
        }
        return from(internalApi.createRelatedStatuteSection({
          domain: action.payload.domain,
          sections: [currentRelatedSection]
        }))
          .pipe(
            map((res) => submitRelatedStatuteSection.success(res))
          );
      }
      return [];
    }),
    catchError(() => of(submitRelatedStatuteSection.failure()))
  );

const deleteRelatedSectionStatuteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(deleteRelatedStatuteSection.request)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const relatedStatuteSection = selectRelatedStatuteSection(state);
      if (relatedStatuteSection) {
        const sections = relatedStatuteSection.sections.filter((section) => section.sectionNumber !== action.payload);
        return from(internalApi.updateRelatedStatuteSection({ domain: relatedStatuteSection.domain, sections }))
          .pipe(
            map((res) => deleteRelatedStatuteSection.success(res))
          );
      }
      return [];
    }),
    catchError(() => of(deleteRelatedStatuteSection.failure()))
  );

export const relatedStatuteSectionsEpics = [
  fetchStatuteDocumentsEpic,
  fetchRelatedStatuteSectionsByDomainEpic,
  submitRelatedStatuteSectionEpic,
  deleteRelatedSectionStatuteEpic
];
