import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

export interface IModuleItemProps {
  path?: string;
  name: string;
  description: string;
  onClick?: () => MouseEventHandler<HTMLDivElement>;
  styles?: {
    color: string;
  };
}

export const ModuleItemComponent: React.FC<IModuleItemProps> = (
  props
) => {
  return (
    <Card style={{color: props.styles ? props.styles.color : 'inherit'}} onClick={() => props.onClick && props.onClick()}>
      <CardActionArea component={Link} {...{to: props.path} as any}>
        <CardContent>
          <Typography gutterBottom={true} variant="h5">
            {props.name}
          </Typography>
          <Typography component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
