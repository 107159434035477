import { Box } from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cancelDeleteMLService,
  cancelPromoteFeatureMLServices,
  confirmDeleteMLService,
  confirmPromoteFeatureMLServices,
  deleteMLService,
  updateMLService
} from '../state/actions';
import {
  selectMLConfigDeleteInProgress,
  selectMLConfigPromoteFeatureInProgress
} from '../state/selectors';
import {
  DeleteMLServiceDetails,
  MLServiceConfig,
  QuestionListItem,
  UpdateMLServiceConfig
} from '../types';

import { MLServiceConfirmationModal } from './MLServiceConfirmationModal';
import { MLServiceUpdateForm } from './update-service/MLServiceUpdateForm';
import { MLServiceConfigDiff } from './MLServiceConfigDiff';

type Props = {
  mlServiceConfigs: MLServiceConfig[],
  promoteTargetMlServiceConfigs: MLServiceConfig[],
  availableQuestions: QuestionListItem[]
};

export const MLServices: React.FunctionComponent<Props> = ({ mlServiceConfigs, promoteTargetMlServiceConfigs, availableQuestions }) => {
  const dispatch = useDispatch();
  const onUpdate = useCallback((data: UpdateMLServiceConfig) => dispatch(updateMLService(data)), [dispatch]);
  const onDelete = useCallback((details: DeleteMLServiceDetails) => dispatch(deleteMLService(details)), [dispatch]);

  const deleteInProgress = useSelector(selectMLConfigDeleteInProgress);
  const promoteFeatureInProgress = useSelector(selectMLConfigPromoteFeatureInProgress);

  return (
    <>
      {mlServiceConfigs.map((serviceConfig) => (
        <Box key={serviceConfig._id} paddingY={1}>
          <MLServiceUpdateForm
            _id={serviceConfig._id}
            feature={serviceConfig.feature}
            type={serviceConfig.type}
            onUpdate={onUpdate}
            onDelete={onDelete}
            initialData={serviceConfig}
            name={serviceConfig.name}
            availableQuestions={availableQuestions}
          >
            <MLServiceConfigDiff
              mlServiceConfig={serviceConfig}
              promoteTargetConfig={
                promoteTargetMlServiceConfigs.find(({ feature, name }) =>
                  serviceConfig.feature === feature && serviceConfig.name === name
                )
              }
            />
          </MLServiceUpdateForm>
        </Box>
      ))}
      <MLServiceConfirmationModal
        title="Delete Confirmation"
        confirmData={deleteInProgress}
        onCancelAction={cancelDeleteMLService}
        onConfirmAction={confirmDeleteMLService}
      />
      <MLServiceConfirmationModal
        title="MLService Promote Confirmation"
        confirmData={promoteFeatureInProgress}
        onCancelAction={cancelPromoteFeatureMLServices}
        onConfirmAction={confirmPromoteFeatureMLServices}
      />
    </>
  );
};
