import * as R from 'ramda';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';
import { getStringsFromIds } from '../../translation/state/actions';

import { getDomainList, setFeaturesList } from './actions';
import { DomainEntity } from './types';

const fetchDomainList: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(getDomainList.request)),
    switchMap(() => {
      return from(internalApi.getAllDomains()).pipe(
        switchMap((domainResponse) => {
          const strings = R.flatten(domainResponse.map((domain: DomainEntity) => {
            const featureNames = domain.features.map((feature) => {
              return feature.displayName;
            });
            return [domain.displayName, ...featureNames];
          }));
          const features = R.flatten(R.pluck('features')(domainResponse)).sort((a, b) => a.feature.localeCompare(b.feature));
          const domains = domainResponse.sort((a, b) => a.id.localeCompare(b.id));
          return [getDomainList.success(domains), getStringsFromIds.request(strings), setFeaturesList(features)];
        })
      );
    }),
    catchError((err) => {
      return of(getDomainList.failure(err));
    })
  );

export const domainManagerEpics = [
  fetchDomainList
];
