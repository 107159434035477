import { MLServiceType, ProphetFormValues } from '../../types';

import { createMLServiceForm } from './create-ml-service-form';

const transformInitialProphetFormData = (initialData: Partial<ProphetFormValues>): ProphetFormValues => ({
  disabled: !!initialData.disabled,
  isDefault: !!initialData.isDefault,
  predictionDomain: initialData.predictionDomain,
  interimPredict: !!initialData.interimPredict
});

export const ProphetMLServiceForm = createMLServiceForm<ProphetFormValues>(
  MLServiceType.Prophet,
  'Prophet Service',
  transformInitialProphetFormData
);
