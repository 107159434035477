import { InputLabel, Box } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import * as React from 'react';

interface IRichTextEditor {
  onEditorChange: (text: string) => void;
  value: string;
  title: string;
  id: string;
}

export class RichTextEditor extends React.PureComponent<IRichTextEditor> {
  handleEditorChange = (e: any) => {
    this.props.onEditorChange(e);
  }

  render() {
    return (
      <>
        <Box pt={2} pb={2}>
          <InputLabel htmlFor="editor">{this.props.title}</InputLabel>
        </Box>
        <Editor
          value={this.props.value}
          id={this.props.id}
          onEditorChange={this.handleEditorChange}
          apiKey={'v5lb7p1yzkdfdp08036qjfbseklrwtmm77v9mpuvfqi2pbk9'}
          init={{
            invalid_elements: 'o:p,style,q,span,html,head,body',
            height: 500,
            branding: false,
            plugins: ['code'],
            toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
            content_style: 'a {text-decoration: none; color: #46b4e6}',
            default_link_target: '_blank',
            valid_styles: {
              '*': 'font-weight text-align text-decoration text-indent padding-left'
            },
            paste_word_valid_elements: 'b,br,i,em,h1,h2,u,ol,ul,li,a[href],strong,span',
            remove_trailing_brs: false
          }}
        />
      </>
    );
  }
}
