import { Subscription } from '../../../types/core';

export enum ActivationType {
  PERSONALIZE_HOMEPAGE = 'Personalize Homepage',
  RUN_PREDICTION = 'Run Prediction',
  SCENARIO_TEST = 'Scenario Test',
  DOWNLOAD_REPORT = 'Download Report',
  CASE_REPORT = 'Case Finder',
  QUESTION_REPORT = 'Question Reference',
  CREATE_WORKSPACE = 'Create Workspace',
  ANNOTATE_STATUTE = 'Annotate Statute',
  EXPORT_WORKSPACE = 'Export Workspace',
  CREATE_DIAGRAM = 'Create Diagram'
}

export type ActivationEntity = {
  _id: string;
  subscription: string,
  order: number;
  title: string;
  title_fr: string;
  description: string;
  description_fr: string;
  body: string;
  body_fr: string;
  activationType: string;
  enabled: boolean;
};

export type ActivationManagerState = {
  subscription: Subscription | null;
  activationModules: ActivationEntity[];
};

export enum SAVE_MODE {
  CREATE = 'create',
  EDIT = 'edit'
}
