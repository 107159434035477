import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';
import { FeatureType } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { selectFeatures } from '../../base/state/selectors';

export const selectMLServiceState = (state: RootState) => state.mlService;

export const selectMLServiceConfigs = createSelector(
  selectMLServiceState,
  (mlServiceState) => mlServiceState.services
);

export const selectPromoteTargetMlServiceConfigs = createSelector(
  selectMLServiceState,
  (mlServiceState) => mlServiceState.promoteTargetServices
);

export const selectMLServiceAvailableQuestions = createSelector(
  selectMLServiceState,
  (mlServiceState) => mlServiceState.availableQuestions
);

export const selectClassifierFeatureSelectOptions = createSelector(
  selectFeatures,
  (features) => features
    .filter(({ featureType }) => [FeatureType.Classifier, FeatureType.Calculator].includes(featureType))
    .map(({ feature, url }): SelectOption => ({
      label: `${feature} - (${url})`,
      value: feature
    }))
);

export const selectMLConfigDeleteInProgress = createSelector(
  selectMLServiceState,
  ({ deleteInProgress }) => deleteInProgress
);

export const selectMLConfigPromoteAllInProgress = createSelector(
  selectMLServiceState,
  ({ promoteAllInProgress }) => promoteAllInProgress
);

export const selectMLConfigPromoteFeatureInProgress = createSelector(
  selectMLServiceState,
  ({ promoteFeatureInProgress }) => promoteFeatureInProgress
);

export const selectMLConfigDataVersion = createSelector(
  selectMLServiceState,
  ({ dataVersion }) => dataVersion
);

export const selectMLConfigCreateOpened = createSelector(
  selectMLServiceState,
  ({ createOpened }) => createOpened
);

export const selectMLConfigErrors = createSelector(
  selectMLServiceState,
  ({ errorStack }) => errorStack
);

export const selectMLConfigMessages = createSelector(
  selectMLServiceState,
  ({ messages }) => messages
);
