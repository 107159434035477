import { Box } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { EntitySelector } from '../../../components/form/EntitySelector';

import { findValueFromObject } from '../../../lib/util';
import { SelectOption } from '../../../types/util';
import { selectDomainListAsSelectedOption } from '../../domain-manager/state/selectors';

import RelatedStatuteSectionsList from './RelatedStatuteSectionsList';

type RouteParams = {
  domainName: string;
};

type Props = RouteComponentProps<RouteParams>;

const RelatedStatuteSectionsManager: React.FC<Props> = ({ match }) => {
  const domainList = useSelector(selectDomainListAsSelectedOption);
  const domain = findValueFromObject<SelectOption>('value', match.params.domainName, domainList);

  const dispatch = useDispatch();
  const pushDomainRelatedStatuteSection = (d: SelectOption) => dispatch(push(`/domains/${d.value}/related-statute-sections`));
  return (
    <>
      <Box p={2}>
        {domain && <EntitySelector isClearable={false} handleChange={pushDomainRelatedStatuteSection} value={domain} objectList={domainList} title="Choose a Domain" />}
      </Box>
      <Switch>
        <Route exact={true} path="/domains/:domainName/related-statute-sections" component={RelatedStatuteSectionsList} />
      </Switch>
    </>
  );
};

export default RelatedStatuteSectionsManager;
