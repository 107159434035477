import { MLServiceType, NoMLServiceFormValues } from '../../types';

import { createMLServiceForm } from './create-ml-service-form';

const transformInitialNoMLServiceData = (initialData: Partial<NoMLServiceFormValues>): NoMLServiceFormValues => ({
  disabled: !!initialData.disabled,
  isDefault: !!initialData.isDefault
});

export const NoMLServiceForm = createMLServiceForm<NoMLServiceFormValues>(
  MLServiceType.NoService,
  'No ML Service',
  transformInitialNoMLServiceData
);
