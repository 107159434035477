import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';

import { ActivationManagerState, ActivationType } from './types';

const selectActivationManagerState = (state: RootState): ActivationManagerState => state.activationManager;

export const selectActivationManagerModules = createSelector(
  selectActivationManagerState,
  (activationManagerState) => activationManagerState.activationModules
);

export const selectCurrentActivationManagerSubscription = createSelector(
  selectActivationManagerState,
  (activationManagerState) => activationManagerState.subscription
);

export const selectRemainingActivationManagerTypes = createSelector(
  selectActivationManagerState,
  (activationManagerState) => {
    const ACTIVATION_TYPE_OPTIONS = Object.values(ActivationType) as string[];
    const currentTypes = activationManagerState.activationModules.map((activationModule) => activationModule.activationType);
    return ACTIVATION_TYPE_OPTIONS.filter((activationType) => !(currentTypes.includes(activationType)));
  }
);
