import { Box, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { EntitySelector } from '../../../components/form/EntitySelector';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { findValueFromObject } from '../../../lib/util';
import { SimilarCaseQuestionConfig } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { selectClassifiersFlowsListAsSelectedOption } from '../../domain-manager/state/selectors';
import { getQuestionsForFeature } from '../../question-reference-manager/state/actions';
import { updateSimilarDecisionsWeights } from '../state/actions';
import { createSelectSimilarDecisionsWeightsFromFeatureId } from '../state/selectors';

import { SimilarDecisionsWeightsForm } from './similar-decisions-weights-form';

type RouteParams = {
  featureId: string;
};

type Props = RouteComponentProps<RouteParams>;

export const SimilarDecisionsWeightsContainer: React.FC<Props> = ({ match }) => {
  const featureSelectOptionList = useSelector(selectClassifiersFlowsListAsSelectedOption);
  const featureAsSelectOption = findValueFromObject<SelectOption>('value', match.params.featureId, featureSelectOptionList);
  const similarDecisionsWeights = useSelector(createSelectSimilarDecisionsWeightsFromFeatureId(match.params.featureId));
  const dispatch = useDispatch();

  const [weights, setWeights] = useState<SimilarCaseQuestionConfig[]>([]);

  useEffect(() => {
    if (similarDecisionsWeights && similarDecisionsWeights.length !== weights.length) {
      setWeights(similarDecisionsWeights);
    }
  }, [similarDecisionsWeights, weights.length]);

  useEffect(() => {
    dispatch(getQuestionsForFeature.request(match.params.featureId));
  }, [match.params.featureId, dispatch]);

  const pushSimilarDecisionsWeights = (f: SelectOption) => dispatch(push(`/domains/${f ? f.value : ''}/similar-decisions-weights`));

  const handleChange = (id: string, field: keyof SimilarCaseQuestionConfig, value: string) => {
    setWeights(weights.map((config) => {
      if (config.id === id) {
        return {
          ...config,
          [field]: value
        };
      }
      return config;
    }));
  };

  const handleSubmit = () => {
    dispatch(updateSimilarDecisionsWeights.request({
      featureId: match.params.featureId,
      similarCaseWeights: weights
    }));
  };

  return (
    <>
      <Box p={2}>
        {featureAsSelectOption && <EntitySelector isClearable={false} title="Change Feature" handleChange={pushSimilarDecisionsWeights} value={featureAsSelectOption} objectList={featureSelectOptionList} />}
      </Box>
      <MainContainerCardComponent>
        <Box mb={2}>
          <Typography variant="h4">Similar Decisions Weights for {featureAsSelectOption?.label}</Typography>
        </Box>
        {similarDecisionsWeights && <SimilarDecisionsWeightsForm similarDecisionsWeights={weights} handleChange={handleChange} handleSubmit={handleSubmit} />}
      </MainContainerCardComponent>
    </>
  );
};
