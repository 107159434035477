import { createAsyncAction } from 'typesafe-actions';

import { AvailableStatuteDocument, RelatedStatuteSection } from '../types';

export const fetchAvailableStatuteDocuments = createAsyncAction(
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/FETCH_AVAILABLE_DOCUMENTS',
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/FETCH_AVAILABLE_DOCUMENTS_SUCCESS',
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/FETCH_AVAILABLE_DOCUMENTS_FAILURE'
)<void, AvailableStatuteDocument[], void>();

export const fetchRelatedStatuteSectionsByDomain = createAsyncAction(
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/FETCH_RELATED_STATUTE_SECTIONS_BY_DOMAIN',
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/FETCH_RELATED_STATUTE_SECTIONS_BY_DOMAIN_SUCCESS',
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/FETCH_RELATED_STATUTE_SECTIONS_BY_DOMAIN_FAILURE'
)<string, RelatedStatuteSection | null, void>();

export const deleteRelatedStatuteSection = createAsyncAction(
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/DELETE_RELATED_STATUTE_SECTION',
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/DELETE_RELATED_STATUTE_SECTION_SUCCESS',
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/DELETE_RELATED_STATUTE_SECTION_FAILURE'
)<string, RelatedStatuteSection, void>();

export const submitRelatedStatuteSection = createAsyncAction(
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/SUBMIT_RELATED_STATUTE_SECTIONS',
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/SUBMIT_RELATED_STATUTE_SECTIONS_SUCCESS',
  '@@INTERNAL/RELATED_STATUTE_SECTIONS_MANAGER/SUBMIT_RELATED_STATUTE_SECTIONS_FAILURE'
)<{ documentId: string; sectionNumber: string; domain: string; }, RelatedStatuteSection, void>();
