import {
  Box,
  Button,
  Grid,
  Checkbox
} from '@material-ui/core';
import * as React from 'react';

import { EntitySelector } from '../../../components/form/EntitySelector';
import { MarkdownWrapper } from '../../../components/form/MarkdownWrapper';
import { TextField } from '../../../components/form/TextField';
import { Subscription } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { ActivationEntity } from '../state/types';

type ActivationModuleFormProps = {
  handleChange: (field: keyof ActivationEntity, value: string | number | boolean) => void;
  handleSubmit: (event: React.SyntheticEvent) => void;
  activationModule: ActivationEntity;
  remainingActivationTypes: string[];
  handleDelete?: () => void;
  subscription: Subscription;
};

export const ActivationModuleFormComponent: React.FC<ActivationModuleFormProps> = ({
  activationModule,
  handleSubmit,
  handleChange,
  handleDelete,
  remainingActivationTypes,
  subscription
}) => {
  const [selectedActivationType, setSelectedActivationType] = React.useState<SelectOption | null>(null);
  const [remainingActivationManagerTypesOptions, setRemainingActivationManagerTypesOptions] = React.useState<Array<SelectOption>>(new Array<SelectOption>());
  React.useEffect(() => {
    if (activationModule.activationType) {
      setSelectedActivationType({
        label: activationModule.activationType,
        value: activationModule.activationType,
      });
    }
    if (subscription !== activationModule.subscription) {
      handleChange('subscription', subscription);
    }
    if (remainingActivationTypes) {
      setRemainingActivationManagerTypesOptions(remainingActivationTypes.map((option) => ({ label: option, value: option })));
    }
  }, [activationModule.activationType, remainingActivationTypes, activationModule.subscription, handleChange, subscription]);

  const handleActivationTypeSelectionCallback = React.useCallback((selectedOption: SelectOption) => {
    handleChange('activationType', selectedOption.value);
    setSelectedActivationType(selectedOption);
  }, [handleChange]);

  const disabled = (
    !activationModule.order ||
    !activationModule.title ||
    !activationModule.description ||
    !activationModule.body ||
    !activationModule.subscription ||
    typeof activationModule.enabled !== 'boolean'
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid xs={12}>
          <Grid item={true} xs={6}>
            <Box>
              Enabled
              <Checkbox
                checked={activationModule.enabled}
                onChange={() => handleChange('enabled', !activationModule.enabled)}
              />
            </Box>
            <Box>
              <TextField
                value={Number(activationModule.order)}
                label="Order #"
                onChange={handleChange.bind(null, 'order')}
                required={true}
                type={'number'}
              />
            </Box>
            <Box>
              <TextField
                value={activationModule.title}
                label="Title (English)"
                onChange={handleChange.bind(null, 'title')}
                required={true}
              />
              <TextField
                value={activationModule.title_fr}
                label="Title (French)"
                onChange={handleChange.bind(null, 'title_fr')}
                required={false}
              />
            </Box>
            <Box>
              <TextField
                value={activationModule.description}
                label="Description (English)"
                onChange={handleChange.bind(null, 'description')}
                required={true}
              />
              <TextField
                value={activationModule.description_fr}
                label="Description (French)"
                onChange={handleChange.bind(null, 'description_fr')}
                required={false}
              />
            </Box>
            <Box>
              <EntitySelector
                isClearable={false}
                title="Activation Type"
                handleChange={handleActivationTypeSelectionCallback}
                value={selectedActivationType}
                objectList={remainingActivationManagerTypesOptions}
              />
            </Box>
          </Grid>
        </Grid>
        <MarkdownWrapper
          markdown={activationModule.body}
          markdownFR={activationModule.body_fr}
          handleChange={handleChange.bind(null, 'body')}
          handleChangeFR={handleChange.bind(null, 'body_fr')}
        >
        </MarkdownWrapper>
      </Grid>
      <Box pt={10}>
        <Grid container={true} spacing={3}>
          {handleDelete && (
            <Grid item={true}>
              <Button onClick={handleDelete} variant="outlined" color="primary">
                Delete
              </Button>
            </Grid>
          )}
          <Grid item={true}>
            <Button
              disabled={disabled}
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
