export enum Environment {
  BETA = 'beta',
  STAGING = 'staging',
  LIVE = 'live',
}

export enum Role {
  ADMIN = 'admin',
  BASIC = 'basic',
  COMPANY_ADMIN = 'companyAdmin',
  DATA_ENTRY = 'dataEntry',
  INTERNAL = 'internal',
  RESTRICTED = 'restricted',
  SUPPORT = 'support',
  FREE_TRIAL = 'freeTrial'
}

export enum Subscription {
  EMPLOYMENT = 'employment',
  EMPLOYMENT_US = 'employmentUS',
  HR = 'hr',
  INSURANCE_US = 'insuranceUS',
  TAX = 'tax',
  TAX_US = 'taxUS',
  TAX_UK = 'taxUK',
  DIAGRAM = 'diagram'
}

export enum FeatureType {
  Core = 'core',
  Classifier = 'classifier',
  Flow = 'flow',
  CaseFinder = 'case-finder',
  CaseAnalytics = 'case-analytics',
  Calculator = 'calculator'
}

export enum QuestionType {
  Checkbox = 'Checkboxes',
  Date = 'Date',
  DateRange = 'DateRange',
  Dropdown = 'Dropdown',
  DropdownCustomValue = 'DropdownCustomValue',
  Invalid = 'Invalid',
  MultipleChoice = 'MultipleChoices',
  MultipleChoicesCustomValue = 'MultipleChoicesCustomValue',
  MultipleValueDropdown = 'MultipleValueDropdown',
  TextInput = 'TextInput',
  FlowMultipleChoices = 'FlowMultipleChoices',
  FlowTextInput = 'FlowTextInput',
  FlowCheckbox = 'CheckboxesQuestionUpdater',
  FlowDate = 'DateSectionUpdater',
  MultipleChoicesSectionUpdater = 'MultipleChoicesSectionUpdater',
  FlowDropdown = 'FlowDropdown',
  YearRange = 'YearRange',
  MinMaxNumberInput = 'MinMaxInputs'
}

export type FeatureResults = {
  [resultId: string]: {
    azure: string,
    display: string,
    short: string,
    default: string,
    subResult?: boolean
  }
};

export type FeatureService = {
  type: string,
  name: string,
  baseUrl: string,
  username: string,
  password: string,
  collectionName: string,
  clusterId: string,
  rankerId: string,
  ranked: boolean,
  authorizationToken: string,
  questionIds: string[],
  disabled?: boolean
};

type FeatureConfidenceGauge = {
  left: string,
  right: string
};

export type SimilarCaseQuestionConfig = {
  id: string,
  questionType: string,
  point: number,
  near?: number,
  far?: number
};

export type FeatureEntity = {
  _id: string,
  feature: string,
  featureType: FeatureType,
  available: Role[],
  subscriptions: Subscription[],
  url: string,
  order: number,
  displayName: string,
  resultType: string,
  results: FeatureResults,
  services: FeatureService[],
  confidenceGuage: FeatureConfidenceGauge,
  genericExplanationText: string,
  category: string[],
  shortDisplayName: string,
  parentFeature: string,
  caseFinderResultHintText: string,
  similarCaseQuestionConfigs: SimilarCaseQuestionConfig[]
};
