import jwt_decode from 'jwt-decode';
import { Epic } from 'redux-observable';
import { filter, ignoreElements, map, first } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getAppConfig } from '../../../config';
import { INTERNAL_API_KEY } from '../../../lib/constants';
import { ErrorType } from '../../../lib/errors/types';
import { Services } from '../../../services';
import { RootAction, failActions } from '../../../store/actions';
import { RootState } from '../../../store/root';

import { setAuthData, setUserData } from './actions';
import { User } from './types';

export const setAuthDataEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi, internalApi, bookcase }) =>
  action$.pipe(
    filter(isActionOf(setAuthData)),
    map((action) => {
      window.localStorage.setItem(INTERNAL_API_KEY, action.payload.token);
      internalApi.setToken(action.payload.token);
      curatorApi.setToken(action.payload.token);
      bookcase.setToken(action.payload.token);
      const user = jwt_decode(action.payload.token);
      return setUserData(user as User);
    })
  );

export const unauthorizedErrorEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(failActions)),
    first((action) => action.payload.type === ErrorType.Unauthorized),
    map(() => {
      window.localStorage.removeItem(INTERNAL_API_KEY);
      window.location.replace(getAppConfig().authLocation);
    }),
    ignoreElements()
);
