import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';

import { getStringsFromIds } from './actions';

const fetchStringsFromIds: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(getStringsFromIds.request)),
    switchMap((action) => {
      return from(internalApi.getAllStrings({stringIds: action.payload})).pipe(
        map((stringsResponse) => {
          return getStringsFromIds.success(stringsResponse.indexedStringData);
        })
      );
    })
  );

export const translationEpics = [
  fetchStringsFromIds
];
