import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';

import { fetchFeatures } from './actions';

export const fetchAllFeaturesEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(fetchFeatures.request)),
      switchMap(() =>
        from(internalApi.getAllFeatures()).pipe(
          map(fetchFeatures.success),
          catchError((error) => of(fetchFeatures.failure(error)))
        )
      )
    );

export const baseEpics = [
  fetchAllFeaturesEpic
];
