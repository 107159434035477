import { AccountForm } from '../state/types';

export const isAccountInformationEmpty = (account: AccountForm) => {
  return (
    !account.id ||
    !account.name ||
    !account.tax ||
    !account.taxUS ||
    !account.employment ||
    !account.employmentUS ||
    !account.hr ||
    !account.insuranceUS ||
    !account.diagram ||
    !account.sector ||
    !account.jurisdiction
  );
};
