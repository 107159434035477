import {
  RowDetailState,
  IntegratedFiltering,
  SearchState,
  SortingState,
  SortingDirection,
  IntegratedSorting,
  Column
} from '@devexpress/dx-react-grid';
import {
  Grid,
  SearchPanel,
  Table,
  TableRowDetail,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@material-ui/core';
import React from 'react';

import { FeatureMatrixRow } from '../types';

import { FeatureAvailabilityRowDetail } from './FeatureAvailabilityRowDetail';

type Props = {
  rows: FeatureMatrixRow[];
  columns: Column[];
};

const defaultSorting = [
  {
    columnName: 'featureName',
    direction: 'asc' as SortingDirection
  }
];

export const FeatureAvailabilityMatrix: React.FunctionComponent<Props> = ({
  rows,
  columns
}) => (
  <Box marginBottom={10}>
    <Grid rows={rows} columns={columns}>
      <SearchState />
      <RowDetailState />
      <SortingState defaultSorting={defaultSorting} />
      <IntegratedSorting />
      <IntegratedFiltering />
      <Table />
      <Toolbar />
      <SearchPanel />
      <TableRowDetail contentComponent={FeatureAvailabilityRowDetail} />
    </Grid>
  </Box>
);
