import { Box } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '../../../components/ui/Modal';
import { ModuleItemComponent } from '../../landing/components/ModuleItemComponent';
import { ItemWrapper } from '../../landing/components/ModuleListComponent';
import { exportAllCodedCases, resetCodedCaseModal } from '../state/actions';
import { selectModalState } from '../state/selectors';

export const CodedCaseManagerLanding: React.FC = () => {
  const modalState = useSelector(selectModalState);
  const dispatch = useDispatch();
  const MODULES = [
    {
      name: 'Generate Coded Case CSV',
      onClick: () => dispatch(exportAllCodedCases()),
      description: 'Generate a CSV list of all coded cases'
    }
  ];
  const Modules = React.useMemo(() => {
    return (
      <>
        {
          MODULES.map((m) => (
            <Box key={m.name} mb={4}>
              <Box display="flex" flexDirection="row">
                <ItemWrapper key={m.name}>
                  <ModuleItemComponent {...m as any} />
                </ItemWrapper>
              </Box>
            </Box>
          ))}
      </>
    );
  }, [MODULES]);

  const resetModal = () => {
    dispatch(resetCodedCaseModal());
  };

  return (
    <>
      {Modules}
      <Box>
          <Modal title={modalState.title} onCancel={resetModal} open={modalState.open}>
            {modalState.message}
          </Modal>
      </Box>
    </>
  );
};
