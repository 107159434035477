import { Box, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { ActivationModuleFormComponent } from '../components/activation-module-form.component';
import { saveActivationModule } from '../state/actions';
import { selectCurrentActivationManagerSubscription, selectRemainingActivationManagerTypes } from '../state/selectors';
import { ActivationEntity, SAVE_MODE } from '../state/types';

export const AddActivationModuleContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [activationModule, setActivationModule] = React.useState<Partial<ActivationEntity>>({enabled: false });
  const subscription = useSelector(selectCurrentActivationManagerSubscription);
  const remainingActivationManagerTypes = useSelector(selectRemainingActivationManagerTypes);
  const handleChange = (field: string, value: string | boolean | number) => {
    const updatedActivationModule = {
      ...activationModule,
      [field]: value
    };
    setActivationModule(updatedActivationModule);
  };

  React.useEffect(() => {
    if (!subscription) {
      dispatch(push('/activation-manager'));
    }
  }, [subscription, dispatch]);

  const submitActivationModule = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    const moduleWithSubscription = {
      ...activationModule,
      subscription
    } as ActivationEntity;

    dispatch(saveActivationModule(moduleWithSubscription, { mode: SAVE_MODE.CREATE }));
  }, [dispatch, activationModule, subscription]);

  return (
    <>
      <Box pb={3}>
        <Typography variant="h6">
          <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
          <BreadcrumbLink to={'/activation-manager'}>Activation Manager</BreadcrumbLink>
          / New Activation Module
        </Typography>
      </Box>
      <MainContainerCardComponent>
        <ActivationModuleFormComponent
          remainingActivationTypes={remainingActivationManagerTypes}
          handleChange={handleChange}
          handleSubmit={submitActivationModule}
          activationModule={activationModule as ActivationEntity}
          subscription={subscription!}
        />
      </MainContainerCardComponent>
    </>
  );
};
