import { ErrorType, IInternalAPIError } from './types';

export class ApiError extends Error implements IInternalAPIError {
  public readonly status: number;
  public readonly message: string;
  public readonly url: string;
  public readonly errorType: string;
  public readonly errorMessage: string;
  public readonly type: ErrorType = ErrorType.ApiFetchError;
  constructor(response: Response, errorMessage: any) {
    super();
    this.status = response.status;
    this.message = response.statusText;
    this.url = response.url;
    this.errorType = response.type;
    this.errorMessage = errorMessage;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
