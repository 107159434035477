import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router';

import { EntitySelector } from '../../../components/form/EntitySelector';
import { Environment } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import {
  cancelPromoteAllMLServices,
  clearMLServices,
  closeCreateMLService,
  confirmPromoteAllMLServices,
  initMLServicesFeatureList,
  loadMLServicesForFeature,
  openCreateMLService,
  promoteAllMLServices,
  promoteFeatureMLServices
} from '../state/actions';
import {
  selectClassifierFeatureSelectOptions,
  selectMLConfigCreateOpened,
  selectMLConfigDataVersion,
  selectMLConfigPromoteAllInProgress,
  selectMLServiceAvailableQuestions,
  selectMLServiceConfigs,
  selectPromoteTargetMlServiceConfigs
} from '../state/selectors';
import { PromoteMLServiceFeature } from '../types';

import { CreateNewMLService } from './create-new-service/CreateNewMLService';
import { MLServiceConfirmationModal } from './MLServiceConfirmationModal';
import { MLServiceErrorModal } from './MLServiceErrorModal';
import { MLServiceMessageModal } from './MLServiceMessageModal';
import { MLServices } from './MLServices';

const SELECT_A_FEATURE_TEXT = 'Select a Feature';

type RouteParams = {
  feature?: string
};

type Props = RouteComponentProps<RouteParams>;

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  }
}));

export const MLServiceList: React.FunctionComponent<Props> = ({ match: { params, path }, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const featureSelectOptions = useSelector(selectClassifierFeatureSelectOptions);
  const mlServiceConfigs = useSelector(selectMLServiceConfigs);
  const promoteTargetMlServiceConfigs = useSelector(selectPromoteTargetMlServiceConfigs);
  const availableQuestions = useSelector(selectMLServiceAvailableQuestions);
  const dataVersion = useSelector(selectMLConfigDataVersion);
  const createOpened = useSelector(selectMLConfigCreateOpened);
  const promoteAllInProgress = useSelector(selectMLConfigPromoteAllInProgress);

  const openCreate = useCallback(() => dispatch(openCreateMLService()), [dispatch]);
  const closeCreate = useCallback(() => dispatch(closeCreateMLService()), [dispatch]);
  const onPromote = useCallback((promotePayload: PromoteMLServiceFeature) => dispatch(promoteFeatureMLServices(promotePayload)), [dispatch]);
  const onPromoteAll = useCallback(() => dispatch(promoteAllMLServices()), [dispatch]);

  const selectedFeature = params.feature || '';

  const selectedFeatureOption: SelectOption = useMemo(() => ({
    value: selectedFeature,
    label: selectedFeature || SELECT_A_FEATURE_TEXT
  }), [selectedFeature]);

  useEffect(() => {
    dispatch(initMLServicesFeatureList(Environment.BETA));
  }, [dispatch]);

  useEffect(() => {
    selectedFeature ?
      dispatch(loadMLServicesForFeature(selectedFeature)) :
      dispatch(clearMLServices());
  }, [dispatch, selectedFeature, dataVersion]);

  const handleFeatureChange = useCallback(
    (option: SelectOption) => {
      history.replace(
        generatePath(path, {
          feature: option ? option.value : undefined
        })
      );
    },
    [path, history]);

  const ClassifierSelector = (
    <EntitySelector
      handleChange={handleFeatureChange}
      objectList={featureSelectOptions}
      title={SELECT_A_FEATURE_TEXT}
      value={selectedFeatureOption}
    />
  );

  const defaultServiceExists = useMemo(
    () => mlServiceConfigs.some(({ isDefault }) => !!isDefault),
    [mlServiceConfigs]
  );

  return (
    <>
      <Box p={2}>
        {!selectedFeature && (
          <Box marginY={2} className={classes.buttonBar}>
            <Button color="primary" onClick={() => onPromoteAll()} variant="contained">
              Promote ALL Feature ML Services to Production
            </Button>
          </Box>
        )}
        {ClassifierSelector}
        {selectedFeature && !createOpened && (
          <Box marginY={2} className={classes.buttonBar}>
            <Button variant="contained" color="primary" onClick={openCreate}>Add Service</Button>
            <Button color="primary" onClick={() => onPromote({ feature: selectedFeature })} variant="contained">
              Promote Feature ML Services to Production
            </Button>
          </Box>
        )}
        {selectedFeature && createOpened && (
          <Box marginTop={2}>
            <CreateNewMLService
              availableQuestions={availableQuestions}
              feature={selectedFeature}
              defaultServiceExists={defaultServiceExists}
              onCancel={closeCreate}
            />
          </Box>
        )}
        <MLServices
          mlServiceConfigs={mlServiceConfigs}
          promoteTargetMlServiceConfigs={promoteTargetMlServiceConfigs}
          availableQuestions={availableQuestions}
        />
      </Box>
      <MLServiceConfirmationModal
        title="MLService Promote ALL Confirmation"
        confirmData={promoteAllInProgress}
        onCancelAction={cancelPromoteAllMLServices}
        onConfirmAction={confirmPromoteAllMLServices}
      />
      <MLServiceErrorModal />
      <MLServiceMessageModal />
    </>
  );
};
