import * as React from 'react';
import { useDispatch } from 'react-redux';

import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { createTag } from '../state/actions';

import { TagBreadcrumb } from './tag-breadcrumb.component';
import { TagForm } from './tag-form.component';

export const CreateTag = () => {
  const dispatch = useDispatch();
  const handleSubmit = (displayNameEnglish: string, displayNameFrench: string, subscriptions: string[]) => {
    dispatch(createTag.request({
      displayName_en: displayNameEnglish,
      displayName_fr: displayNameFrench,
      subscriptions
    }));
  };

  return (
    <>
      <TagBreadcrumb currentPage="Create Tag" />
      <MainContainerCardComponent>
        <TagForm handleSubmit={handleSubmit} />
      </MainContainerCardComponent>
    </>
  );
};
