import { Box, Typography } from '@material-ui/core';
import * as React from 'react';

import { NoMLServiceFormValues } from '../../../types';

import { SpecificFormProps } from './createMLServiceTypeForm';

export const NoMLServiceTypeForm: React.FunctionComponent<SpecificFormProps<NoMLServiceFormValues>> = () => {
  return (
    <Box>
      <Typography variant="h6">No additional configuration needed</Typography>
    </Box>
  );
};
