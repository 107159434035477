import { Card, Box, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Environment } from '../../../types/core';
import { AccountFormComponent } from '../components/AccountForm';
import { createAccount } from '../state/actions';
import { AccountForm } from '../state/types';
import { isAccountInformationEmpty } from '../util';

type RouteParams = {
  env: Environment;
};

type AddAccountContainerProps = RouteComponentProps<RouteParams>;

export const AddAccountContainer: React.FC<AddAccountContainerProps> = ({
  match
}) => {
  const { env } = match.params;
  const dispatch = useDispatch();
  const [account, setAccount] = useState<AccountForm>({
    id: '',
    name: '',
    tax: '',
    taxUS: '',
    employment: '',
    employmentUS: '',
    insuranceUS: '',
    diagram: '',
    hr: '',
    sector: '',
    jurisdiction: '',
  });

  return (
    <Box p={2}>
      <Card>
        {
          env === 'live' ? (
            <CardHeader title="Production Account" titleTypographyProps={{variant: 'h3'}} style={{color: '#AF2225'}} />
          ) : (
            <CardHeader title="Beta Account" titleTypographyProps={{variant: 'h3'}} />
          )
        }
        <CardContent>
          <AccountFormComponent account={account} setAccount={setAccount} />
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(createAccount.request({ accountData: account, env }))}
            disabled={isAccountInformationEmpty(account)}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
