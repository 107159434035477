import { Box, Grid } from '@material-ui/core';
import React from 'react';

import { SelectField } from '../../../components/form/SelectField';
import { Environment } from '../../../types/core';
import { SelectOption } from '../../../types/util';

type Props = {
  environments: ReadonlyArray<SelectOption>;
  onChange: (value: Environment) => void;
  selectedEnvironment: SelectOption;
};

export const FeatureAvailabilityEnvironmentSelect: React.FunctionComponent<Props> = ({ environments, selectedEnvironment, onChange }) => (
  <Grid alignItems="flex-start" container={true} justify="flex-end">
    <Box pr={5} pt={2} minWidth={150}>
      <SelectField
        handleChange={({ value }: SelectOption) => onChange(value as Environment)}
        options={environments}
        placeholder="Environment"
        value={selectedEnvironment}
      />
    </Box>
  </Grid>
);
