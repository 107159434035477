import { CaseEntity } from '../../../types/case-types';
import { FeatureEntity, QuestionType } from '../../../types/core';
import { InternationalizedString } from '../../../types/util';

export type QuestionEntity = {
  _id: string,
  id: string,
  domain: string,
  displayId?: string,
  prompt?: string,
  numReferences?: number,
  questionType: QuestionType,
  choices?: Array<Record<string, string>>;
  textInputProps?: {
    updateStateOnChange?: boolean;
    type?: string;
    min?: number;
    max?: number;
  }
};

export type QuestionReferenceEntity = {
  id: string,
  created_at?: string,
  updated_at?: string,
  title: InternationalizedString,
  link: InternationalizedString,
  excerpt: InternationalizedString,
  questionId?: string,
  mongoQuestionId?: string,
  featureId?: string,
  initialVoteCount?: number,
  totalVoteCount?: number,
};

export type QuestionReferenceManagerState = {
  questionList?: QuestionEntity[]
  questionReferencesList?: QuestionReferenceEntity[],
  currentQuestion: QuestionEntity | undefined,
  currentFeature: FeatureEntity | undefined,
  curatorSearchResults: CaseEntity[],
};

export enum LANGUAGE_TYPE {
  EN = 'en',
  FR = 'fr',
}
