import { Box, FormControl, FormControlLabel, Input, InputLabel, Switch } from '@material-ui/core';
import * as React from 'react';

import { ProphetFormValues } from '../../../types';
import { createInputChangeHandler, createSwitchChangeHandler } from '../form-helpers';

import { SpecificFormProps } from './createMLServiceTypeForm';

export const ProphetServiceTypeForm: React.FunctionComponent<SpecificFormProps<ProphetFormValues>> = ({ formValues, onChange }) => {
  return (
    <>
      <Box paddingY={1}>
        <FormControl fullWidth={true}>
          <InputLabel>Prediction Domain</InputLabel>
          <Input value={formValues.predictionDomain} onChange={createInputChangeHandler('predictionDomain', onChange, formValues)} />
        </FormControl>
      </Box>
      <Box paddingY={1}>
        <FormControlLabel
          control={(
            <Switch
              checked={formValues.interimPredict}
              onChange={createSwitchChangeHandler('interimPredict', onChange, formValues)}
            />
          )}
          label="Interim Prediction Enabled"
        />
      </Box>
    </>
  );
};
