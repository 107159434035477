import { Box, InputLabel, Button } from '@material-ui/core';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import 'codemirror/lib/codemirror.css';
import React from 'react';
import ReactMarkdownWithHtml from 'react-markdown';

type MarkdownEventMapProps = {
  handleChange: () => void,
};

type MarkdownProps = {
  title: string,
  markdown: string,
  handleChange?: (input: string) => void,
  handleClose?: () => void,
  editButtonText?: string,
  disabled?: boolean,
};

const textWrappingStyle = {
  wordBreak: 'break-all',
} as const;

export const MarkdownEditor: React.FC<MarkdownProps> = ({
  title,
  markdown,
  handleChange,
  handleClose,
  editButtonText,
  disabled = false
}) => {
  const [editorOpen, setEditorOpen] = React.useState(false);
  const editorRef = React.useRef<Editor>(null);

  const handleEditorOnChange = (e: any) => {
    if (editorRef.current !== null) {
      const mdText = editorRef.current.getInstance().getMarkdown();
      if (handleChange !== undefined && mdText !== undefined) {
        handleChange(mdText);
      }
    }
  };

  const editorEventMap = {
    change: handleEditorOnChange,
  } as toastui.EventMap;

  const handleCloseCallback = () => {
    setEditorOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Box>
      {title && (
        <Box pt={2}>
          <InputLabel htmlFor="editor">{title}</InputLabel>
        </Box>
      )}
      {editorOpen ? (
        <Box pt={1} minHeight="50px">
          <Editor
            ref={editorRef}
            initialValue={markdown}
            previewStyle="tab"
            initialEditType="markdown"
            useCommandShortcut={true}
            events={editorEventMap}
            usageStatistics={false}
          />
        </Box>
      ) : (
        <Box mt={1} border="1px gray solid" minHeight="50px">
          <div style={textWrappingStyle}>
            <ReactMarkdownWithHtml
              allowDangerousHtml={true}
              children={markdown}
            />
          </div>
        </Box>
      )}

      <Box pt={3}>
        {!editorOpen ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEditorOpen(true)}
            disabled={disabled}
          >
            {editButtonText || 'Edit Markdown'}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseCallback}
            disabled={disabled}
          >
            Close Editor
          </Button>
        )}
      </Box>
    </Box>
  );
};
