import { Box } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';

import { DomainMetadata } from '../components/domain-metadata';

import { DomainsListContainer } from './DomainsListContainer';
import { FeatureSelectorContainer } from './FeatureSelectorContainer';

type StateProps = {};

type DispatchProps = {};

type DomainManagerProps = StateProps & DispatchProps;

class DomainsManagerContainerComponent extends React.Component<DomainManagerProps> {
  render() {
    return (
      <Box p={2}>
        <Switch>
          <Route exact={true} path="/domains" component={DomainsListContainer} />
          <Route path="/domains/:domainId/metadata" component={DomainMetadata} />
          <Route path="/domains/:featureName" component={FeatureSelectorContainer} />
        </Switch>
      </Box>
    );
  }
}

export const DomainsManagerContainer = connect()(DomainsManagerContainerComponent);
