import { Box, Button, Dialog, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearAllMLServiceErrors } from '../state/actions';
import { selectMLConfigErrors } from '../state/selectors';

export const MLServiceErrorModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const errors = useSelector(selectMLConfigErrors);
  const onClose = useCallback(() => dispatch(clearAllMLServiceErrors()), [dispatch]);

  if (errors.length === 0) {
    return null;
  }

  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={onClose}
    >
      <Box padding={4}>
        <Typography variant="h5">Error</Typography>
        <Box marginTop={4}>
          {errors.map((error, i) => (
            <>
              <Box key={i} alignItems="baseline" display="flex">
                <Typography><strong>{error.message}</strong></Typography>
              </Box>
              <Box key={i} alignItems="baseline" display="flex">
                <Typography>{error.toString()}</Typography>
              </Box>
            </>
          ))}
        </Box>
        <Box display="flex" marginTop={5}>
          <Button onClick={onClose} variant="contained">
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
