import { Box, Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EntitySelector } from '../../../components/form/EntitySelector';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { Modal } from '../../../components/ui/Modal';
import { mongoIdPropGetter, findValueFromObject } from '../../../lib/util';
import { Subscription } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { ActivationListTable } from '../components/activation-table';
import {
  deleteActivationModule,
  initializeActivationManagerPage,
  selectActivationManagerSubscription,
} from '../state/actions';
import { subscriptionOptions } from '../state/constants';
import {
  selectCurrentActivationManagerSubscription,
  selectActivationManagerModules,
  selectRemainingActivationManagerTypes
} from '../state/selectors';
import { ActivationEntity } from '../state/types';

type ActivationListColumn = {
  name: keyof ActivationEntity,
  title: string,
  getCellValue?: (row: ActivationEntity) => any
};

const columns: ActivationListColumn[] = [
  {
    name: 'order',
    title: 'Order #'
  },
  {
    name: 'activationType',
    title: 'Activation Type'
  },
  {
    name: 'title',
    title: 'Title'
  },
  {
    name: 'description',
    title: 'Description'
  }
];

export const ActionManagerListContainer: React.FC = () => {
  const [selectedSubscriptionOption, setSelectedSubscriptionOption] = React.useState<SelectOption | null>(null);
  const selectedSubscription = useSelector(selectCurrentActivationManagerSubscription);
  const activationModuleData = useSelector(selectActivationManagerModules);
  const remainingActivationModules = useSelector(selectRemainingActivationManagerTypes);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(initializeActivationManagerPage());
  }, [dispatch]);

  React.useEffect(() => {
    if (selectedSubscription) {
      setSelectedSubscriptionOption({
        label: selectedSubscription,
        value: selectedSubscription
      });
    }
  }, [dispatch, selectedSubscription]);

  const selectSubscriptionCallback = React.useCallback((selectValue: SelectOption) => {
    setSelectedSubscriptionOption(selectValue);
    dispatch(selectActivationManagerSubscription(selectValue.value as Subscription));
  }, [dispatch]);

  const deleteActivationModuleCallback = React.useCallback((id: string) => {
    dispatch(deleteActivationModule(id));
  }, [dispatch]);

  const newActivationModuleCallback = React.useCallback(() => {
    dispatch(push(`${window.location.pathname}/create`));
  }, [dispatch]);

  const editActivationModuleCallback = React.useCallback((id: string) => {
    dispatch(push(`${window.location.pathname}/${id}`));
  }, [dispatch]);

  const [deleteModal, setDeleteModal] = React.useState({open: false, id: '', title: ''});

  const handleDelete = (referenceId: string) => {
    const activationModule = findValueFromObject('_id', referenceId, activationModuleData);
    if (activationModule) {
      setDeleteModal({open: true, id: referenceId, title: activationModule.title});
    }
  };

  const handleCancel = () => {
    setDeleteModal({open: false, id: '', title: ''});
  };

  const handleConfirm = () => {
    deleteActivationModuleCallback(deleteModal.id);
    setDeleteModal({open: false, id: '', title: ''});
  };

  const deleteModalTitle = `Are you sure you want to delete ${deleteModal.id} - ${deleteModal.title}?`;

  return (
    <Box>
      <Box p={2}>
        <EntitySelector
          isClearable={false}
          title="Change Subscription"
          handleChange={selectSubscriptionCallback}
          value={selectedSubscriptionOption}
          objectList={subscriptionOptions}
        />
      </Box>
      <MainContainerCardComponent>
        <Box p={3}>
          <Button disabled={!selectedSubscription || remainingActivationModules.length === 0} variant="contained" color="primary" onClick={newActivationModuleCallback}>New Module</Button>
        </Box>
        <Box>
          <ActivationListTable
            columns={columns}
            data={activationModuleData}
            idPropGetter={mongoIdPropGetter}
            actions={[
              {
                label: 'Edit',
                onClick: editActivationModuleCallback
              },
              {
                label: 'Delete',
                onClick: handleDelete,
                color: 'default'
              }
            ]}
          />
          <Box>
            <Modal title={deleteModalTitle} onConfirm={handleConfirm} onCancel={handleCancel} open={deleteModal.open} />
          </Box>
        </Box>
      </MainContainerCardComponent>
    </Box>
  );
};
