import { Box, Typography, CardHeader, Card, CardContent, Button, CardActions, Grid } from '@material-ui/core';
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { Environment } from '../../../types/core';
import { AccountFormComponent } from '../components/AccountForm';
import { getAccount, deleteAccount, editAccount } from '../state/actions';
import { selectAccount } from '../state/selectors';
import { AccountForm } from '../state/types';
import { isAccountInformationEmpty } from '../util';

type RouteParams = {
  accountId: string,
  env: Environment
};

type EditAccountContainerProps = RouteComponentProps<RouteParams>;

const EditAccountContainer: React.FC<EditAccountContainerProps> = ({
  match
}) => {
  const { env, accountId } = match.params;
  const [account, setAccount] = useState<AccountForm>({
    id: '',
    name: '',
    tax: '',
    taxUS: '',
    employment: '',
    employmentUS: '',
    diagram: '',
    insuranceUS: '',
    hr: '',
    sector: '',
    jurisdiction: ''
  });
  const currentAccount = useSelector(selectAccount);

  useEffect(() => {
    setAccount(currentAccount);
  }, [currentAccount]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccount.request({ env, account: accountId }));
  }, [env, accountId, dispatch]);

  const handleDelete = useCallback(() => {
    dispatch(deleteAccount.request({ env, account: accountId }));
  }, [env, dispatch, accountId]);

  return account ? (
    <Box p={2}>
      <Box pb={3}>
        <Typography variant="h6">
          <BreadcrumbLink to={'/'}>Home</BreadcrumbLink>
          &nbsp;/ <BreadcrumbLink to={`/account-manager/${env}`}>Account Manager - {env === 'live' ? 'Production' : 'Beta'}</BreadcrumbLink>
          &nbsp;/ <strong>{account.name}</strong>
        </Typography>
      </Box>
      <Card>
        {
          env === 'live' ? (
            <CardHeader title={`Production Account - ${currentAccount.name}`} titleTypographyProps={{variant: 'h3'}} style={{color: '#AF2225'}} subheader={currentAccount.sector} />
          ) : (
            <CardHeader title={`Beta Account - ${currentAccount.name}`} titleTypographyProps={{variant: 'h3'}} subheader={currentAccount.sector} />
          )
        }
        <CardContent>
          <Box>
            <Typography variant="body1">User Count: <strong>{currentAccount.userCount}</strong></Typography>
          </Box>
          <AccountFormComponent account={account} setAccount={setAccount} />
        </CardContent>
        <CardActions>
          <Grid container={true}>
            <Box ml={2} mr={2} mb={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={isAccountInformationEmpty(account)}
                onClick={() => dispatch(editAccount.request({ accountData: { ...account, _id: accountId }, env }))}
              >
                Edit
              </Button>
            </Box>
            <Box mb={2}>
              <Button
                disabled={!!currentAccount.userCount && currentAccount.userCount > 0}
                onClick={handleDelete}
                variant="contained"
                color="default"
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  ) : (
    <>
    </>
  );
};

export default EditAccountContainer;
