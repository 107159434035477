import { createAsyncAction } from 'typesafe-actions';

import { FeatureEntity } from '../../../types/core';
import { SimilarDecisionWeightsPayload } from '../types';

export const updateSimilarDecisionsWeights = createAsyncAction(
  '@@INTERNAL/SIMILAR_DECISIONS_WEIGHTS/UPDATE_SIMILAR_DECISIONS/REQUEST',
  '@@INTERNAL/SIMILAR_DECISIONS_WEIGHTS/UPDATE_SIMILAR_DECISIONS/SUCCESS',
  '@@INTERNAL/SIMILAR_DECISIONS_WEIGHTS/UPDATE_SIMILAR_DECISIONS/FAILURE'
)<SimilarDecisionWeightsPayload, FeatureEntity, void>();
