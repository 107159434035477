import { createAsyncAction, createAction } from 'typesafe-actions';

import { ApiError } from '../../../lib/errors/ApiError';
import { Environment, Role, Subscription } from '../../../types/core';
import { FeatureAvailabilityEntity } from '../types';

export const changeEnvironment =
  createAction('@@INTERNAL/FEATURE_AVAILABILITY/CHANGE_ENVIRONMENT')<Environment>();

export const changeFeatureAvailabilityStatus =
  createAction('@@INTERNAL/FEATURE_AVAILABILITY/CHANGE_FEATURE_AVAILABILITY_STATUS')<{
    feature: string;
    subscription: Subscription;
    role?: Role;
    enabled: boolean;
  }>();

export const commitChanges =
  createAction('@@INTERNAL/FEATURE_AVAILABILITY/COMMIT_CHANGES')();

export const fetchFeatureAvailabilities = createAsyncAction(
    '@@INTERNAL/FEATURE_AVAILABILITY/GET_FEATURE_AVAILABILITIES/REQUEST',
    '@@INTERNAL/FEATURE_AVAILABILITY/GET_FEATURE_AVAILABILITIES/SUCCESS',
    '@@INTERNAL/FEATURE_AVAILABILITY/GET_FEATURE_AVAILABILITIES/FAILURE'
  )<Environment, FeatureAvailabilityEntity[], ApiError>();

export const updateFeatureAvailabilities = createAsyncAction(
  '@@INTERNAL/FEATURE_AVAILABILITY/UPDATE_AVAILABILITIES/REQUEST',
  '@@INTERNAL/FEATURE_AVAILABILITY/UPDATE_AVAILABILITIES/SUCCESS',
  '@@INTERNAL/FEATURE_AVAILABILITY/UPDATE_AVAILABILITIES/FAILURE',
)<FeatureAvailabilityEntity[], undefined, ApiError>();

export const loadFeatureAvailabilityList =
  createAction('@@INTERNAL/FEATURE_AVAILABILITY/LOAD')<Environment>();

export const resetChanges =
  createAction('@@INTERNAL/FEATURE_AVAILABILITY/RESET_CHANGES')();

export const confirmChanges = createAction('@@INTERNAL/FEATURE_AVAILABILITY/CONFIRM_CHANGES')();
