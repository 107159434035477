import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers, createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { StateType } from 'typesafe-actions';

import { accountManagerEpics } from '../modules/account-manager/state/epics';
import { accountManagerInitialState, accountManagerReducer } from '../modules/account-manager/state/reducer';
import { activationManagerEpics } from '../modules/activation-manager/state/epics';
import { activationManagerReducer } from '../modules/activation-manager/state/reducer';
import { setAuthData } from '../modules/auth/state/actions';
import { setAuthDataEpic, unauthorizedErrorEpic } from '../modules/auth/state/epics';
import { authReducer } from '../modules/auth/state/reducer';
import { baseEpics } from '../modules/base/state/epics';
import { baseReducer } from '../modules/base/state/reducer';
import { codedCaseManagerEpics } from '../modules/coded-case-manager/state/epics';
import { codedCaseManagerReducer } from '../modules/coded-case-manager/state/reducer';
import { domainManagerEpics } from '../modules/domain-manager/state/epics';
import { domainManagerInitialState, domainManagerReducer } from '../modules/domain-manager/state/reducer';
import { errorEpics } from '../modules/errors/state/epics';
import { errorHandlingReducer, errorHandlingInitialState } from '../modules/errors/state/reducer';
import { featureAvailabilityEpics } from '../modules/feature-availability/state/epics';
import { featureAvailabilityReducer } from '../modules/feature-availability/state/reducer';
import { mlServiceEpics } from '../modules/ml-service-manager/state/epics';
import { mlServiceReducer } from '../modules/ml-service-manager/state/reducer';
import { noticeManagerEpics } from '../modules/notice-manager/state/epics';
import { noticeManagerReducer } from '../modules/notice-manager/state/reducer';
import { questionReferenceManagerEpics } from '../modules/question-reference-manager/state/epics';
import { questionReferenceManagerReducer, questionReferenceManagerInitialState } from '../modules/question-reference-manager/state/reducer';
import { relatedStatuteSectionsEpics } from '../modules/related-statute-sections-manager/state/epics';
import {
  relatedStatuteSectionsReducer,
  relatedStatuteSectionsManagerInitialState
} from '../modules/related-statute-sections-manager/state/reducer';
import { similarDecisionsWeightsEpics } from '../modules/similar-decisions-weights/state/epics';
import { tagManagerEpics } from '../modules/tag-manager/state/epics';
import { tagManagerReducer } from '../modules/tag-manager/state/reducer';
import { translationEpics } from '../modules/translation/state/epics';
import { translatedStringsReducer } from '../modules/translation/state/reducer';
import { Services } from '../services';

import { RootAction } from './actions';

const rootInitialState: Partial<RootState> = {
  questionReferenceManager: questionReferenceManagerInitialState,
  domainManager: domainManagerInitialState,
  errorHandling: errorHandlingInitialState,
  accountManager: accountManagerInitialState,
  relatedStatuteSectionsManager: relatedStatuteSectionsManagerInitialState
};

export const appHistory = createBrowserHistory();

const rootReducer = combineReducers({
  accountManager: accountManagerReducer,
  auth: authReducer,
  base: baseReducer,
  domainManager: domainManagerReducer,
  errorHandling: errorHandlingReducer,
  featureAvailability: featureAvailabilityReducer,
  mlService: mlServiceReducer,
  questionReferenceManager: questionReferenceManagerReducer,
  router: connectRouter(appHistory),
  translatedStringsManager: translatedStringsReducer,
  activationManager: activationManagerReducer,
  noticeManager: noticeManagerReducer,
  relatedStatuteSectionsManager: relatedStatuteSectionsReducer,
  codedCaseManager: codedCaseManagerReducer,
  tagManager: tagManagerReducer
});

export type RootState = StateType<typeof rootReducer>;

export function initializeInternalStore(store: Store) {
  const authToken = window.localStorage.getItem('INTERNAL_API_KEY');
  if (authToken) {
    store.dispatch(setAuthData({ token: authToken }));
  }
}

export const configureStore = (services: Services) => {
  const rootEpic = combineEpics<RootAction, RootAction, RootState, Services>(
    ...baseEpics,
    ...questionReferenceManagerEpics,
    ...domainManagerEpics,
    ...translationEpics,
    ...errorEpics,
    ...featureAvailabilityEpics,
    ...accountManagerEpics,
    ...mlServiceEpics,
    ...activationManagerEpics,
    ...noticeManagerEpics,
    ...relatedStatuteSectionsEpics,
    ...codedCaseManagerEpics,
    ...tagManagerEpics,
    ...similarDecisionsWeightsEpics,
    setAuthDataEpic,
    unauthorizedErrorEpic
  );

  const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: services
  });

  const store = createStore(
    rootReducer,
    rootInitialState,
    composeWithDevTools(
      applyMiddleware(
        epicMiddleware,
        routerMiddleware(appHistory)
      )
    )
  );

  epicMiddleware.run(rootEpic);

  return store;
};
