import { push } from 'connected-react-router';
import * as R from 'ramda';
import { Epic } from 'redux-observable';
import { merge, of, from } from 'rxjs';
import { filter, catchError, withLatestFrom, switchMap, take, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { CuratorEntitySearchError } from '../../../lib/errors/CuratorEntitySearchError';
import { findValueFromObject } from '../../../lib/util';
import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';
import { getDomainList, setFeaturesList } from '../../domain-manager/state/actions';
import { getStringsFromIds } from '../../translation/state/actions';

import {
  getQuestionsForFeature,
  getQuestionReferencesForQuestion,
  submitQuestionReferenceForQuestion,
  deleteQuestionReference,
  editQuestionReference,
  clearQuestionList,
  clearQuestionReferencesList,
  countQuestionReferences,
  getQuestion,
  setCurrentFeature,
  searchCuratorCases,
  duplicateQuestionReference
} from './actions';
import { createSelectQuestionReferenceFromId, createSelectQuestionFromId } from './selectors';

const fetchQuestionsForDomain: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, {internalApi}) =>
  action$.pipe(
    filter(isActionOf(getQuestionsForFeature.request)),
    switchMap((action) => {
      return merge(
        of(clearQuestionList()),
        of(getDomainList.request()),
        action$.pipe(
          filter(isActionOf(setFeaturesList)),
          take(1),
          withLatestFrom(store$),
          switchMap(([_, state]) => {
            const feature = findValueFromObject('_id', action.payload, state.domainManager.featureList);
            if (!feature) {
              return [];
            }
            return from(internalApi.getAllQuestionsFromDomain({selectedDomain: feature.feature})).pipe(
              switchMap((response) => {
                const mongoQuestionIds = R.pluck('_id')(response);
                const questionStringIds = R.flatten(response.map((question) => {
                  return question.prompt ? [question.prompt] : [];
                }));
                return [countQuestionReferences.request(mongoQuestionIds),
                  getStringsFromIds.request(questionStringIds),
                  getQuestionsForFeature.success(response)];
              }),
              catchError((err) => {
                return of(getQuestionsForFeature.failure(err));
              })
            );
          })
        )
      );
    }),
    catchError((err) => {
      return of(getQuestionsForFeature.failure(err));
    })
  );

const fetchReferencesForQuestions: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(getQuestionReferencesForQuestion.request)),
    switchMap((action) => {
      return merge(
        of(clearQuestionReferencesList()),
        of(getQuestion.request(action.payload)),
        from(internalApi.getAllQuestionReferencesForQuestion(action.payload)).pipe(
          map((response) => {
            return getQuestionReferencesForQuestion.success(response);
          }),
          catchError((err) => {
            return of(getQuestionReferencesForQuestion.failure(err));
          })
        )
      );
    }),
    catchError((err) => {
      return of(getQuestionReferencesForQuestion.failure(err));
    })
  );

const postReferenceForQuestion: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(submitQuestionReferenceForQuestion.request)),
    switchMap((action) => {
      const questionReference = {
        title: action.payload.title,
        link: action.payload.link,
        excerpt: action.payload.excerpt,
        initialVoteCount: action.payload.initialVoteCount
      };
      const question = {
        mongoQuestionId: action.payload.mongoQuestionId,
        questionId: action.payload.questionId
      };
      return from(internalApi.createNewQuestionReference({questionReference, question})).pipe(
        switchMap((response) => {
          return [push(`/question-reference-manager/${action.payload.mongoQuestionId}`),
            submitQuestionReferenceForQuestion.success(response),
            getQuestionReferencesForQuestion.request(action.payload.mongoQuestionId || '')];
        }),
        catchError((err) => {
          return of(submitQuestionReferenceForQuestion.failure(err));
        })
      );
    }),
    // TODO: differentiate between the earlier error and this error as being a front-end error
    catchError((err) => {
      return of(submitQuestionReferenceForQuestion.failure(err));
    })
  );

const postDeleteQuestionReference: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(deleteQuestionReference.request)),
    switchMap((action) => {
      return from(internalApi.deleteQuestionReference({id: action.payload.id})).pipe(
        switchMap((response) => {
          return [
            deleteQuestionReference.success(response),
            getQuestionReferencesForQuestion.request(action.payload.mongoQuestionId || ''),
            push(`/question-reference-manager/${action.payload.mongoQuestionId}`)
          ];
        }),
        catchError((err) => {
          return of(deleteQuestionReference.failure(err));
        })
      );
    }),
    // TODO: differentiate between the earlier error and this error as being a front-end error
    catchError((err) => {
      return of(deleteQuestionReference.failure(err));
    })
  );

const postEditQuestionReference: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(editQuestionReference.request)),
    switchMap((action) => {
      const questionReference = {
        id: action.payload.id,
        title: action.payload.title,
        link: action.payload.link,
        excerpt: action.payload.excerpt,
        initialVoteCount: action.payload.initialVoteCount
      };
      return from(internalApi.editQuestionReference({questionReference})).pipe(
        switchMap((response) => {
          return [editQuestionReference.success(response),
            getQuestionReferencesForQuestion.request(action.payload.mongoQuestionId || ''),
            push(`/question-reference-manager/${action.payload.mongoQuestionId}`)];
        }),
        catchError((err) => {
          return of(editQuestionReference.failure(err));
        })
      );
    }),
    catchError((err) => {
      return of(deleteQuestionReference.failure(err));
    })
  );

const fetchCountQuestionReferences: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(countQuestionReferences.request)),
    switchMap((action) => {
      return from(internalApi.countQuestionReferencesByQuestion({questionIds: action.payload})).pipe(
        map((response) => {
          return countQuestionReferences.success(response);
        }),
        catchError((innerErr) => {
          return of(countQuestionReferences.failure(innerErr));
        })
      );
    }),
    catchError((err) => {
      return of(countQuestionReferences.failure(err));
    })
  );

const fetchQuestionInformation: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(getQuestion.request)),
    switchMap((action) => {
      return from(internalApi.getQuestionInformationById({questionId: action.payload})).pipe(
        switchMap((response) => {
          return from(internalApi.getFeatureByName({featureName: response.domain})).pipe(
            switchMap((featureResponse) => {
              return [setCurrentFeature(featureResponse),
                getQuestion.success(response),
                getStringsFromIds.request([response.prompt || ''])];
            }),
            catchError((err) => {
              return of(getQuestion.failure(err));
            })
          );
        }),
        catchError((err) => {
          return of(getQuestion.failure(err));
        })
      );
    })
  );

const fetchCasesFromCurator: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) => {
  return action$.pipe(
    filter(isActionOf(searchCuratorCases.request)),
    switchMap((action) => {
      return from(curatorApi.searchCases({filter: action.payload, pagination: {page: 1, perPage: 20}})).pipe(
        switchMap((response) => [searchCuratorCases.success(response.data)]),
        catchError((err) => of(searchCuratorCases.failure(new CuratorEntitySearchError(err.message))))
      );
    })
  );
};

const postDuplicateQuestionReference: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { internalApi }) => {
  return action$.pipe(
    filter(isActionOf(duplicateQuestionReference.request)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const questionReference = createSelectQuestionReferenceFromId(action.payload.referenceId)(state);
      const question = createSelectQuestionFromId(action.payload.questionId)(state);
      if (questionReference && question) {
        const newQuestionReference = {
          title: questionReference.title,
          link: questionReference.link,
          excerpt: questionReference.excerpt,
          initialVoteCount: questionReference.initialVoteCount
        };
        const newQuestion = {
          mongoQuestionId: action.payload.questionId,
          questionId: question.id
        };
        return from(internalApi.createNewQuestionReference({questionReference: newQuestionReference, question: newQuestion})).pipe(
          map(() => duplicateQuestionReference.success()),
          catchError((err) => of(duplicateQuestionReference.failure(err)))
        );
      }
      return [];
    })
  );
};

export const questionReferenceManagerEpics = [
  fetchQuestionsForDomain,
  fetchReferencesForQuestions,
  postReferenceForQuestion,
  postDeleteQuestionReference,
  postEditQuestionReference,
  fetchCountQuestionReferences,
  fetchQuestionInformation,
  fetchCasesFromCurator,
  postDuplicateQuestionReference
];
