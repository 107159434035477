import * as React from 'react';
import { FocusEventHandler } from 'react-select';
import AsyncSelect from 'react-select/async';

import { SelectOption } from '../../types/util';

type AsyncSelectFieldProps = {
  value: SelectOption | null;
  defaultOptions?: SelectOption[];
  disabled?: boolean;
  invalid?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  onBlur?: FocusEventHandler;
  onChange: (value: any) => void;
  asyncLoadOptions: (arg: string) => void;
  placeHolder?: string;
  maxMenuHeight?: number;
};

export const AsyncSelectField: React.FC<AsyncSelectFieldProps> = ({
  value,
  defaultOptions,
  placeHolder,
  isMulti,
  isClearable,
  asyncLoadOptions,
  onChange,
  disabled = false,
  maxMenuHeight = 130
}) => {
  const loadOptions = async (inputValue: string) => {
    return await asyncLoadOptions(inputValue);
  };

  return (
    <AsyncSelect
      value={value}
      defaultOptions={defaultOptions || []}
      placeholder={placeHolder}
      isMulti={isMulti}
      isClearable={isClearable}
      loadOptions={loadOptions}
      onChange={onChange}
      disabled={disabled}
      maxMenuHeight={maxMenuHeight}
    />
  );
};
