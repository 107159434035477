import { createAsyncAction, createAction } from 'typesafe-actions';

import { Subscription } from '../../../types/core';

import { ActivationEntity, SAVE_MODE } from './types';

export const selectActivationManagerSubscription = createAction(
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/SELECT_SUBSCRIPTION'
)<Subscription>();

export const initializeActivationManagerPage = createAction(
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/INITIALIZE_ACTIVATION_MANAGER'
)<void>();

export const fetchActivationModulesForSubscription = createAsyncAction(
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/FETCH_ACTIVATION_MODULES_FOR_SUBSCRIPTION/REQUEST',
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/FETCH_ACTIVATION_MODULES_FOR_SUBSCRIPTION/SUCCESS',
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/FETCH_ACTIVATION_MODULES_FOR_SUBSCRIPTION/FAILURE',
)<string, ActivationEntity[], void>();

export const deleteActivationModule = createAction(
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/DELETE_ACTIVATION_MODULE'
)<string>();

export const deleteActivationModuleData = createAsyncAction(
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/DELETE_ACTIVATION_MODULE_DATA/REQUEST',
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/DELETE_ACTIVATION_MODULE_DATA/SUCCESS',
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/DELETE_ACTIVATION_MODULE_DATA/FAILURE'
)<string, void, void>();

export const createActivationModuleData = createAsyncAction(
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/CREATE_ACTIVATION_MODULE/REQUEST',
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/CREATE_ACTIVATION_MODULE/SUCCESS',
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/CREATE_ACTIVATION_MODULE/FAILURE'
)<ActivationEntity, void, void>();

export const updateActivationModuleData = createAsyncAction(
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/UPDATE_ACTIVATION_MODULE/REQUEST',
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/UPDATE_ACTIVATION_MODULE/SUCCESS',
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/UPDATE_ACTIVATION_MODULE/FAILURE'
)<ActivationEntity, void, void>();

export const saveActivationModule = createAction(
  'BLUE_J_INTERNAL/ACTIVATION_MANAGER/SAVE_ACTIVATION_MODULE'
)<ActivationEntity, { mode: SAVE_MODE }>();
