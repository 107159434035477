import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';

import * as accountManagerActions from './actions';
import { AccountManagerState } from './types';

export const accountManagerInitialState = {
  accountList: [],
  currentAccount: {
    _id: '',
    id: '',
    name: '',
    sector: '',
    jurisdiction: '',
    tax: '',
    taxUS: '',
    employment: '',
    employmentUS: '',
    hr: '',
    insuranceUS: '',
    diagram: '',
    userCount: 0
  },
  sectors: [],
  jurisdictions: []
};

export const accountManagerReducer = createReducer<AccountManagerState, RootAction>(accountManagerInitialState)
  .handleAction(accountManagerActions.getAccounts.success, (state, action) => {
    return {
      ...state,
      accountList: action.payload
    };
  })
  .handleAction(accountManagerActions.getAccount.success, (state, action) => {
    return {
      ...state,
      currentAccount: action.payload
    };
  })
  .handleAction(accountManagerActions.getAccounts.request, (state) => {
    return {
      ...state,
      accountList: []
    };
  })
  .handleAction(accountManagerActions.getSectors.success, (state, action) => {
    return {
      ...state,
      sectors: action.payload
    };
  })
  .handleAction(accountManagerActions.getJurisdictions.success, (state, action) => {
    return {
      ...state,
      jurisdictions: action.payload
    };
  });
