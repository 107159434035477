import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { ModulesContainer } from './ModulesContainer';

const LandingContainerComponent = () => (
  <div>
    <Switch>
      <Route exact={true} path="/" component={ModulesContainer} />
    </Switch>
  </div>
);

export const LandingContainer = connect()(LandingContainerComponent);
