import {
  createAction,
  createAsyncAction
} from 'typesafe-actions';

import {
  CreateTagPayload,
  EditTagPayload,
  Tag,
  GetTagsForItemSuccess,
  UpdateItemTags
} from '../types';

export const createTag = createAsyncAction(
  '@@INTERNAL/TAGS/CREATE/REQUEST',
  '@@INTERNAL/TAGS/CREATE/SUCCESS',
  '@@INTERNAL/TAGS/CREATE/FAILURE'
)<CreateTagPayload, Tag, void>();

export const editTag = createAsyncAction(
  '@@INTERNAL/TAGS/EDIT/REQUEST',
  '@@INTERNAL/TAGS/EDIT/SUCCESS',
  '@@INTERNAL/TAGS/EDIT/FAILURE'
)<EditTagPayload, Tag, void>();

export const getTags = createAsyncAction(
  '@@INTERNAL/TAGS/GET/REQUEST',
  '@@INTERNAL/TAGS/GET/SUCCESS',
  '@@INTERNAL/TGS/GET/FAILURE'
)<void, Tag[], void>();

export const deleteTag = createAsyncAction(
  '@@INTERNAL/TAGS/DELETE/REQUEST',
  '@@INTERNAL/TAGS/DELETE/SUCCESS',
  '@@INTERNAL/TAGS/DELETE/FAILURE'
)<string, void, void>();

export const getTagsForItem = createAsyncAction(
  '@@INTERNAL/TAGS/ITEM_GET/REQUEST',
  '@@INTERNAL/TAGS/ITEM_GET/SUCCESS',
  '@@INTERNAL/TAGS/ITEM_GET/FAILURE'
)<string, GetTagsForItemSuccess, void>();

export const updateItemTags = createAsyncAction(
  '@@INTERNAL/TAGS/ITEM_UPDATE/REQUEST',
  '@@INTERNAL/TAGS/ITEM_UPDATE/SUCCESS',
  '@@INTERNAL/TAGS/ITEM_UPDATE/FAILURE'
)<UpdateItemTags, GetTagsForItemSuccess, void>();

export const openDeleteTagModal = createAction('@@INTERNAL/TAGS/DELETE/OPEN/MODAL')<string>();
export const closeDeleteTagModal = createAction('@@INTERNAL/TAGS/DELETE/CLOSE/MODAL')<void>();
