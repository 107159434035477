import { CuratorErrorType, ICuratorError } from './CuratorError';

export class CuratorEntitySearchError extends Error implements ICuratorError {
  public readonly status: number = 500;
  public readonly type: CuratorErrorType = CuratorErrorType.Search;
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, CuratorEntitySearchError.prototype);
  }
}
