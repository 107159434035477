import { Box, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { findValueFromObject } from '../../../lib/util';
import { NoticeFormComponent } from '../components/notice-form.component';
import { saveNoticeModule } from '../state/actions';
import { selectCurrentNoticeManagerSubscription, selectNoticeManagerModules } from '../state/selectors';
import { NoticeEntity, SAVE_MODE } from '../state/types';

export const EditNoticeContainer: React.FC = () => {
  const dispatch = useDispatch();
  const parentSubscription = useSelector(selectCurrentNoticeManagerSubscription);
  const [noticeModule, setNoticeModule] = React.useState<NoticeEntity>(
    {
      _id: '',
      subscription: '',
      title: '',
      title_fr: '',
      body: '',
      body_fr: '',
      enabled: false,
    });
  const { id } = useParams<{ id: string }>();
  const noticeManagerModuleList = useSelector(selectNoticeManagerModules);

  React.useEffect(() => {
    const initialNoticeModule: NoticeEntity | undefined = findValueFromObject<NoticeEntity>('_id', id, noticeManagerModuleList);
    if (initialNoticeModule) {
      setNoticeModule(initialNoticeModule);
    }
  }, [noticeManagerModuleList, id]);

  React.useEffect(() => {
    if (!parentSubscription) {
      dispatch(push('/notice-manager'));
    }
  }, [parentSubscription, dispatch]);

  const handleChange = (field: string, value: string | number | boolean) => {
    const updatedNoticeModule = {
      ...noticeModule,
      [field]: value
    };
    setNoticeModule(updatedNoticeModule);
  };

  const submitNoticeModule = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    const moduleWithSubscription = {
      ...noticeModule,
    } as NoticeEntity;
    dispatch(saveNoticeModule(moduleWithSubscription, { mode: SAVE_MODE.EDIT }));
  }, [dispatch, noticeModule]);

  return (
    <>
      <Box pb={3}>
        <Typography variant="h6">
          <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
          <BreadcrumbLink to={'/notice-manager'}>Notice Manager </BreadcrumbLink>
          / {id}
        </Typography>
      </Box>
      <MainContainerCardComponent>
        {
          noticeModule._id ?
            (
              <NoticeFormComponent
                handleChange={handleChange}
                handleSubmit={submitNoticeModule}
                noticeModule={noticeModule as NoticeEntity}
                subscription={parentSubscription!}
              />
            ) : (
              <Box>
                Notice not found
              </Box>
            )
        }
      </MainContainerCardComponent>
    </>
  );
}
