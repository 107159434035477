import {
  Grid,
  Table,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';
import { Switch, Checkbox } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import { RoleDisplayNames, SubscriptionDisplayNames } from '../../../lib/constants';
import { RootState } from '../../../store/root';
import { Role, Subscription } from '../../../types/core';
import {
  changeFeatureAvailabilityStatus as changeFeatureAvailabilityStatusActionCreator
} from '../state/actions';
import { selectFeatureAvailabilityMatrixByFeature } from '../state/selectors';

type OwnProps = {
  row: string;
};

type Row = {
  name: Subscription;
  enabled: boolean;
  role?: Record<string, boolean>;
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  featureAvailabilityMatrix: selectFeatureAvailabilityMatrixByFeature(state, props)
});

const mapDispatchToProps = {
  changeFeatureAvailabilityStatus: changeFeatureAvailabilityStatusActionCreator
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

const createColumnDefinition = (
  featureName: string,
  changeFeatureAvailabilityStatus: typeof changeFeatureAvailabilityStatusActionCreator
) => [
  {
    name: 'Subscription Name',
    value: 'subscriptionName',
    getCellValue: (row: Row) => SubscriptionDisplayNames[row.name],
  },
  {
    name: 'Enabled',
    value: 'enabled',
    getCellValue: (row: Row) => (
      <Checkbox
        checked={row.enabled}
        onChange={() => {
          changeFeatureAvailabilityStatus({
            feature: featureName,
            subscription: row.name,
            enabled: !row.enabled
          });
        }}
      />
    )
  },
  ...Object.keys(RoleDisplayNames).map((role) => ({
    name: RoleDisplayNames[role as Role],
    value: role,
    getCellValue: (row: Row) => {
      const checked = row.role && row.role[role];
      return (
        <Switch
          checked={checked}
          onChange={() =>
            changeFeatureAvailabilityStatus({
              feature: featureName,
              subscription: row.name,
              role: role as Role,
              enabled: row.enabled
            })
          }
        />
      );
    }
  }))
];

const FeatureAvailabilityRowDetailComponent: React.FunctionComponent<Props> = ({
  row: featureName,
  featureAvailabilityMatrix,
  changeFeatureAvailabilityStatus
}) => {
  const columns = createColumnDefinition(featureName, changeFeatureAvailabilityStatus);
  const rows = (Object.keys(SubscriptionDisplayNames) as Subscription[]).map((subscription) => ({
    name: subscription,
    enabled: ((featureAvailabilityMatrix && featureAvailabilityMatrix.enabledSubscriptions) || []).includes(subscription),
    role: featureAvailabilityMatrix && featureAvailabilityMatrix.availability[subscription]
  }));

  return (
    <Grid columns={columns} rows={rows}>
      <Table />
      <TableHeaderRow />
    </Grid>
  );
};

export const FeatureAvailabilityRowDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureAvailabilityRowDetailComponent);
