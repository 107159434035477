import * as React from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useParams } from 'react-router';

import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { editTag } from '../state/actions';
import { createSelectTagById } from '../state/selectors';

import { TagBreadcrumb } from './tag-breadcrumb.component';
import { TagForm } from './tag-form.component';

export const EditTag = () => {
  const { id } = useParams<{id: string}>();
  const tag = useSelector(createSelectTagById(id));

  const dispatch = useDispatch();
  const handleSubmit = (displayNameEnglish: string, displayNameFrench: string, subscriptions: string[]) => {
    dispatch(editTag.request({
      id,
      displayName_en: displayNameEnglish,
      displayName_fr: displayNameFrench,
      subscriptions
    }));
  };

  return (
    <>
      <TagBreadcrumb currentPage="Edit Tag" />
      <MainContainerCardComponent>
        {tag && <TagForm tag={tag} handleSubmit={handleSubmit} />}
      </MainContainerCardComponent>
    </>
  );
};
