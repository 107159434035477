import { ErrorType, IInternalAPIError } from './types';

export class UnauthorizedError extends Error implements IInternalAPIError {
  public readonly status: number = 401;
  public readonly type: ErrorType = ErrorType.Unauthorized;
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}
