import MuiTextField from '@material-ui/core/TextField';
import * as React from 'react';

export type TextFieldProps = {
  label?: string,
  value?: string | number,
  onChange: (input: string) => void,
  disabled?: boolean,
  required?: boolean,
  helperText?: string,
  type?: string,
  id?: string,
  defaultValue?: string | number
};

export const TextField = ({label, value, onChange, disabled = false, required = false, helperText = '', type, id, defaultValue}: TextFieldProps) => (
  <MuiTextField
    value={value}
    label={label}
    margin="normal"
    disabled={disabled}
    required={required}
    onChange={(event) => onChange(event.target.value)}
    type={type || typeof value}
    variant="outlined"
    helperText={helperText}
    fullWidth={true}
    id={id}
    defaultValue={defaultValue}
  />
);
