import { Box } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, RouteComponentProps } from 'react-router';

import { EntitySelector } from '../../../components/form/EntitySelector';
import { findValueFromObject } from '../../../lib/util';
import { SelectOption } from '../../../types/util';
import { selectClassifiersFlowsListAsSelectedOption } from '../../domain-manager/state/selectors';

import { AddQuestionReferenceContainer } from './AddQuestionReferenceContainer';
import { EditQuestionReferenceContainer } from './EditQuestionReferenceContainer';
import { QuestionListContainer } from './QuestionListContainer';
import { QuestionReferenceListContainer } from './QuestionReferenceListContainer';

type RouteParams = {
  featureName: string;
};

type Props = RouteComponentProps<RouteParams>;

const QuestionReferenceManagerComponent: React.FC<Props> = ({ match }) => {
  const featureSelectOptionList = useSelector(selectClassifiersFlowsListAsSelectedOption);
  const feature = findValueFromObject<SelectOption>('value', match.params.featureName, featureSelectOptionList);

  const dispatch = useDispatch();
  const pushQuestionReferenceManager = (f: SelectOption) => dispatch(push(`/domains/${f ? f.value : ''}/question-reference-manager`));
  return (
    <>
      {feature && <EntitySelector isClearable={false} title="Change Feature" handleChange={pushQuestionReferenceManager} value={feature} objectList={featureSelectOptionList} />}
      <Box p={2}>
        <Switch>
          <Route exact={true} path="/domains/:featureName/question-reference-manager" component={QuestionListContainer} />
          <Route path="/question-reference-manager/:questionId/edit-reference/:referenceId" component={EditQuestionReferenceContainer} />
          <Route path="/question-reference-manager/:questionId/add-reference" component={AddQuestionReferenceContainer} />
          <Route path="/question-reference-manager/:questionId" component={QuestionReferenceListContainer} />
        </Switch>
      </Box>
    </>
  );
};

export const QuestionReferenceManagerContainer = QuestionReferenceManagerComponent;
