import { getSearch, push } from 'connected-react-router';
import { parse } from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { RootAction } from '../../store/actions';
import { RootState } from '../../store/root';

import { setAuthData } from './state/actions';
import { AuthData } from './state/types';

const mapStateToProps = (state: RootState) => ({
  query: parse(getSearch(state))
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  setAuthData: (authData: AuthData) => dispatch(setAuthData(authData)),
  goToMain: () => dispatch(push('/'))
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

class AuthHandlerContainer extends React.Component<StateProps & DispatchProps> {
  componentDidMount(): void {
    if (typeof this.props.query.token === 'string') {
      this.props.setAuthData({
        token: this.props.query.token
      });
    }
    this.props.goToMain();
  }

  render() {
    return null;
  }
}

export const AuthHandler = connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(AuthHandlerContainer);
