import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { connect } from 'react-redux';

import { Environment } from '../../../types/core';
import { FeatureAvailabilityBottomToolbar } from '../components/FeatureAvailabilityBottomToolbar';
import { FeatureAvailabilityTopToolbar } from '../components/FeatureAvailabilityTopToolbar';
import { changeEnvironment as changeEnvironmentActionCreator, commitChanges as commitChangesActionCreator, confirmChanges as confirmChangesActionCreator, resetChanges as resetChangesActionCreator } from '../state/actions';
import storeConnector from '../state/selectors';

import { FeatureAvailabilityList } from './FeatureAvailabilityList';

const mapDispatchToProps = {
  changeEnvironment: changeEnvironmentActionCreator,
  commitChanges: commitChangesActionCreator,
  confirmChanges: confirmChangesActionCreator,
  resetChanges: resetChangesActionCreator,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof storeConnector>;
type Props = StateProps & DispatchProps;

const FeatureAvailabilityComponent: React.FunctionComponent<Props> = ({ changeEnvironment, commitChanges, confirmChanges, environments, resetChanges, selectedEnvironment, updateDiff }) => (
  <>
    {selectedEnvironment.value === Environment.LIVE && (
      <Box position="sticky" top="0" zIndex="999">
        <Alert severity="error">
          Warning: Editing {selectedEnvironment.label} environment.
        </Alert>
      </Box>
    )}
    <FeatureAvailabilityTopToolbar
      changeEnvironment={changeEnvironment}
      environments={environments}
      selectedEnvironment={selectedEnvironment}
    />

    <FeatureAvailabilityList />

    <FeatureAvailabilityBottomToolbar
      commitChanges={commitChanges}
      confirmChanges={confirmChanges}
      resetChanges={resetChanges}
      selectedEnvironment={selectedEnvironment}
      updateDiff={updateDiff}
    />
  </>
);

export const FeatureAvailability = connect(storeConnector, mapDispatchToProps)(FeatureAvailabilityComponent);
