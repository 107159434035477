import { Box } from '@material-ui/core';
import * as React from 'react';
import { Switch, Route } from 'react-router';

import { CreateTag } from './create-tag.component';
import { EditTag } from './edit-tag.component';
import { TagManagerList } from './tag-manager-list.component';

export const TagManager: React.FC = () => {
  return (
    <Box p={2}>
      <Switch>
        <Route path="/tag-manager/" exact={true} component={TagManagerList} />
        <Route path="/tag-manager/create" component={CreateTag} />
        <Route path="/tag-manager/:id" component={EditTag} />
      </Switch>
    </Box>
  );
};
