import { RouterAction } from 'connected-react-router';
import { ActionType } from 'typesafe-actions';

import * as accountManagerActions from '../modules/account-manager/state/actions';
import * as activationManagerActions from '../modules/activation-manager/state/actions';
import * as authActions from '../modules/auth/state/actions';
import * as baseActions from '../modules/base/state/actions';
import * as codedCaseManagerActions from '../modules/coded-case-manager/state/actions';
import * as domainManagerActions from '../modules/domain-manager/state/actions';
import * as errorActions from '../modules/errors/state/actions';
import * as featureAvailabilityActions from '../modules/feature-availability/state/actions';
import * as mlServiceActions from '../modules/ml-service-manager/state/actions';
import * as noticeManagerActions from '../modules/notice-manager/state/actions';
import * as questionReferenceManagerActions from '../modules/question-reference-manager/state/actions';
import * as relatedStatuteSectionsActions from '../modules/related-statute-sections-manager/state/actions';
import * as similarDecisionsWeightsActions from '../modules/similar-decisions-weights/state/actions';
import * as tagManagerActions from '../modules/tag-manager/state/actions';
import * as translationActions from '../modules/translation/state/actions';

export type RootAction =
  ActionType<typeof baseActions> |
  ActionType<typeof questionReferenceManagerActions> |
  ActionType<typeof domainManagerActions> |
  ActionType<typeof authActions> |
  ActionType<typeof translationActions> |
  ActionType<typeof featureAvailabilityActions> |
  ActionType<typeof errorActions> |
  ActionType<typeof accountManagerActions> |
  ActionType<typeof mlServiceActions> |
  ActionType<typeof activationManagerActions> |
  ActionType<typeof noticeManagerActions> |
  ActionType<typeof relatedStatuteSectionsActions> |
  ActionType<typeof codedCaseManagerActions> |
  ActionType<typeof tagManagerActions> |
  ActionType<typeof similarDecisionsWeightsActions> |
  RouterAction;

export const failActions = [
  baseActions.fetchFeatures.failure,
  domainManagerActions.getDomainList.failure,
  featureAvailabilityActions.fetchFeatureAvailabilities.failure,
  featureAvailabilityActions.updateFeatureAvailabilities.failure,
  questionReferenceManagerActions.deleteQuestionReference.failure,
  questionReferenceManagerActions.getQuestionsForFeature.failure,
  questionReferenceManagerActions.editQuestionReference.failure,
  questionReferenceManagerActions.submitQuestionReferenceForQuestion.failure,
  translationActions.getStringsFromIds.failure,
  accountManagerActions.getAccounts.failure,
  mlServiceActions.fetchMLServices.failure,
];

export const successActions = [
  questionReferenceManagerActions.deleteQuestionReference.success,
  questionReferenceManagerActions.editQuestionReference.success,
  questionReferenceManagerActions.submitQuestionReferenceForQuestion.success,
  mlServiceActions.fetchMLServices.success
];
