import { Subscription } from '../../../types/core';

export type NoticeEntity = {
  _id: string;
  subscription: string,
  title: string,
  title_fr: string,
  body: string;
  body_fr: string;
  enabled: boolean;
};

export type NoticeManagerState = {
  subscription: Subscription | null;
  noticeModules: NoticeEntity[];
};

export enum SAVE_MODE {
  CREATE = 'create',
  EDIT = 'edit'
}
