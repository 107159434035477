import { Typography, Box } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store/root';
import { selectError } from '../state/selectors';

const mapStateToProps = (state: RootState) => ({
  error: selectError(state)
});

type StateProps = ReturnType<typeof mapStateToProps>;

type ErrorBoundaryProps = StateProps;

class ErrorBoundaryContainerComponent extends React.Component<ErrorBoundaryProps> {
  render() {
    if (this.props.error) {
      return (
        <>
          <Box p={3} textAlign={'center'}>
            <Typography variant={'h4'} color={'secondary'}>{this.props.error.status} - {this.props.error.message}</Typography>
            <Typography variant={'h6'} color={'primary'}>Type: {this.props.error.errorType}</Typography>
            <Typography variant={'h6'} color={'primary'}>URL: {this.props.error.url}</Typography>
            <Typography variant={'body1'} color={'primary'}>Error Message: {JSON.stringify(this.props.error.errorMessage)}</Typography>
          </Box>
        </>
      );
    }
    return null;
  }
}

export const ErrorBoundaryContainer = connect<StateProps, {}, {}, RootState>(mapStateToProps)(ErrorBoundaryContainerComponent);
