export function getApiUrl(): string {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
  switch (hostname) {
    case 'manage.dev.bluej.co':
      return 'https://api.internal.dev.bluej.co';
    case 'manage.bluej.co':
      return 'https://api.internal.prod.bluej.co';
    default:
      return process.env.REACT_APP_API_URL || 'http://localhost:4001';
  }
}

export function getCuratorAPIUrl(): string {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
  switch (hostname) {
    case 'manage.dev.bluej.co':
      return 'https://api.curator.dev.bluej.co/api';
    case 'manage.bluej.co':
      return 'https://api.curator.bluej.co/api';
    default:
      return 'https://api.curator.bluej.co/api';
  }
}

export function getBookcaseUrl(): string {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
  switch (hostname) {
    case 'manage.dev.bluej.co':
      return 'https://bookcase.dev.bluej.co/api';
    case 'manage.bluej.co':
      return 'https://bookcase.beta.bluej.co/api';
    default:
      return process.env.BOOKCASE_API_URL || 'https://bookcase.dev.bluej.co/api';
  }
}

export type AppConfig = {
  apiUrl: string,
  curatorApiUrl: string,
  authLocation: string,
  bookcaseUrl: string
};

let memoizedAppConfig: AppConfig;
export function getAppConfig(): AppConfig {
  if (!memoizedAppConfig) {
    memoizedAppConfig = {
      apiUrl: getApiUrl(),
      curatorApiUrl: getCuratorAPIUrl(),
      authLocation: process.env.REACT_APP_AUTH_REDIRECT || 'https://internal.bluej.co',
      bookcaseUrl: getBookcaseUrl()
    };
  }
  return memoizedAppConfig;
}
