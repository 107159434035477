import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import {
  from,
  of
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  ignoreElements
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';

import {
  createTag,
  deleteTag,
  editTag,
  getTags,
  updateItemTags,
  getTagsForItem
} from './actions';

export const getTagsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(getTags.request)),
    switchMap(() => {
      return from(bookcase.getTags()).pipe(
        map((response) => getTags.success(response)),
        catchError(() => of(getTags.failure()))
      );
    })
  );

export const createTagEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(createTag.request)),
    switchMap(({ payload }) => {
      return from(bookcase.createTag(payload)).pipe(
        map((response) => createTag.success(response)),
        catchError(() => of(createTag.failure()))
      );
    })
  );

export const createTagSuccessEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(createTag.success)),
    map(({ payload }) => {
      toast.success(`Tag ${payload.id} created successfully`);
      return push('/tag-manager');
    })
  );

export const createTagFailureEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(createTag.failure)),
    map(({ payload }) => {
      toast.success(`Failed creating tag`);
      return push('/tag-manager');
    })
  );

export const editTagEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(editTag.request)),
    switchMap(({ payload }) => {
      return from(bookcase.editTag(payload.id)(payload)).pipe(
        map((response) => editTag.success(response)),
        catchError(() => of(editTag.failure()))
      );
    })
  );

export const editTagSuccessEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(editTag.success)),
    map(({ payload }) => {
      toast.success(`Tag ${payload.id} edited successfully`);
      return push('/tag-manager');
    })
  );

export const editTagFailureEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(editTag.failure)),
    map(({ payload }) => {
      toast.success(`Failed editing tag`);
      return push('/tag-manager');
    })
  );

export const deleteTagEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(deleteTag.request)),
    switchMap(({ payload: id }) => {
      return from(bookcase.deleteTag(id)()).pipe(
        map(() => deleteTag.success()),
        catchError(() => of(deleteTag.failure()))
      );
    })
  );

export const deleteTagSuccessEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(deleteTag.success)),
    map(({ payload }) => {
      toast.success(`Successfully deleted tag`);
      return getTags.request();
    })
  );

export const deleteTagFailureEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(deleteTag.failure)),
    map(({ payload }) => {
      toast.error(`Failed deleting tag`);
      return push('/tag-manager');
    })
  );

export const getTagsForItemEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(getTagsForItem.request)),
    switchMap(({ payload }) => {
      return from(bookcase.getTagsForItem(payload)()).pipe(
        map((response) => {
          if (response === null) {
            return getTagsForItem.success({
              id: payload,
              tags: []
            });
          }

          return getTagsForItem.success(response);
        }),
        catchError(() => of(getTagsForItem.failure()))
      );
    })
  );

export const updateItemTagsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { bookcase }) =>
  action$.pipe(
    filter(isActionOf(updateItemTags.request)),
    switchMap(({ payload }) => {
      return from(bookcase.updateItemTags(payload.id)({
        tags: payload.tags,
        type: payload.type
      })).pipe(
        map((response) => {
          if (response === null) {
            return updateItemTags.success({
              id: payload.id,
              tags: [],
            });
          }

          return updateItemTags.success(response);
        }),
        catchError(() => of(updateItemTags.failure()))
      );
    })
  );

export const updateItemTagsSuccessEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateItemTags.success)),
    map(() => {
      toast.success(`Successfully updated tags`);
    }),
    ignoreElements()
  );

export const tagManagerEpics = [
  createTagEpic,
  createTagFailureEpic,
  createTagSuccessEpic,
  deleteTagEpic,
  deleteTagFailureEpic,
  deleteTagSuccessEpic,
  editTagEpic,
  editTagFailureEpic,
  editTagSuccessEpic,
  getTagsEpic,
  getTagsForItemEpic,
  updateItemTagsEpic,
  updateItemTagsSuccessEpic
];
