import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { PropTypes, Box, Button, IconButton } from '@material-ui/core';
import { ReactElement } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { RootAction } from '../../store/actions';

const TableRow = styled.tr<Table.DataRowProps>`
  &:hover {
    background-color: #f9f9f9;
  }
`;

type TableColumn = {
  name: string,
  title: string
};

type EntityId = string;

type EntitySelectedCallback = (entityId: EntityId) => RootAction | void;

type EntityAction = {
  onClick: EntitySelectedCallback,
  label?: string,
  color?: PropTypes.Color,
  icon?: ReactElement
};

interface IEntityTableComponentProps<Entity> {
  columns: TableColumn[];
  data: Entity[];
  actions: EntityAction[];
  tableColumnExtensions?: Table.ColumnExtension[];
  idPropGetter: (data: Entity) => string;
}

type ActionButtonProps = EntityAction & {
  id: string
};

const ActionButton = (props: ActionButtonProps) => (
  <Box mr={2} ml={1} mb={1} display="inline-block">
    {
      props.icon ? (
        <IconButton color={props.color || 'primary'} onClick={() => props.onClick(props.id)} size="small">
          {props.icon}
        </IconButton>
      ) : (
        <Button onClick={() => props.onClick(props.id)} variant="contained" color={props.color || 'primary'}>{props.label || 'Action'}</Button>
      )
    }
  </Box>
);

function addActionColumn<TColumnData>(
  data: TColumnData,
  idPropGetter: (data: TColumnData) => string,
  actions: EntityAction[]
) {
  const id = idPropGetter(data);
  return {
    ...data,
    action: (
      <>
        { actions.map((action) => (
          <ActionButton key={action.label} id={id} {...action} />
        ))}
      </>
    )
  };
}

export class DataTable<TColumnData>
  extends React.Component<IEntityTableComponentProps<TColumnData>> {
  render() {
    const columns = [
      ...this.props.columns,
      {
        name: 'action',
        title: 'Actions'
      }
    ];

    const data = Array.isArray(this.props.data) ?
      this.props.data.map((item) =>
        addActionColumn<TColumnData>(item, this.props.idPropGetter, this.props.actions)) : [];

    return (
      <Grid rows={data} columns={columns}>
        <SortingState />
        <IntegratedSorting />
        <Table rowComponent={TableRow} columnExtensions={this.props.tableColumnExtensions} />
        <TableHeaderRow showSortingControls={true} />
      </Grid>
    );
  }
}
