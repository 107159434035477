import * as R from 'ramda';

export function findValueFromObject<TObject extends Record<string, any>>(valueTitle: string, value: string, object: TObject[]): TObject | undefined {
  return R.find(R.propEq(valueTitle, value))(object) as TObject | undefined;
}

export function genericIdPropGetter<T extends Record<'id', string>>(data: T) {
  return data.id;
}

export function mongoIdPropGetter<T extends Record<'_id', string>>(data: T) {
  return data._id;
}

export function sectionNumberPropGetter<T extends Record<'sectionNumber', string>>(data: T) {
  return data.sectionNumber;
}

export function generateInternalCaseRelativeLink(curatorId: string) {
  return `/case/${curatorId}`;
}

export function isValidLink(link: string) {
  const isUrl = link.match(/https?:\/\/\S+\.\S+$/);
  const isCase = link.match(/^\/case\//);
  return isCase || isUrl;
}

export const arrayToMap = (array: any[], key: string) => array.reduce((obj: Record<string, any>, item: Record<string, any>) => {
  obj[item[key]] = item;
  return obj;
}, { });

export const toSentenceCase = (value: string) => `${value[0].toUpperCase()}${value.substr(1)}`;

export const toSelectOption = (value: string) => ({
  label: toSentenceCase(value),
  value,
});

export const difference = <T>(arr1: T[], arr2: T[]) => arr1.filter(x => !arr2.includes(x));
