import { Typography, Box, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { Modal } from '../../../components/ui/Modal';
import { genericIdPropGetter, findValueFromObject } from '../../../lib/util';
import { RootState } from '../../../store/root';
import { selectFeatureList } from '../../domain-manager/state/selectors';
import { DuplicateQuestionReference } from '../components/DuplicateQuestionReference';
import { QuestionReferenceListDataTable } from '../components/QuestionReferenceListDataTable';
import { getQuestionReferencesForQuestion, deleteQuestionReference } from '../state/actions';
import {
  selectQuestionReferencesList, selectCurrentQuestion, selectCurrentFeature
} from '../state/selectors';
import { QuestionReferenceEntity } from '../state/types';

type QuestionReferenceTableColumn = {
  name: keyof QuestionReferenceEntity,
  title: string,
  getCellValue?: (row: QuestionReferenceEntity) => any
};

const columns: QuestionReferenceTableColumn[] = [
  {
    name: 'title',
    title: 'Title',
    getCellValue: (row: QuestionReferenceEntity) => {
      return row.title.en;
    }
  },
  {
    name: 'link',
    title: 'Link',
    getCellValue: (row: QuestionReferenceEntity) => {
      return row.link.en;
    }
  },
  {
    name: 'excerpt',
    title: 'Excerpt',
    getCellValue: (row: QuestionReferenceEntity) => {
      return (
        <div dangerouslySetInnerHTML={{ __html: row.excerpt.en }} />
      );
    }
  },
  {
    name: 'initialVoteCount',
    title: 'Initial Vote Count',
    getCellValue: (row: QuestionReferenceEntity) => {
      return row.initialVoteCount;
    }
  },
  {
    name: 'totalVoteCount',
    title: 'Total Vote Count',
    getCellValue: (row: QuestionReferenceEntity) => {
      return row.totalVoteCount;
    }
  }
];

const mapStateToProps = (state: RootState) => ({
  questionReferencesList: selectQuestionReferencesList(state),
  question: selectCurrentQuestion(state),
  feature: selectCurrentFeature(state),
  featureList: selectFeatureList(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getReferencesForQuestion: (questionId: string) => dispatch(getQuestionReferencesForQuestion.request(questionId)),
  pushDomainManager: () => dispatch(push('/domains')),
  pushEditReference: (referenceId: string) =>
    dispatch(push(`${window.location.pathname}/edit-reference/${referenceId}`)),
  pushNewReference: () => dispatch(push(`${window.location.pathname}/add-reference`)),
  deleteQuestionReference: (referenceId: string, mongoQuestionId: string) => dispatch(deleteQuestionReference.request({id: referenceId, mongoQuestionId}))
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type RouteParams = {
  questionId: string
};

type QuestionReferenceListContainerProps = StateProps & DispatchProps & RouteComponentProps<RouteParams>;

type ComponentProps = {
  deleteModal: {
    id: string,
    title: string,
    open: boolean
  },
  duplicateModal: {
    id: string,
    open: boolean,
    title: string
  }
};

class QuestionReferenceListContainerComponent extends React.Component<QuestionReferenceListContainerProps, ComponentProps> {
  constructor(props: QuestionReferenceListContainerProps) {
    super(props);
    this.state = {
      deleteModal: {
        id: '',
        title: '',
        open: false
      },
      duplicateModal: {
        id: '',
        open: false,
        title: ''
      }
    };
  }

  componentDidMount(): void {
    const questionId = this.props.match.params.questionId;
    if (questionId) {
      this.props.getReferencesForQuestion(questionId);
    } else {
      this.props.pushDomainManager();
    }
  }

  componentDidUpdate(prevProps: QuestionReferenceListContainerProps) {
    if (prevProps.match.params.questionId !== this.props.match.params.questionId) {
      this.props.getReferencesForQuestion(this.props.match.params.questionId);
    }
  }

  handleEdit = (referenceId: string) => {
    return this.props.pushEditReference(referenceId);
  }

  handleDelete = (referenceId: string) => {
    const questionReference = this.props.questionReferencesList ? findValueFromObject('id', referenceId, this.props.questionReferencesList) : undefined;

    if (questionReference) {
      this.setState({
        ...this.state,
        deleteModal: {
          id: referenceId,
          title: questionReference.title.en,
          open: true
        }
      });
    }
  }

  handleCancel = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        id: '',
        title: '',
        open: false
      }
    });
  }

  handleConfirm = () => {
    this.props.deleteQuestionReference(this.state.deleteModal.id, this.props.match.params.questionId);
    this.setState({
      ...this.state,
      deleteModal: {
        id: '',
        title: '',
        open: false
      }
    });
  }

  handleDuplicate = (referenceId: string) => {
    const questionReference = this.props.questionReferencesList ? findValueFromObject('id', referenceId, this.props.questionReferencesList) : undefined;
    if (questionReference) {
      this.setState({
        ...this.state,
        duplicateModal: {
          open: true,
          id: referenceId,
          title: questionReference.title.en
        }
      });
    }
  }

  handleDuplicateClose = () => {
    this.setState({
      ...this.state,
      duplicateModal: {
        open: false,
        id: '',
        title: ''
      }
    });
  }

  render() {
    return this.props.questionReferencesList && (
      <>
        <Box pb={3}>
          <Typography variant="h6">
            <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={'/domains'}>Domains</BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={`/domains/${this.props.feature && this.props.feature._id}/question-reference-manager`}>
              Question List
            </BreadcrumbLink> / References for <strong>Question {this.props.question ? this.props.question.id : this.props.match.params.questionId}</strong> in <small>{this.props.question ? this.props.question.domain : 'Unknown'}</small>
          </Typography>
        </Box>
        <MainContainerCardComponent>
          <Box p={3}>
            <Button href="" variant="contained" color="primary" onClick={this.props.pushNewReference}>New Reference</Button>
          </Box>
          <Box pt={3}>
            <QuestionReferenceListDataTable
              idPropGetter={genericIdPropGetter}
              columns={columns}
              data={this.props.questionReferencesList}
              actions={[
                {
                  onClick: this.handleEdit,
                  icon: <EditIcon color="primary" />
                },
                {
                  onClick: this.handleDuplicate,
                  color: 'default',
                  icon: <FileCopyIcon />
                },
                {
                  onClick: this.handleDelete,
                  color: 'default',
                  icon: <DeleteIcon />
                }
              ]}
              tableColumnExtensions={
                [
                  {
                    columnName: 'title',
                    wordWrapEnabled: true
                  },
                  {
                    columnName: 'excerpt',
                    wordWrapEnabled: true,
                    width: '30%'
                  },
                  {
                    columnName: 'initialVoteCount',
                    wordWrapEnabled: true,
                    width: '7%'
                  },
                  {
                    columnName: 'totalVoteCount',
                    wordWrapEnabled: true,
                    width: '7%'
                  },
                  {
                    columnName: 'action',
                    wordWrapEnabled: true
                  },
                  {
                    columnName: 'link',
                    wordWrapEnabled: true
                  }
                ]
              }
            />
          </Box>
          <Box>
            <Modal title={`Are you sure you want to delete ${this.state.deleteModal.id} - ${this.state.deleteModal.title}?`} onConfirm={this.handleConfirm} onCancel={this.handleCancel} open={this.state.deleteModal.open} />
          </Box>
          <Box>
            <DuplicateQuestionReference referenceId={this.state.duplicateModal.id} open={this.state.duplicateModal.open} handleClose={this.handleDuplicateClose} title={this.state.duplicateModal.title} />
          </Box>
        </MainContainerCardComponent>
      </>
    );
  }
}

export const QuestionReferenceListContainer = connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(QuestionReferenceListContainerComponent);
