import { map, pathOr, prop } from 'ramda';
import { createStructuredSelector, createSelector } from 'reselect';

import { RoleDisplayNames, SubscriptionDisplayNames } from '../../../lib/constants';
import { difference, toSelectOption } from '../../../lib/util';
import { RootState } from '../../../store/root';
import { Environment, FeatureEntity, Role, Subscription } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { selectFeatures } from '../../base/state/selectors';
import {
  AvailabilityDiff,
  FeatureAvailabilityEntityMap,
  FeatureAvailabilityMap,
  RoleMap,
  EnvironmentOptionList,
  AvailabilityEnabledDisabledDiff,
  AvailabilityRoleDiff
} from '../types';

const selectSelectedEnvironment = pathOr(Environment.BETA, ['featureAvailability', 'selectedEnvironment']);
const selectInitialFeatureAvailabilities = pathOr<FeatureAvailabilityEntityMap>({}, ['featureAvailability', 'availabilities', 'initial']);
const selectUpdatedFeatureAvailabilities = pathOr<FeatureAvailabilityEntityMap>({}, ['featureAvailability', 'availabilities', 'update']);
const selectFeatureAvailabilityEntity = (state: RootState, props: { row: string }) =>
  state.featureAvailability.availabilities.update[props.row] || state.featureAvailability.availabilities.initial[props.row];

export const selectFeatureNames = createSelector(
  selectFeatures,
  map<FeatureEntity, string>(prop('feature'))
);

export const selectFeatureAvailabilityMatrixByFeature = createSelector(
  selectFeatureAvailabilityEntity,
  (featureAvailabilityEntity) => featureAvailabilityEntity && {
    name: featureAvailabilityEntity.feature,
    enabledSubscriptions: featureAvailabilityEntity.enabledSubscriptions,
    availability: Object.keys(SubscriptionDisplayNames).reduce((acc: FeatureAvailabilityMap, subscription) => {
      acc[subscription] = Object.keys(RoleDisplayNames).reduce((roleMap: RoleMap, value) => {
        const sub = featureAvailabilityEntity && featureAvailabilityEntity.availability.find((x) => x.subscription === subscription);
        roleMap[value] = sub && sub.roles ? sub.roles.includes(value as Role) : false;
        return roleMap;
      }, {});
      return acc;
    }, {})
  }
);

const selectEnvironmentsAsListOption = () =>
  [Environment.BETA, Environment.STAGING, Environment.LIVE].map(toSelectOption);

const selectSelectedEnvironmentAsListOption = createSelector(
  selectSelectedEnvironment,
  (selectedEnvironment) => toSelectOption(selectedEnvironment)
);

const selectUpdateDiff = createSelector(
  selectInitialFeatureAvailabilities,
  selectUpdatedFeatureAvailabilities,
  (initial, updated) => {
    const res: AvailabilityRoleDiff = {};
    const enabledDisabledDiff: AvailabilityEnabledDisabledDiff = {};

    Object.values(updated).forEach(({ availability: updatedAvailability, feature, enabledSubscriptions }) => {
      const initialAvailability = feature && initial[feature] ? initial[feature].availability : [];
      const initialEnabledSubscriptions = feature && initial[feature] ? initial[feature].enabledSubscriptions: [];

      enabledDisabledDiff[feature] = {
        enabled: difference<Subscription>((enabledSubscriptions || []), (initialEnabledSubscriptions || [])),
        disabled: difference<Subscription>((initialEnabledSubscriptions || []), (enabledSubscriptions || []))
      };

      initialAvailability.forEach(({ roles, subscription }) => {
        const updatedSubscription = updatedAvailability.find((x) => x.subscription === subscription);
        const updatedRoles = updatedSubscription ? updatedSubscription.roles : [];

        res[feature] = {
          ...res[feature],
          [subscription]: {
            added: difference<Role>(updatedRoles, roles),
            removed: difference<Role>(roles, updatedRoles)
          }
        };
      });

      updatedAvailability.forEach(({ roles: updatedRoles, subscription }) => {
        const initialSubscription = initialAvailability.find((x) => x.subscription === subscription);
        const initialRoles = initialSubscription ? initialSubscription.roles : [];

        res[feature] = {
          ...res[feature],
          [subscription]: {
            added: difference<Role>(updatedRoles, initialRoles),
            removed: difference<Role>(initialRoles, updatedRoles),
          }
        };
      });
    });

    Object.keys(res).forEach((feature) => {
      Object.keys(res[feature]).forEach((subscription) => {
        if (res[feature][subscription].added.length === 0 && res[feature][subscription].removed.length === 0) {
          delete res[feature][subscription];
        }
      });
    });

    return {
      roles: res,
      enabledDisabled: enabledDisabledDiff
    };
  }
);

export default createStructuredSelector<RootState, {
  environments: EnvironmentOptionList;
  selectedEnvironment: SelectOption;
  updateDiff: AvailabilityDiff,
}>({
  environments: selectEnvironmentsAsListOption,
  selectedEnvironment: selectSelectedEnvironmentAsListOption,
  updateDiff: selectUpdateDiff,
});
