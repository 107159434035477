import { createAsyncAction, createAction } from 'typesafe-actions';

import { ApiError } from '../../../lib/errors/ApiError';
import { FeatureEntity } from '../../../types/core';
import { SelectOption } from '../../../types/util';

import { DomainEntity } from './types';

export const getDomainList = createAsyncAction(
  '@@INTERNAL/DOMAIN_MANAGER/GET_DOMAIN_LIST',
  '@@INTERNAL/DOMAIN_MANAGER/GET_DOMAIN_LIST_SUCCESS',
  '@@INTERNAL/DOMAIN_MANAGER/GET_DOMAIN_LIST_FAILURE'
)<undefined, DomainEntity[], ApiError>();

export const setFeaturesList = createAction('@@INTERNAL/DOMAIN_MANAGER/SET_FEATURE_LIST')<FeatureEntity[]>();

export const setSelectedProduct = createAction('@@INTERNAL/DOMAIN_MANAGER/SET_SELECTED_PRODUCT')<SelectOption>();

export const setSelectedDomain = createAction('@@INTERNAL/DOMAIN_MANAGER/SET_SELECTED_DOMAIN')<SelectOption>();
