import { createAsyncAction, createAction } from 'typesafe-actions';

import { ApiError } from '../../../lib/errors/ApiError';
import { CuratorEntitySearchError } from '../../../lib/errors/CuratorEntitySearchError';
import { CaseSearchQuery } from '../../../services/CuratorApi';
import { CaseEntity } from '../../../types/case-types';
import { FeatureEntity } from '../../../types/core';

import { QuestionEntity, QuestionReferenceEntity } from './types';

export const getQuestionsForFeature = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_QUESTIONS_FROM_FEATURE',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_QUESTIONS_FROM_FEATURE_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_QUESTIONS_FROM_FEATURE_FAILURE'
)<string, QuestionEntity[], ApiError>();

export const getQuestion = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_QUESTION',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_QUESTION_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_QUESTION_FAILURE'
)<string, QuestionEntity, ApiError>();

export const getQuestionReferencesForQuestion = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_REFERENCES_FROM_QUESTION',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_REFERENCES_FROM_QUESTION_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/GET_REFERENCES_FROM_QUESTION_FAILURE',
)<string, QuestionReferenceEntity[], ApiError>();

export const submitQuestionReferenceForQuestion = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/SUBMIT_QUESTION_REFERENCE_FOR_QUESTION',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/SUBMIT_QUESTION_REFERENCE_FOR_QUESTION_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/SUBMIT_QUESTION_REFERENCE_FOR_QUESTION_FAILURE'
)<QuestionReferenceEntity, any, ApiError>();

export const deleteQuestionReference = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/DELETE_QUESTION_REFERENCE',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/DELETE_QUESTION_REFERENCE_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/DELETE_QUESTION_REFERENCE_FAILURE'
)<Pick<QuestionReferenceEntity, 'id' | 'mongoQuestionId'>, number, ApiError>();

export const editQuestionReference = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/EDIT_QUESTION_REFERENCE',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/EDIT_QUESTION_REFERENCE_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/EDIT_QUESTION_REFERENCE_FAILURE'
)<QuestionReferenceEntity, any, ApiError>();

export const countQuestionReferences = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/COUNT_QUESTION_REFERENCES',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/COUNT_QUESTION_REFERENCES_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/COUNT_QUESTION_REFERENCES_FAILURE'
)<string[], Record<string, number>, ApiError>();

export const setCurrentFeature = createAction('@@INTERNAL/QUESTION_REFERENCE_MANAGER/SET_CURRENT_FEATURE')<FeatureEntity>();

export const clearQuestionList = createAction('@@INTERNAL/QUESTION_REFERENCE_MANAGER/CLEAR_QUESTION_LIST')<void>();

export const clearQuestionReferencesList = createAction('@@INTERNAL/QUESTION_REFERENCE_MANAGER/CLEAR_QUESTION_REFERENCES_LIST')<void>();

export const searchCuratorCases = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/SEARCH_CURATOR',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/SEARCH_CURATOR_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/SEARCH_CURATOR_FAILURE',
)<CaseSearchQuery, CaseEntity[], CuratorEntitySearchError>();

export const duplicateQuestionReference = createAsyncAction(
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/DUPLICATE_QUESTION_REFERENCE',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/DUPLICATE_QUESTION_REFERENCE_SUCCESS',
  '@@INTERNAL/QUESTION_REFERENCE_MANAGER/DUPLICATE_QUESTION_REFERENCE_FAILURE'
)<{ questionId: string, feature: string, referenceId: string }, void, ApiError>();
