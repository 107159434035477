import * as React from 'react';

import { BaseFormValues, MLServiceType } from '../../types';
import { AzureMLServiceForm } from '../service-forms/AzureMLServiceForm';
import { NoMLServiceForm } from '../service-forms/NoMLServiceForm';
import { ProphetMLServiceForm } from '../service-forms/ProphetMLServiceForm';
import { UpdateMLServiceManagerProps } from '../types';

type UpdateFormProps = {
  type: MLServiceType
} & UpdateMLServiceManagerProps;

export const MLServiceUpdateForm: React.FunctionComponent<UpdateFormProps> = (
  {
    type,
    feature,
    name,
    availableQuestions,
    onDelete,
    initialData,
    _id,
    onUpdate,
    children
  }
) => {

  function onSubmit<TFormValues extends BaseFormValues>(values: TFormValues) {
    onUpdate({
      ...values,
      type,
      name,
      feature
    });
  }

  const baseProps = {
    children,
    createMode: false,
    feature,
    availableQuestions,
    name,
    initialData,
    onSubmit,
    onDelete: () => {
      onDelete({
        _id,
        feature,
        name,
        type
      });
    }
  };

  switch (type) {
    case MLServiceType.Azure: {
      return (
        <AzureMLServiceForm {...baseProps} />
      );
    }
    case MLServiceType.NoService:
      return (
        <NoMLServiceForm {...baseProps} />
      );
    case MLServiceType.Prophet: {
      return (
        <ProphetMLServiceForm {...baseProps} />
      );
    }
    default:
      return <div>{`Not Implemented - ${type}`}</div>;
  }
};
