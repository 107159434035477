import {
  Box,
  Button,
  Grid,
  Checkbox
} from '@material-ui/core';
import * as React from 'react';

import { MarkdownWrapper } from '../../../components/form/MarkdownWrapper';
import { TextField } from '../../../components/form/TextField';
import { Subscription } from '../../../types/core';
import { NoticeEntity } from '../state/types';

type NoticeFormProps = {
  handleChange: (field: keyof NoticeEntity, value: string | number | boolean) => void;
  handleSubmit: (event: React.SyntheticEvent) => void;
  handleDelete?: () => void;
  noticeModule: NoticeEntity;
  subscription: Subscription;
};

export const NoticeFormComponent: React.FC<NoticeFormProps> = ({
  handleChange,
  handleSubmit,
  handleDelete,
  noticeModule,
  subscription,
}) => {

  React.useEffect(() => {
    if (subscription !== noticeModule.subscription) {
      handleChange('subscription', subscription);
    }
  }, [noticeModule.subscription, handleChange, subscription]);

  const disabled = (
    !noticeModule.subscription ||
    (!noticeModule.body && !noticeModule.body_fr) ||
    typeof noticeModule.enabled !== 'boolean'
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={2}>
        <Grid xs={12}>
          <Grid item={true} xs={6}>
            <Box>
              Enabled
              <Checkbox
                checked={noticeModule.enabled}
                onChange={() => handleChange('enabled', !noticeModule.enabled)}
              />
            </Box>
            <Box>
              <TextField
                value={noticeModule.title}
                label="Title (English)"
                onChange={handleChange.bind(null, 'title')}
                required={false}
              />
              <TextField
                value={noticeModule.title_fr}
                label="Title (French)"
                onChange={handleChange.bind(null, 'title_fr')}
                required={false}
              />
            </Box>
          </Grid>
        </Grid>
        <MarkdownWrapper
          markdown={noticeModule.body}
          markdownFR={noticeModule.body_fr}
          handleChange={handleChange.bind(null, 'body')}
          handleChangeFR={handleChange.bind(null, 'body_fr')}
        />
      </Grid>
      <Box pt={10}>
        <Grid container={true} spacing={3}>
          {handleDelete && (
            <Grid item={true}>
              <Button onClick={handleDelete} variant="outlined" color="primary">
                Delete
              </Button>
            </Grid>
          )}
          <Grid item={true}>
            <Button
              disabled={disabled}
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
