import { createSelector } from 'reselect';

import { QuestionType, SimilarCaseQuestionConfig } from '../../../types/core';
import { selectFeatureList } from '../../domain-manager/state/selectors';
import { selectQuestionList } from '../../question-reference-manager/state/selectors';

export const createSelectFeatureFromId = (featureId: string) => createSelector(
  selectFeatureList,
  (features) => features.find((feature) => feature._id === featureId)
);

const DEFAULT_POINT = 1;

export const createSelectSimilarDecisionsWeightsFromFeatureId = (featureId: string) => createSelector(
  createSelectFeatureFromId(featureId),
  selectQuestionList,
  (feature, questions) => {
    if (!feature || !questions) {
      return undefined;
    }

    const configs: SimilarCaseQuestionConfig[] = [];
    const standardQuestionTypes = [QuestionType.MultipleChoice, QuestionType.Dropdown, QuestionType.MultipleChoicesCustomValue, QuestionType.DropdownCustomValue];
    questions.forEach((question) => {
      if ((standardQuestionTypes).includes(question.questionType)) {
        const existingConfig = feature.similarCaseQuestionConfigs?.find((config) => config.id === question.id);

        configs.push({
          id: question.id,
          point: existingConfig ? existingConfig.point : DEFAULT_POINT,
          questionType: question.questionType
        });
      } else if (question.questionType === QuestionType.Checkbox) {
        return question.choices?.map((_, i) => {
          const id = `${question.id}.${i + 1}`;
          const existingConfig = feature.similarCaseQuestionConfigs?.find((config) => config.id === id);

          configs.push({
            id,
            point: existingConfig ? existingConfig.point : DEFAULT_POINT,
            questionType: QuestionType.Checkbox
          });
        });
      } else if (question.questionType === QuestionType.TextInput && question.textInputProps?.type === 'number') {
        const existingConfig = feature.similarCaseQuestionConfigs?.find((config) => config.id === question.id);

        configs.push({
          id: question.id,
          point: existingConfig ? existingConfig.point : DEFAULT_POINT,
          questionType: QuestionType.TextInput,
          near: existingConfig?.point || DEFAULT_POINT,
          far: existingConfig?.point || DEFAULT_POINT
        });
      }
    });

    return configs;
  }
);
