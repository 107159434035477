import * as R from 'ramda';
import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';
import { TagManagerState } from '../types';

import {
  closeDeleteTagModal,
  deleteTag,
  getTags,
  openDeleteTagModal,
  getTagsForItem,
  updateItemTags
} from './actions';

const INITIAL_STATE: TagManagerState = {
  deleteModalId: '',
  tags: [],
  items: {}
};

export const tagManagerReducer = createReducer<TagManagerState, RootAction>(INITIAL_STATE)
  .handleAction(getTags.success, (state, { payload }) => R.assoc('tags', payload, state))
  .handleAction(openDeleteTagModal, (state, { payload: id }) => R.assoc('deleteModalId', id, state))
  .handleAction([deleteTag.success, closeDeleteTagModal, deleteTag.failure], R.assoc('deleteModalId', ''))
  .handleAction([getTagsForItem.success, updateItemTags.success], (state, { payload }) => R.assocPath(['items', payload.id], payload.tags, state))
