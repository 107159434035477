import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';
import { Tag } from '../types';

const selectTagManagerState = (state: RootState) => state.tagManager;

export const selectTags = createSelector(
  selectTagManagerState,
  (tagManager) => tagManager.tags
);

export const selectTagsById = createSelector(
  selectTags,
  (tags) => tags.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {})
);

export const createSelectTagById = (id: string) => createSelector(
  selectTagsById,
  (tags) => tags[id]
);

export const selectDeleteModalId = createSelector(
  selectTagManagerState,
  (tagManager) => tagManager.deleteModalId
);

export const createSelectTagTypes = (id: string) =>  createSelector(
  createSelectTagById(id),
  (tag: Tag) => {
    if (!tag?.metadata?.typesTagged) {
      return [];
    }

    return Object.entries(tag.metadata.typesTagged).reduce((acc: string[], curr: [string, number]) => {
      acc.push(`${curr[1]} ${curr[0]}${curr[1] > 1 ? 's': ''}`);
      return acc;
    }, []);
  }
);

export const selectTagsByItem = (id: string) => createSelector(
  selectTagManagerState,
  (state) => state.items[id]
);
