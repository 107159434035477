import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { RootState } from '../../../store/root';
import { QuestionReferenceManagerModule } from '../../question-reference-manager';
import RelatedStatuteSectionsManager from '../../related-statute-sections-manager/components/RelatedStatuteSectionsManager';
import { SimilarDecisionsWeightsContainer } from '../../similar-decisions-weights/components/similar-decisions-weights-container';
import { getDomainList } from '../state/actions';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getDomainList: () => dispatch(getDomainList.request())
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type RouteParams = {
  featureName?: string,
  domainName?: string
};

type FeatureSelectorContainerProps = DispatchProps & RouteComponentProps<RouteParams>;

class FeatureSelectorContainerComponent extends React.Component<FeatureSelectorContainerProps> {
  componentDidMount(): void {
    this.props.getDomainList();
  }

  render() {
    return (
      <>
        <Switch>
          <Route path="/domains/:featureName/question-reference-manager" component={QuestionReferenceManagerModule} />
          <Route path="/domains/:domainName/related-statute-sections" component={RelatedStatuteSectionsManager} />
          <Route path="/domains/:featureId/similar-decisions-weights" component={SimilarDecisionsWeightsContainer} />
        </Switch>
      </>
    );
  }
}

export const FeatureSelectorContainer = connect<{}, DispatchProps, {}, RootState>(undefined, mapDispatchToProps)(FeatureSelectorContainerComponent);
