import { assoc, assocPath, clone, dissocPath, equals, pathOr, pipe, uniq, without } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { arrayToMap } from '../../../lib/util';
import { RootAction } from '../../../store/actions';
import { Environment, Role, Subscription } from '../../../types/core';
import { fetchFeatures } from '../../base/state/actions';
import { FeatureAvailabilityEntity, FeatureAvailabilityState } from '../types';

import * as featureAvailabilityActions from './actions';

const INITIAL_STATE: FeatureAvailabilityState = {
  availabilities: {
    initial: {},
    update: {},
  },
  selectedEnvironment: Environment.BETA,
};

const updateFeature = (subscription: Subscription, entity: FeatureAvailabilityEntity, feature: string, explicitEnabled: boolean, role?: Role) => {

  const enabled = Boolean(role || explicitEnabled);

  if (!entity.availability) {
    /**
     * This entity has no availability documents associated with it
     * So we're creating the bare minimum that the API expects
     */
    entity.feature = feature;
    entity.availability = role ? [{
      roles: [role],
      subscription
    }]: [];
    entity.enabledSubscriptions = enabled ? [subscription] : [];

    return entity;
  }

  if (enabled) {
    entity.enabledSubscriptions = uniq((entity.enabledSubscriptions || []).concat(subscription));
  } else {
    entity.enabledSubscriptions = without([subscription], (entity.enabledSubscriptions || []));
  }

  const availability = entity.availability.find((x) => x.subscription === subscription);

  if (!availability) {
    entity.availability.push({
      roles: role ? [role] : [],
      subscription,
    });
    return entity;
  }

  if (!enabled) {
    availability.roles = [];
  } else {
    const { roles } = availability;
    if (role) {
      if (roles.includes(role)) {
        roles.splice(roles.indexOf(role), 1);
      } else {
        roles.push(role);
      }
    }
  }

  const filteredAvailability = entity.availability.filter((f) => (entity.enabledSubscriptions || []).includes(f.subscription));
  return assoc('availability', filteredAvailability, entity);
};

function populateEnabledSubscriptions(availabilities: Record<string, FeatureAvailabilityEntity>): Record<string, FeatureAvailabilityEntity> {
  const clonedAvailabilities = clone(availabilities);
  Object.keys(clonedAvailabilities).forEach((feature) => {
    const featureAvailabilities = clonedAvailabilities[feature];
    featureAvailabilities.enabledSubscriptions = featureAvailabilities.availability.reduce<Subscription[]>((subs, avail) => uniq(subs.concat(avail.subscription)), []);
  });
  return clonedAvailabilities;
}

export const featureAvailabilityReducer =
  createReducer<FeatureAvailabilityState, RootAction>(INITIAL_STATE)
    .handleAction(featureAvailabilityActions.changeEnvironment, (state, { payload }) => assoc('selectedEnvironment', payload, state))
    .handleAction(featureAvailabilityActions.resetChanges, assocPath(['availabilities', 'update'], {}))
    .handleAction([fetchFeatures.request, featureAvailabilityActions.fetchFeatureAvailabilities.request],
      pipe<FeatureAvailabilityState, FeatureAvailabilityState, FeatureAvailabilityState, FeatureAvailabilityState>(
        assoc('features', []),
        assocPath(['availabilities', 'initial'], {}),
        assocPath(['availabilities', 'update'], {}),
      )
    )
    .handleAction(featureAvailabilityActions.fetchFeatureAvailabilities.success, (state, { payload }) =>
      pipe<FeatureAvailabilityState, FeatureAvailabilityState, FeatureAvailabilityState>(
        assocPath(['availabilities', 'initial'], populateEnabledSubscriptions(arrayToMap(payload, 'feature'))),
        assocPath(['availabilities', 'update'], {})
      )(state)
    )
    .handleAction(featureAvailabilityActions.changeFeatureAvailabilityStatus, (state, { payload }) => {
      const { feature, subscription, role, enabled } = payload;
      const initialFeature = pipe<FeatureAvailabilityState, FeatureAvailabilityEntity | {}, FeatureAvailabilityEntity>(
        pathOr({}, ['availabilities', 'initial', feature]),
        clone
      )(state);

      const derivedFeature = clone(pathOr(initialFeature, ['availabilities', 'update', feature], state));
      const updatedFeature = updateFeature(subscription, derivedFeature, feature, enabled, role);

      return equals(state.availabilities.initial[feature], updatedFeature) ?
        dissocPath(['availabilities', 'update', feature], state) :
        assocPath(['availabilities', 'update', feature], updatedFeature, state);
    });
