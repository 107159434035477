import {
  Box, Button, Card, CardActions, CardContent, CardHeader,
  FormControl,
  Input,
  InputLabel,
  Typography
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

import { SelectOption } from '../../../../types/util';
import { MLServiceConfig, MLServiceType } from '../../types';

type Props = {
  type?: MLServiceType,
  name: MLServiceConfig['name'],
  onTypeChange: (type: MLServiceType) => void,
  onNameChange: (name: MLServiceConfig['name']) => void,
  onSubmit: () => void,
  onCancel: () => void
};

type ServiceOption = SelectOption<MLServiceType>;
const SERVICE_TYPES = Object.entries(MLServiceType).map(([label, value]) => ({ label, value }));

export const NewMLServicePreForm: React.FunctionComponent<Props> = (
  {
    type,
    name,
    onTypeChange,
    onNameChange,
    onSubmit,
    onCancel
  }
) => {

  const handleTypeChange = (option: ValueType<ServiceOption>) => {
    onTypeChange((option as ServiceOption).value);
  };

  const handleNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onNameChange(target.value);
  };

  return (
    <Card style={{ overflow: 'unset' }}>
      <CardHeader title="New ML Service" />
      <CardContent>
        <Box minWidth={300} width="50%">
          <Typography color="textSecondary" variant="caption">ML Service Type</Typography>
          <Select<ServiceOption> value={mlServiceTypeToSelectOption(type)} options={SERVICE_TYPES}
                                 onChange={handleTypeChange} />
        </Box>
        <Box minWidth={300} width="50%">
          <FormControl>
            <InputLabel>Service Name</InputLabel>
            <Input value={name} onChange={handleNameChange} />
          </FormControl>
        </Box>
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={onSubmit}>Submit</Button>
        <Button color="secondary" onClick={onCancel}>Cancel</Button>
      </CardActions>
    </Card>
  );
};

function mlServiceTypeToSelectOption(type?: MLServiceType): ServiceOption | undefined {
  if (!type) {
    return undefined;
  }
  return SERVICE_TYPES.find(({ value }) => value === type) as ServiceOption;
}
