import { Button, Grid, Box } from '@material-ui/core';
import * as React from 'react';

type FormButtonsProps = {
  handleSubmit: () => void,
  handleClear: () => void
};

export const FormButtons = ({
  handleSubmit,
  handleClear
}: FormButtonsProps) => {
  return (
    <>
      <Grid container={true} justify="flex-end">
        <Box p={1}>
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} href="">Apply</Button>
        </Box>
        <Box p={1}>
          <Button type="submit" variant="contained" color="primary" onClick={handleClear} href="">Clear</Button>
        </Box>
      </Grid>
    </>
  );
};
