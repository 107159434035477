export type MLServiceState = {
  createOpened: boolean,
  dataVersion: number,
  services: MLServiceConfig[],
  promoteTargetServices: MLServiceConfig[],
  availableQuestions: QuestionListItem[],
  deleteInProgress?: DeleteMLServiceDetails,
  promoteAllInProgress?: boolean,
  promoteFeatureInProgress?: PromoteMLServiceFeature,
  errorStack: Error[],
  messages: string[]
};

export enum MLServiceType {
  Azure = 'Azure Machine Learning',
  NoService = 'no_service',
  Prophet = 'prophet'
}

export type MLServiceConfig = {
  _id: string,
  createdAt: string,
  updatedAt: string

  feature: string,
  disabled: boolean,
  name: string,
  type: MLServiceType,
  isDefault: boolean,

  // Azure Fields
  baseUrl?: string,
  authorizationToken?: string,
  questionIds?: string[]

  // Prophet Fields
  predictionDomain?: string
  interimPredict?: boolean
};

export type QuestionListItem = {
  _id: string,
  id: string,
  displayId: string
};

export type MLServiceResponse = {
  services: MLServiceConfig[],
  availableQuestions: QuestionListItem[]
};

export type MLServicePromoteTargetResponse = {
  services: MLServiceConfig[]
};

export type CreateMLServiceConfig = Omit<MLServiceConfig, 'createdAt' | 'updatedAt' | '_id'>;
export type UpdateMLServiceConfig = Omit<MLServiceConfig, 'createdAt' | 'updatedAt' | '_id'>;
export type DeleteMLServiceConfig = Pick<MLServiceConfig, '_id'>;
export type DeleteMLServiceDetails = Pick<MLServiceConfig, '_id' | 'feature' | 'name' | 'type'>;
export type PromoteMLServiceFeature = Pick<MLServiceConfig, 'feature'>;

export type BaseFormValues = Pick<MLServiceConfig, 'disabled' | 'isDefault'>;

export type AzureSpecificFormValues = Pick<MLServiceConfig, 'authorizationToken' | 'baseUrl' | 'questionIds'>;
export type AzureFormValues = BaseFormValues & AzureSpecificFormValues;

export type ProphetSpecificFormValues = Pick<MLServiceConfig, 'predictionDomain' | 'interimPredict'>;
export type ProphetFormValues = BaseFormValues & ProphetSpecificFormValues;

export type NoMLServiceFormValues = BaseFormValues;
