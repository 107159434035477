import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';

import { fetchActivationModulesForSubscription, selectActivationManagerSubscription } from './actions';
import { ActivationManagerState } from './types';

const activationManagerInitialState = {
  subscription: null,
  activationModules: []
};

export const activationManagerReducer = createReducer<ActivationManagerState, RootAction>(activationManagerInitialState)
  .handleAction(selectActivationManagerSubscription, (state, { payload }) => {
    return {
      ...state,
      subscription: payload
    };
  })
  .handleAction(fetchActivationModulesForSubscription.success, (state, { payload }) => {
    return {
      ...state,
      activationModules: payload
    };
  });
