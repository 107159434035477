import { Grid, Paper, Box, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { EntitySelector } from '../../../components/form/EntitySelector';
import { SubscriptionDisplayNames } from '../../../lib/constants';
import { findValueFromObject } from '../../../lib/util';
import { RootState } from '../../../store/root';
import { Subscription } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { DomainComponent } from '../components/DomainComponent';
import { getDomainList, setSelectedProduct, setSelectedDomain } from '../state/actions';
import {
  selectDomainList,
  selectDomainListAsSelectedOption,
  selectSelectedProduct,
  selectSelectedDomain,
  selectFilteredDomainList,
  selectDomainListSubscriptions
} from '../state/selectors';

const subscriptionList = Object.keys(SubscriptionDisplayNames).map((value) => ({
  label: SubscriptionDisplayNames[value as Subscription],
  value,
}));

const mapStateToProps = (state: RootState) => ({
  domainList: selectDomainList(state),
  domainListSubscriptions: selectDomainListSubscriptions(state),
  domainListAsSelectedOption: selectDomainListAsSelectedOption(state),
  filteredDomainList: selectFilteredDomainList(state),
  selectedProduct: selectSelectedProduct(state),
  selectedDomain: selectSelectedDomain(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  pushQuestionReferenceManager: (domainName: string) => dispatch(push(`/domains/${domainName}/question-reference-manager`)),
  getDomainList: () => dispatch(getDomainList.request()),
  setSelectedProduct: (selected: SelectOption) => dispatch(setSelectedProduct(selected)),
  setSelectedDomain: (selected: SelectOption) => dispatch(setSelectedDomain(selected)),
  pushRelatedStatuteSections: (domain: string) => dispatch(push(`/domains/${domain}/related-statute-sections`)),
  pushSimilarDecisionsWeight: (featureId: string) => dispatch(push(`/domains/${featureId}/similar-decisions-weights`)),
  pushDomainMetadata: (domain: string) => dispatch(push(`/domains/${domain}/metadata`))
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type DomainsListProps = StateProps & DispatchProps;

class DomainsListContainerComponent extends React.Component<DomainsListProps> {
  componentDidMount(): void {
    this.props.getDomainList();
  }

  handleReferenceClick = (feature: string) => {
    this.props.pushQuestionReferenceManager(feature);
  }

  handleSearchChange = (selectedDomain: SelectOption) => {
    this.props.setSelectedDomain(selectedDomain);
  }

  handleProductChange = (selectedProduct: SelectOption) => {
    this.props.setSelectedProduct(selectedProduct);
  }

  handleRelatedStatuteSectionsChange = (domain: string) => {
    this.props.pushRelatedStatuteSections(domain);
  }

  handleSimilarDecisionsWeightsClick = (featureId: string) => {
    this.props.pushSimilarDecisionsWeight(featureId);
  }

  handleMetadataClick = (domain: string) => {
    this.props.pushDomainMetadata(domain);
  }

  render() {
    const domainComponents = this.props.filteredDomainList.map((domain) => {
      const domainSubscriptions = findValueFromObject('domain', domain._id, this.props.domainListSubscriptions);
      const subscription = domainSubscriptions?.subscriptions[0];
      return subscription && (
        <Grid key={domain._id} item={true} xs={4}>
          <DomainComponent
            domain={domain}
            subscription={subscription}
            handleReferenceClick={(featureId: string) => this.handleReferenceClick(featureId)}
            handleRelatedStatuteSectionsClick={this.handleRelatedStatuteSectionsChange}
            handleSimilarDecisionsWeightsClick={this.handleSimilarDecisionsWeightsClick}
            handleMetadataClick={this.handleMetadataClick}
          />
        </Grid>
      );
    });

    return (
      <>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Paper elevation={3}>
              <Box p={3}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <Typography variant="h5">Filters</Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <EntitySelector
                      handleChange={this.handleSearchChange}
                      value={this.props.selectedDomain}
                      objectList={this.props.domainListAsSelectedOption}
                      title="Filter by Domain"
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <EntitySelector
                      handleChange={this.handleProductChange}
                      value={this.props.selectedProduct}
                      objectList={subscriptionList}
                      title="Filter by Product"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          {domainComponents}
        </Grid>
      </>
    );
  }
}

export const DomainsListContainer = connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(DomainsListContainerComponent);
