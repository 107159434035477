import * as R from 'ramda';
import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';
import { CaseEntity } from '../../../types/case-types';
import { FeatureEntity } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { selectTranslatedStrings } from '../../translation/state/selectors';

import {
  QuestionEntity,
  QuestionReferenceEntity,
  QuestionReferenceManagerState, LANGUAGE_TYPE
} from './types';

export const selectQuestionReferenceManagerState = (state: RootState): QuestionReferenceManagerState => state.questionReferenceManager;

export const selectQuestionList = createSelector<RootState, QuestionReferenceManagerState, QuestionEntity[] | undefined>(
  [selectQuestionReferenceManagerState],
  (questionReferenceManagerState) => questionReferenceManagerState.questionList
);

export const createSelectQuestionFromId = (id: string) => createSelector(
  selectQuestionList,
  (questionList): QuestionEntity | undefined => questionList ? questionList.find((question) => question._id === id) : undefined
);

export const selectQuestionIdsAndPromptsAsDropdownList = createSelector(
  selectQuestionList,
  selectTranslatedStrings,
  (questions, strings) => questions ? questions.map((q) => {
    const prompt = q.prompt ? R.pathOr(q.prompt, [q.prompt, LANGUAGE_TYPE.EN], strings) : '';
    return { value: q._id, label: `${q.id} - ${prompt}` };
  }) : []
);

export const selectQuestionReferencesList = createSelector<RootState, QuestionReferenceManagerState, QuestionReferenceEntity[] | undefined>(
  [selectQuestionReferenceManagerState],
  (questionReferenceManagerState) => questionReferenceManagerState.questionReferencesList
);

export const createSelectQuestionReferenceFromId = (id: string) => createSelector(
  selectQuestionReferencesList,
  (questionReferences): QuestionReferenceEntity | undefined => questionReferences ? questionReferences.find((ref) => ref.id === id) : undefined
);

export const selectCurrentQuestion = createSelector<RootState, QuestionReferenceManagerState, QuestionEntity | undefined>(
  [selectQuestionReferenceManagerState],
  (questionReferenceManagerState) => questionReferenceManagerState.currentQuestion
);

export const selectCurrentFeature = createSelector<RootState, QuestionReferenceManagerState, FeatureEntity | undefined>(
  [selectQuestionReferenceManagerState],
  (questionReferenceManagerState) => questionReferenceManagerState.currentFeature
);

export const selectCurrentFeatureAsSelectOption = createSelector(
  selectCurrentFeature,
  (feature): SelectOption | null => {
    return !!feature ? { value: feature._id, label: feature.feature } : null;
  }
);

export const selectCuratorSearchResults = createSelector<RootState, QuestionReferenceManagerState, CaseEntity[]>(
  [selectQuestionReferenceManagerState],
  (questionReferenceManagerState) => questionReferenceManagerState.curatorSearchResults
);
