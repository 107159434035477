import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';
import { Subscription } from '../../../types/core';

import {
  fetchNoticeModulesForSubscription,
  selectNoticeManagerSubscription,
  createNoticeModuleData,
  deleteNoticeModuleData,
  deleteNoticeModule,
  updateNoticeModuleData,
  saveNoticeModule,
  initializeNoticeManagerPage,
} from './actions';
import { selectCurrentNoticeManagerSubscription } from './selectors';
import { SAVE_MODE } from './types';

const fetchNoticeModulesForSubscriptionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(fetchNoticeModulesForSubscription.request)),
    switchMap(({ payload: subscription }) => {
      const netPayload = { subscription };
      return from(internalApi.fetchNoticeModules(netPayload))
        .pipe(
          switchMap((response) => {
            return [
              fetchNoticeModulesForSubscription.success(response)
            ];
          }),
          catchError(() => {
            toast.error('Cannot fetch notice modules');
            return of(fetchNoticeModulesForSubscription.failure());
          })
        );
    })
  );

const selectCurrentSubscriptionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(selectNoticeManagerSubscription)),
    switchMap(({ payload }) => {
      return [
        fetchNoticeModulesForSubscription.request(payload)
      ];
    })
  );

const createNoticeModuleEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(createNoticeModuleData.request)),
    switchMap(({ payload }) => {
      return from(internalApi.createNoticeModule(payload))
        .pipe(
          switchMap(() => {
            return [
              push('/notice-manager')
            ];
          }),
          catchError(() => {
            toast.error('Cannot create module');
            return of(createNoticeModuleData.failure());
          })
        );
    })
  );

const deleteNoticeModuleDataEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(deleteNoticeModuleData.request)),
    withLatestFrom(store$),
    switchMap(([{ payload }, state]) => {
      return from(internalApi.deleteNoticeModule({ _id: payload }))
        .pipe(
          switchMap(() => {
            const subscription = selectCurrentNoticeManagerSubscription(state);
            return [
              fetchNoticeModulesForSubscription.request(subscription as Subscription)
            ];
          }),
          catchError(() => {
            toast.error('Error deleting notice module');
            return of(deleteNoticeModuleData.failure());
          })
        );
    })
  );

const deleteNoticeModuleEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(deleteNoticeModule)),
    switchMap(({ payload }) => {
      return [
        deleteNoticeModuleData.request(payload)
      ];
    })
  );

const updateNoticeModuleDataEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(updateNoticeModuleData.request)),
    switchMap(({ payload }) => {
      return of(internalApi.updateNoticeModule(payload))
        .pipe(
          switchMap(() => {
            return [
              push('/notice-manager')
            ];
          }),
          catchError(() => {
            toast.error('Error updating notice module');
            return of(updateNoticeModuleData.failure());
          })
        );
    })
  );

const saveNoticeModuleEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(saveNoticeModule)),
    switchMap(({ payload, meta }) => {
      return meta.mode === SAVE_MODE.CREATE ?
        [createNoticeModuleData.request(payload)] :
        [updateNoticeModuleData.request(payload)];
    })
  );

const initializeNoticeManagerPageEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(initializeNoticeManagerPage)),
    withLatestFrom(store$),
    switchMap(([_, state]) => {
      const subscription = selectCurrentNoticeManagerSubscription(state);
      return subscription ? [fetchNoticeModulesForSubscription.request(subscription)] : [];
    })
  );

export const noticeManagerEpics = [
  fetchNoticeModulesForSubscriptionEpic,
  selectCurrentSubscriptionEpic,
  saveNoticeModuleEpic,
  createNoticeModuleEpic,
  deleteNoticeModuleEpic,
  deleteNoticeModuleDataEpic,
  initializeNoticeManagerPageEpic,
  updateNoticeModuleDataEpic
];
