import { SurveyDropdown } from '@bluejlegal/design-system';
import { Box, Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { Subscription } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { getTags, updateItemTags, getTagsForItem } from '../../tag-manager/state/actions';
import { selectTags, selectTagsByItem } from '../../tag-manager/state/selectors';
import { Tag } from '../../tag-manager/types';
import { TranslatedStringContainer } from '../../translation/containers/TranslatedStringContainer';
import { getDomainList } from '../state/actions';
import { createSelectDomainById } from '../state/selectors';

type RouteParams = {
  domainId: string;
};

type Props = RouteComponentProps<RouteParams>;

const toSelectOption = (tag: Tag) => ({
  label: tag.displayName_en,
  value: tag.id
});

const haveTagsChanged = (activeTags?: SelectOption[], itemTags?: Tag[]) => {
  const activeTagIds = activeTags?.map(({ value }) => value) || [];
  const itemTagIds = itemTags?.map(({ id }) => id) || [];
  const difference = activeTagIds
    .filter((id) => !itemTagIds.includes(id))
    .concat(itemTagIds.filter((id) => !activeTagIds.includes(id)));

  return difference.length > 0;
};

export const DomainMetadata: React.FC<Props> = ({match}) => {
  const {domainId} = match.params;
  const dispatch = useDispatch();
  const [availableTags, setAvailableTags] = useState<Tag[]>();
  const [activeTags, setActiveTags] = useState<SelectOption[]>();

  const domain = useSelector(createSelectDomainById(domainId));
  const tags = useSelector(selectTags);
  const itemTags = useSelector(selectTagsByItem(domainId));

  const onTagsChange = useCallback((options: SelectOption[]) => {
    setActiveTags(options);
  }, []);

  const onSubmit = useCallback(() => {
    dispatch(updateItemTags.request({
      id: domainId,
      tags: activeTags?.map((option) => option.value) || [],
      type: 'domain'
    }));
  }, [activeTags, dispatch, domainId]);

  const onCancel = useCallback(() => {
    setActiveTags(itemTags?.map(toSelectOption) || []);
  }, [itemTags]);

  useEffect(() => {
    dispatch(getTagsForItem.request(domainId));
  }, [domainId, dispatch]);

  useEffect(() => {
    const subscriptions = domain && domain.features.flatMap((feature) => feature.subscriptions);
    const filteredTags = subscriptions && tags ? tags.filter((tag) => tag.subscriptions.some((subscription) => subscriptions.includes(subscription as Subscription))) : [];
    setAvailableTags(filteredTags);
  }, [domain, tags]);

  useEffect(() => {
    if (itemTags) {
      setActiveTags(itemTags.map(toSelectOption));
    }
  }, [itemTags]);

  useEffect(() => {
    dispatch(getDomainList.request());
    dispatch(getTags.request());
  }, [dispatch]);

  return domain ? (
    <Box>
      <Box pb={2}>
        <Typography variant="h4">
          <TranslatedStringContainer id={domain.displayName} language="en" />
        </Typography>
      </Box>
      <Box pb={3}>
        <Typography variant="h6">
          <BreadcrumbLink to="/">Home</BreadcrumbLink> /&nbsp;
          <BreadcrumbLink to="/domains">Domain Manager</BreadcrumbLink>
          &nbsp;/ {domain._id} / Metadata
        </Typography>
      </Box>
      <Box pb={3}>
        <Box pb={2}>
          <Typography variant="h6">Tags</Typography>
        </Box>
        <SurveyDropdown
          options={availableTags?.map((tag) => ({
            label: tag.displayName_en,
            value: tag.id
          })) || []}
          onChange={onTagsChange}
          noOptionsMessage="There are no available tags for this domain"
          placeholder="Select tags..."
          isMulti={true}
          value={activeTags}
        />
      </Box>
      { activeTags && activeTags.length > 10 ? (
        <Alert severity="error">You may only assign a maximum of 10 tags to a Domain</Alert>
      ) : null}
      <Box p={2} display="flex">
        <Box pr={2}>
          <Button
            disabled={!haveTagsChanged(activeTags || [], itemTags || []) || (activeTags && activeTags.length > 10)}
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Box>
        <Box>
          <Button
            disabled={!haveTagsChanged(activeTags || [], itemTags || [])}
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  ) : null;
};
