import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';
import { Subscription } from '../../../types/core';

import {
  fetchActivationModulesForSubscription,
  selectActivationManagerSubscription,
  saveActivationModule,
  createActivationModuleData,
  updateActivationModuleData,
  deleteActivationModule,
  deleteActivationModuleData,
  initializeActivationManagerPage,
} from './actions';
import { selectCurrentActivationManagerSubscription } from './selectors';
import { SAVE_MODE } from './types';

const fetchActivationModulesForSubscriptionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(fetchActivationModulesForSubscription.request)),
    switchMap(({ payload: subscription }) => {
      const netPayload = { subscription };
      return from(internalApi.fetchActivationModules(netPayload))
        .pipe(
          switchMap((response) => {
            return [
              fetchActivationModulesForSubscription.success(response)
            ];
          }),
          catchError(() => {
            toast.error('Cannot fetch activation modules');
            return of(fetchActivationModulesForSubscription.failure());
          })
        );
    })
  );

const selectCurrentSubscriptionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(selectActivationManagerSubscription)),
    switchMap(({ payload }) => {
      return [
        fetchActivationModulesForSubscription.request(payload)
      ];
    })
  );

const createActivationModuleEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(createActivationModuleData.request)),
    switchMap(({ payload }) => {
      return from(internalApi.createActivationModule(payload))
        .pipe(
          switchMap(() => {
            return [
              push('/activation-manager')
            ];
          }),
          catchError(() => {
            toast.error('Cannot create module');
            return of(createActivationModuleData.failure());
          })
        );
    })
  );

const deleteActivationModuleDataEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(deleteActivationModuleData.request)),
    withLatestFrom(store$),
    switchMap(([{ payload }, state]) => {
      return from(internalApi.deleteActivationModule({ _id: payload }))
        .pipe(
          switchMap(() => {
            const subscription = selectCurrentActivationManagerSubscription(state);
            return [
              fetchActivationModulesForSubscription.request(subscription as Subscription)
            ];
          }),
          catchError(() => {
            toast.error('Error deleting activation module');
            return of(deleteActivationModuleData.failure());
          })
        );
    })
  );

const deleteActivationModuleEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(deleteActivationModule)),
    switchMap(({ payload }) => {
      return [
        deleteActivationModuleData.request(payload)
      ];
    })
  );

const updateActivationModuleDataEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(updateActivationModuleData.request)),
    switchMap(({ payload }) => {
      return of(internalApi.updateActivationModule(payload))
        .pipe(
          switchMap(() => {
            return [
              push('/activation-manager')
            ];
          }),
          catchError(() => {
            toast.error('Error updating module');
            return of(updateActivationModuleData.failure());
          })
        );
    })
  );

const saveActivationModuleEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(saveActivationModule)),
    switchMap(({ payload, meta }) => {
      return meta.mode === SAVE_MODE.CREATE ?
        [createActivationModuleData.request(payload)] :
        [updateActivationModuleData.request(payload)];
    })
  );

const initializeActivationManagerPageEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(initializeActivationManagerPage)),
    withLatestFrom(store$),
    switchMap(([_, state]) => {
      const subscription = selectCurrentActivationManagerSubscription(state);
      return subscription ? [fetchActivationModulesForSubscription.request(subscription)] : [];
    })
  );

export const activationManagerEpics = [
  fetchActivationModulesForSubscriptionEpic,
  selectCurrentSubscriptionEpic,
  saveActivationModuleEpic,
  createActivationModuleEpic,
  deleteActivationModuleEpic,
  deleteActivationModuleDataEpic,
  initializeActivationManagerPageEpic,
  updateActivationModuleDataEpic
];
