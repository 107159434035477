import { createSelector, createStructuredSelector } from 'reselect';

import { RootState } from '../../../store/root';
import { SelectOption } from '../../../types/util';

import { AccountEntity, AccountManagerState, SectorEntity, JurisdictionEntity } from './types';

const selectAccountManagerState = (state: RootState): AccountManagerState => state.accountManager;

export const selectFormattedAccountList = createSelector(
  [selectAccountManagerState],
  (accountManager: AccountManagerState) => accountManager.accountList.map((account) => {
    return {
      ...account,
      jurisdiction: account.jurisdiction || 'N/A',
      doNotEmail: account.doNotEmail ? 'true' : ''
    };
  })
);

export const selectAccount = createSelector<RootState, AccountManagerState, AccountEntity>(
  [selectAccountManagerState],
  (accountManager: AccountManagerState) => accountManager.currentAccount
);

const selectSectors = createSelector<RootState, AccountManagerState, SectorEntity[]>(
  [selectAccountManagerState],
  (accountManager: AccountManagerState) => accountManager.sectors
);

const selectJurisdictions = createSelector<RootState, AccountManagerState, JurisdictionEntity[]>(
  [selectAccountManagerState],
  (accountManager: AccountManagerState) => accountManager.jurisdictions
);

const mapNameFieldToSelectOption = ({ name }: { name: string; }) => ({ value: name, label: name });

export const selectSectorsAsSelectOptions = createSelector<RootState, SectorEntity[], Array<SelectOption<string>>>(
  [selectSectors],
  (sectors) => sectors.map(mapNameFieldToSelectOption)
);

export const selectJurisdictionsAsSelectOptions = createSelector<RootState, JurisdictionEntity[], Array<SelectOption<string>>>(
  [selectJurisdictions],
  (jurisdictions) => jurisdictions.map(mapNameFieldToSelectOption)
);

export const accountManagerConnector = createStructuredSelector<RootState, Omit<AccountManagerState, 'accountList'>>({
  currentAccount: selectAccount,
  sectors: selectSectors,
  jurisdictions: selectJurisdictions
});
