import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';

import * as domainManagerActions from './actions';
import { DomainManagerState } from './types';

export const domainManagerInitialState = {
  domainList: [],
  domainsById: {},
  featureList: [],
  selectedProduct: null,
  selectedDomain: null
};

export const domainManagerReducer = createReducer<DomainManagerState, RootAction>(domainManagerInitialState)
  .handleAction(domainManagerActions.getDomainList.success, (state, action) => {
    return {
      ...state,
      domainList: action.payload,
      domainsById: action.payload.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {})
    };
  })
  .handleAction(domainManagerActions.setFeaturesList, (state, action) => {
    return {
      ...state,
      featureList: action.payload
    };
  })
  .handleAction(domainManagerActions.setSelectedProduct, (state, action) => {
    return {
      ...state,
      selectedProduct: action.payload
    };
  })
  .handleAction(domainManagerActions.setSelectedDomain, (state, action) => {
    return {
      ...state,
      selectedDomain: action.payload
    };
  });
