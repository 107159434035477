import { Typography, Box, Grid, Button } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { findValueFromObject, sectionNumberPropGetter } from '../../../lib/util';
import { getDomainList } from '../../domain-manager/state/actions';
import { selectDomainList } from '../../domain-manager/state/selectors';
import { fetchRelatedStatuteSectionsByDomain, deleteRelatedStatuteSection } from '../state/actions';
import { selectStatuteSections } from '../state/selectors';
import { StatuteSection } from '../types';

import AddRelatedStatuteSectionModal from './AddRelatedStatuteSectionModal';
import { RelatedStatuteSectionsDataTable } from './RelatedStatuteSectionsDataTable';

type TableColumn = {
  name: keyof StatuteSection,
  title: string
};

const columns: TableColumn[] = [
  {
    name: 'type',
    title: 'Type'
  },
  {
    name: 'country',
    title: 'Country'
  },
  {
    name: 'title',
    title: 'Title'
  },
  {
    name: 'sectionNumber',
    title: 'Section'
  }
];

type RouteParams = {
  domainName: string;
};

type RelatedStatuteSectionsListProps = RouteComponentProps<RouteParams>;

const RelatedStatuteSectionsList: React.FC<RelatedStatuteSectionsListProps> = ({ match }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getDomainList.request());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fetchRelatedStatuteSectionsByDomain.request(match.params.domainName));
  }, [dispatch, match.params.domainName]);

  const domains = useSelector(selectDomainList);
  const foundDomain = React.useMemo(() => {
    return findValueFromObject('_id', match.params.domainName, domains);
  }, [domains, match.params.domainName]);

  const [isAddRelatedSectionModalOpen, setIsAddRelatedSectionModalOpen] = React.useState<boolean>(false);

  const statuteSections = useSelector(selectStatuteSections);
  return foundDomain ? (
    <>
      <Grid container={true} justify="space-between">
        <Grid item={true}>
          <Box pb={3}>
            <Typography variant="h6">
              <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
              <BreadcrumbLink to={'/domains'}>Domains</BreadcrumbLink> / Related Statute Sections List for&nbsp;
              <strong>{foundDomain && foundDomain.id}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item={true}>
          <Button onClick={() => setIsAddRelatedSectionModalOpen(true)}>Add Related Section</Button>
        </Grid>
      </Grid>
      <MainContainerCardComponent>
        <RelatedStatuteSectionsDataTable
          columns={columns}
          data={statuteSections}
          actions={[
            {
              label: 'Delete',
              onClick: (id) => dispatch(deleteRelatedStatuteSection.request(id))
            }
          ]}
          idPropGetter={sectionNumberPropGetter}
        />
      </MainContainerCardComponent>
      <AddRelatedStatuteSectionModal isOpen={isAddRelatedSectionModalOpen} setIsOpen={setIsAddRelatedSectionModalOpen} domain={foundDomain._id} />
    </>
  ) : (
    <>
    </>
  );
};

export default RelatedStatuteSectionsList;
