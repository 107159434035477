import { Grid, Button } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { TextField } from '../../../components/form/TextField';
import { Modal } from '../../../components/ui/Modal';
import { genericIdPropGetter } from '../../../lib/util';
import { RootState } from '../../../store/root';
import { CaseEntity } from '../../../types/case-types';
import { ModalState } from '../../../types/util';
import { CaseSearchDataTable } from '../components/CaseSearchDataTable';
import { searchCuratorCases } from '../state/actions';
import { selectCuratorSearchResults } from '../state/selectors';

type CaseSearchTableColumn = {
  name: keyof CaseEntity,
  title: string
};

const columns: CaseSearchTableColumn[] = [
  {
    name: 'id',
    title: 'ID'
  },
  {
    name: 'title',
    title: 'Title'
  },
  {
    name: 'judgementDate',
    title: 'Judgement Date'
  },
  {
    name: 'citation',
    title: 'Citation'
  }
];

const mapStateToProps = (state: RootState) => ({
  searchedCases: selectCuratorSearchResults(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  searchCases: (searchText: string) => dispatch(searchCuratorCases.request({title: searchText}))
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = {
  modalState: ModalState,
  handleSelect: (curatorId: string) => void
};

type LinkHelperContainerProps = DispatchProps & StateProps & OwnProps;

type ComponentProps = {
  searchText: string
};

class CaseSearchContainerComponent extends React.Component<LinkHelperContainerProps, ComponentProps> {
  constructor(props: LinkHelperContainerProps) {
    super(props);
    this.state = {
      searchText: ''
    };
  }

  handleChange = (text: string) => {
    this.setState({
      ...this.state,
      searchText: text
    });
  }

  handleSearch = () => {
    this.props.searchCases(this.state.searchText);
  }

  render() {
    return (
      <Modal title={'Find Case'} onCancel={this.props.modalState.handleCancel} open={this.props.modalState.open}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={3} alignItems={'center'}>
              <Grid item={true} xs={10}>
                <TextField label={'Case Title'} value={this.state.searchText} onChange={this.handleChange} />
              </Grid>
              <Grid container={true} item={true} xs={2} justify={'center'}>
                <Button variant={'contained'} onClick={this.handleSearch}>Find Case</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <CaseSearchDataTable
              columns={columns}
              data={this.props.searchedCases}
              idPropGetter={genericIdPropGetter}
              tableColumnExtensions={[
                {
                  columnName: 'title',
                  width: '40%',
                  wordWrapEnabled: true
                },
                {
                  columnName: 'action',
                  width: '12%'
                },
                {
                  columnName: 'id',
                  width: '12%'
                }
              ]}
              actions={[
                {
                  label: 'Select',
                  onClick: this.props.handleSelect
                }
              ]}
            />
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

export const CaseSearchContainer = connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(CaseSearchContainerComponent);
