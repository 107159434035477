import { Grid, Button, Box, InputLabel } from '@material-ui/core';
import React from 'react';
import { TextField } from '../../../components/form/TextField';

import { SimilarCaseQuestionConfig, QuestionType } from '../../../types/core';

export type Props = {
  similarDecisionsWeights: SimilarCaseQuestionConfig[];
  handleChange: (questionId: string, field: keyof SimilarCaseQuestionConfig, value: string) => void,
  handleSubmit: () => void;
};

export const SimilarDecisionsWeightsForm: React.FC<Props> = ({ similarDecisionsWeights, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      {
        similarDecisionsWeights.map((config) => (
          <Grid container={true} spacing={2} key={config.id}>
            <Grid item={true} xs={6}>
              <InputLabel htmlFor={config.id}>
                {config.id}
              </InputLabel>
              <TextField id={config.id} defaultValue={config.point} onChange={(input) => handleChange(config.id, 'point', input)} />
            </Grid>
            {
              config.questionType === QuestionType.TextInput && (
                <>
                  <Grid item={true} xs={3}>
                    <InputLabel htmlFor="Near %">
                      Near %
                    </InputLabel>
                    <TextField id={`Near % - ${config.id}`} defaultValue={config.near} onChange={(input) => handleChange(config.id, 'near', input)} />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <InputLabel htmlFor="Far %">
                      Far %
                    </InputLabel>
                    <TextField id={`Far % - ${config.id}`} defaultValue={config.far} onChange={(input) => handleChange(config.id, 'far', input)} />
                  </Grid>
                </>
              )
            }
          </Grid>
        ))
      }
      <Grid container={true} justify="flex-end">
        <Box>
          <Button color="primary" variant="contained" size="large" onClick={handleSubmit}>Submit</Button>
        </Box>
      </Grid>
    </form>
  );
};
