import { baseTheme } from '@bluejlegal/design-system';
import { MuiThemeProvider, createMuiTheme, Container } from '@material-ui/core';
import { blue, orange } from '@material-ui/core/colors';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

import { GlobalHeaderComponent } from './components/GlobalHeaderComponent';
import { getAppConfig } from './config';
import './index.css';
import { AccountManagerModule } from './modules/account-manager';
import { ActivationManagerModule } from './modules/activation-manager';
import { AuthHandler } from './modules/auth/AuthHandler';
import { CodedCaseManagerContainer } from './modules/coded-case-manager/containers/coded-case-manager.container';
import { DomainManagerModule } from './modules/domain-manager';
import { ErrorBoundaryContainer } from './modules/errors/containers/ErrorBoundary';
import { FeatureAvailabilityModule } from './modules/feature-availability';
import { LandingModule } from './modules/landing';
import { MLServiceModule } from './modules/ml-service-manager';
import { NoticeManagerModule } from './modules/notice-manager';
import { QuestionReferenceManagerModule } from './modules/question-reference-manager';
import { TagManagerModule } from './modules/tag-manager';
import { createServices } from './services';
import { configureStore, appHistory, initializeInternalStore } from './store/root';

const appConfig = getAppConfig();

export const services = createServices(appConfig);
const store = configureStore(services);

initializeInternalStore(store);

ReactDOM.render(
  (
    <Provider store={store}>
      <ThemeProvider theme={baseTheme}>
        <MuiThemeProvider theme={createMuiTheme({
          palette: {
            primary: blue,
            secondary: orange
          }
        })}>
          <ConnectedRouter history={appHistory}>
            <ToastContainer
              transition={Slide}
            />
            <GlobalHeaderComponent />
            <ErrorBoundaryContainer />
            <Container maxWidth="xl">
              <Switch>
                <Route exact={true} path="/" component={LandingModule} />
                <Route path="/activation-manager" component={ActivationManagerModule} />
                <Route path="/coded-case-manager" component={CodedCaseManagerContainer} />
                <Route path="/notice-manager" component={NoticeManagerModule} />
                <Route path="/domains" component={DomainManagerModule} />
                <Route path="/auth" component={AuthHandler} />
                <Route path="/question-reference-manager" component={QuestionReferenceManagerModule} />
                <Route path="/feature-availability" component={FeatureAvailabilityModule} />
                <Route path="/ml-service/:feature?" component={MLServiceModule} />
                <Route path="/account-manager" component={AccountManagerModule} />
                <Route path="/tag-manager" component={TagManagerModule} />
              </Switch>
            </Container>
          </ConnectedRouter>
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  ),
  document.getElementById('root'));
