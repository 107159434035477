import { Box, Typography } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AsyncSelectField } from '../../../components/form/AsyncSelectField';
import { EntitySelector } from '../../../components/form/EntitySelector';
import { Modal } from '../../../components/ui/Modal';
import { services } from '../../../index';
import { SelectOption } from '../../../types/util';
import { fetchAvailableStatuteDocuments, submitRelatedStatuteSection } from '../state/actions';
import { selectAvailableDocumentsAsSelectOption, selectStatuteSections } from '../state/selectors';

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  domain: string
};

const AddRelatedStatuteSectionModal: React.FC<Props> = ({ isOpen, setIsOpen, domain }) => {
  const [chosenDocument, setChosenDocument] = React.useState<SelectOption<string> | null>(null);
  const [chosenStatute, setChosenStatute] = React.useState<SelectOption<string> | null>(null);

  const documents = useSelector(selectAvailableDocumentsAsSelectOption);
  const statuteSections = useSelector(selectStatuteSections);

  const fetchStatuteSearchResults = useCallback(async (searchTerm) => {
    if (chosenDocument) {
      const response = await services.bookcase.searchSectionsFromDocument(chosenDocument.value)({ q: searchTerm });
      return response.map((d) => ({ value: d.number, label: d.number }));
    }
  }, [chosenDocument]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAvailableStatuteDocuments.request());
  }, [dispatch]);

  const doesSectionExist = useMemo(() => {
    return statuteSections.some((section) => section.sectionNumber === chosenStatute?.value);
  }, [chosenStatute, statuteSections]);

  const isSubmitDisabled = useMemo(() => {
    return !chosenStatute || doesSectionExist;
  }, [chosenStatute, doesSectionExist]);

  const handleClear = useCallback(() => {
    setIsOpen(false);
    setChosenStatute(null);
    setChosenDocument(null);
  }, [setIsOpen]);

  const handleSubmit = useCallback(() => {
    if (chosenDocument && chosenStatute) {
      dispatch(submitRelatedStatuteSection.request({
        documentId: chosenDocument.value,
        sectionNumber: chosenStatute.value,
        domain
      }));
      handleClear();
    }
  }, [chosenDocument, chosenStatute, dispatch, domain, handleClear]);

  return (
    <Modal
      title="Add Related Statute"
      onCancel={() => setIsOpen(false)}
      open={isOpen}
      onConfirm={handleSubmit}
      isConfirmButtonDisabled={isSubmitDisabled}
    >
      <Box width="600px" height="300px">
        <Box mb={1}>
          <Typography>Choose a Document</Typography>
        </Box>
        <Box mb={2}>
          <EntitySelector
            handleChange={(chosen) => setChosenDocument(chosen)}
            value={chosenDocument}
            objectList={documents}
            title="Document"
          />
        </Box>
        <Box mb={1}>
          <Typography>Choose a Section</Typography>
        </Box>
        <Box mb={2}>
          <AsyncSelectField
            asyncLoadOptions={fetchStatuteSearchResults}
            value={chosenStatute}
            onChange={(chosen) => setChosenStatute(chosen)}
            isClearable={true}
            maxMenuHeight={130}
          />
        </Box>
        { doesSectionExist && (
          <Typography color="secondary">The chosen section is already on the related statute section list.</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default AddRelatedStatuteSectionModal;
