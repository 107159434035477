import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createMLService } from '../../state/actions';
import { CreateMLServiceConfig, MLServiceType, QuestionListItem } from '../../types';

import { MLServiceCreateForm } from './MLServiceCreateForm';
import { NewMLServicePreForm } from './NewMLServicePreForm';

type Props = {
  feature: string;
  availableQuestions: QuestionListItem[];
  defaultServiceExists: boolean;
  onCancel: () => void;
};

export const CreateNewMLService: React.FunctionComponent<Props> = ({ defaultServiceExists, onCancel, feature, availableQuestions }) => {
  const dispatch = useDispatch();

  const [serviceType, setServiceType] = useState<MLServiceType>();
  const [serviceName, setServiceName] = useState<string>('');
  const [preFormComplete, setPreFormComplete] = useState<boolean>(false);

  const onPreFormSubmit = () => {
    if (serviceType && serviceName) {
      setPreFormComplete(true);
    }
  };

  const onCreate = (createMLServicePayload: CreateMLServiceConfig) => {
    dispatch(createMLService(createMLServicePayload));
  };

  return preFormComplete ? (
    <MLServiceCreateForm
      type={serviceType as MLServiceType}
      defaultAllowed={!defaultServiceExists}
      name={serviceName}
      feature={feature}
      availableQuestions={availableQuestions}
      onCreate={onCreate}
      onCancel={onCancel}
    />
  ) : (
    <NewMLServicePreForm
      name={serviceName}
      type={serviceType}
      onTypeChange={setServiceType}
      onNameChange={setServiceName}
      onSubmit={onPreFormSubmit}
      onCancel={onCancel}
    />
  );
};
