import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';
import { FeatureEntity } from '../../../types/core';

export const selectBaseState = (state: RootState) => state.base;

export const selectFeatures = createSelector(
  selectBaseState,
  (baseState) => baseState.features || []
);

export const selectFeaturesMappedById = createSelector(
  selectFeatures,
  (features) => features.reduce<Record<FeatureEntity['feature'], FeatureEntity>>((featureMap, nextFeature) => {
    featureMap[nextFeature.feature] = nextFeature;
    return featureMap;
  }, {})
);
