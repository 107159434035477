import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import * as React from 'react';

type ModalProps = {
  title: string,
  onConfirm?: () => void,
  onCancel: () => void,
  open: boolean,
  children?: any,
  isConfirmButtonDisabled?: boolean
};

export const Modal: React.FC<ModalProps> = (
  {
    title,
    onConfirm,
    onCancel,
    open,
    children,
    isConfirmButtonDisabled
  }
) => {
  return (
    <Dialog maxWidth={'lg'} open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {onConfirm && <Button onClick={onConfirm} color="primary" disabled={isConfirmButtonDisabled}>Confirm</Button>}
        <Button onClick={onCancel} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
