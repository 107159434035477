import { Box, Button, Dialog, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearAllMLServiceMessages } from '../state/actions';
import { selectMLConfigMessages } from '../state/selectors';

export const MLServiceMessageModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const messages = useSelector(selectMLConfigMessages);
  const onClose = useCallback(() => dispatch(clearAllMLServiceMessages()), [dispatch]);

  if (messages.length === 0) {
    return null;
  }

  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={onClose}
    >
      <Box padding={4}>
        <Typography variant="h5">Message</Typography>
        <Box marginTop={4}>
          {messages.map((message, i) => (
            <Box key={i} alignItems="baseline" display="flex">
              <Typography><strong>{message}</strong></Typography>
            </Box>
          ))}
        </Box>
        <Box display="flex" marginTop={5}>
          <Button onClick={onClose} variant="contained">
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
