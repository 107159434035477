import { push } from 'connected-react-router';
import * as R from 'ramda';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';

import {
  deleteAccount,
  getAccount,
  getAccounts,
  createAccount,
  editAccount,
  getSectors,
  getJurisdictions
} from './actions';

const fetchAccountList: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(getAccounts.request)),
    switchMap((action) => {
      return from(internalApi.getAllAccounts(null, action.payload)).pipe(
        map((response) => {
          return getAccounts.success(R.sortBy(R.compose(R.toLower, R.prop('name')))(response));
        }),
        catchError((err) => of(getAccounts.failure(err)))
      );
    })
  );

const postDeleteAccount: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(deleteAccount.request)),
    switchMap((action) => {
      return from(internalApi.deleteAccount({ account: action.payload.account }, action.payload.env)).pipe(
        switchMap(() => [
          deleteAccount.success(),
          push(`/account-manager/${action.payload.env}`)
        ]),
        catchError((err) => of(deleteAccount.failure(err)))
        );
      }
    )
  );

const fetchAccount: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(getAccount.request)),
    switchMap((action) => {
      return from(internalApi.getAccount({ account: action.payload.account }, action.payload.env)).pipe(
        map((response) => {
          return getAccount.success(response);
        }),
        catchError((err) => of(getAccount.failure(err)))
      );
    })
  );

const createAccountEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(createAccount.request)),
    switchMap((action) => {
      return from(internalApi.createAccount(action.payload.accountData, action.payload.env)).pipe(
        switchMap(() => {
          return [createAccount.success(), push(`/account-manager/${action.payload.env}`)];
        }),
        catchError((err) => of(getAccount.failure(err)))
      );
    })
  );

const editAccountEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(editAccount.request)),
    switchMap((action) => {
      return from(internalApi.editAccount(action.payload.accountData, action.payload.env)).pipe(
        switchMap(() => {
          return [editAccount.success(), push(`/account-manager/${action.payload.env}`)];
        }),
        catchError((err) => of(editAccount.failure(err)))
      );
    })
  );

const fetchSectorsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(getSectors.request)),
    switchMap(() => {
      return from(internalApi.getSectors()).pipe(
        map((res) => {
          return getSectors.success(res);
        }),
        catchError((err) => of(getSectors.failure(err)))
      );
    })
  );

  const fetchJurisdictionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(getJurisdictions.request)),
    switchMap(() => {
      return from(internalApi.getJurisdictions()).pipe(
        map((res) => {
          return getJurisdictions.success(res);
        }),
        catchError((err) => of(getJurisdictions.failure(err)))
      );
    })
  );

export const accountManagerEpics = [
  fetchAccountList,
  postDeleteAccount,
  fetchAccount,
  createAccountEpic,
  editAccountEpic,
  fetchSectorsEpic,
  fetchJurisdictionsEpic
];
