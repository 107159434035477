import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';
import { TranslatedString } from '../../../types/util';

import * as translatedStringsActions from './actions';

type TranslatedStringsState = {
  strings: TranslatedString
};

const translatedStringsInitialState: TranslatedStringsState = {
  strings: {}
};

export const translatedStringsReducer = createReducer<TranslatedStringsState, RootAction>(translatedStringsInitialState)
  .handleAction(translatedStringsActions.getStringsFromIds.success, (state, action) => {
    return {
      ...state,
      strings: action.payload
    };
  });
