import { Box } from '@material-ui/core';
import * as React from 'react';
import { Switch, Route } from 'react-router';

import { ActionManagerListContainer } from './activation-manager-list.container';
import { AddActivationModuleContainer } from './add-activation-module.container';
import { EditActivationModuleContainer } from './edit-activation-module.container';

export const  ActivationManagerContainer: React.FC = () => {
  return (
    <Box p={2}>
      <Switch>
        <Route path="/activation-manager/" exact={true} component={ActionManagerListContainer} />
        <Route path="/activation-manager/create" component={AddActivationModuleContainer} />
        <Route path="/activation-manager/:id"  component={EditActivationModuleContainer} />
      </Switch>
    </Box>
  );
};
