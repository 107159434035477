import { stringify } from 'query-string';

import { UnauthorizedError } from '../lib/errors/UnauthorizedError';
import { AvailableStatuteDocument, SearchStatuteResult } from '../modules/related-statute-sections-manager/types';
import {
  Tag,
  CreateTagPayload,
  EditTagPayload,
  TaggedItem,
  CreateUpdateTaggedItemPayload,
  GetTagsForItemSuccess
} from '../modules/tag-manager/types';
import { Method } from '../types/query-types';

export class Bookcase {
  public getAllAvailableDocuments = this.createStandardRequest<AvailableStatuteDocument[]>('documents/availability', 'GET');
  public searchSectionsFromDocument = this.createStandardRequestWithURLParams<SearchStatuteResult[], { q: string; }>('documents', 'sections/search');
  public getTags = this.createStandardRequest<Tag[]>('tags', 'GET');
  public createTag = this.createStandardRequest<Tag, CreateTagPayload>('tags', 'POST');

  private token = '';
  constructor(
    public readonly apiUrl: string
  ) {
  }

  public editTag = (id: string) => this.createStandardRequest<Tag, EditTagPayload>(`tags/${id}`, 'PUT');
  public deleteTag = (id: string) => this.createStandardRequest<Tag>(`tags/${id}`, 'DELETE');
  public getTagsForItem = (id: string) => this.createStandardRequest<GetTagsForItemSuccess>(`tags/items/${id}`, 'GET');
  public updateItemTags = (id: string) => this.createStandardRequest<TaggedItem, CreateUpdateTaggedItemPayload>(`tags/items/${id}`, 'POST');

  public setToken(token: string) {
    this.token = token;
  }

  protected baseHeaders(): Record<string, string> {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    };
  }

  protected apiFetch(url: string, requestParams: RequestInit): Promise<Response> {
    return fetch(`${this.apiUrl}${url}`, {
      ...requestParams,
      headers: {
        ...this.baseHeaders(),
        ...(requestParams.headers || {})
      }
    }).then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          throw new UnauthorizedError(response.statusText);
        }

        throw new Error(response.statusText);
      }
      return response;
    });
  }

  protected createStandardFetch<TResponse, TQuery extends Record<string, any>>(relativeUri: string, method: Method) {
    return async (payload?: TQuery): Promise<TResponse> => {
      const requestParams: RequestInit = {
        method
      };

      let url = relativeUri;

      if (payload !== undefined) {
        if (method === 'POST' || method === 'PUT') {
          requestParams.body = JSON.stringify(payload);
        } else if (method === 'GET') {
          url = `${url}?${stringify(payload)}`;
        }
      }

      const response = await this.apiFetch(url, requestParams);
      return response.status === 204 || response.status === 404 ? null : await response.json();
    };
  }

  protected createStandardRequestWithURLParams<TResponse, TQuery = null>(apiSubRoute: string, postUrlRoute?: string) {
    return (urlParam: string) => this.createStandardFetch<TResponse, TQuery>(`/${apiSubRoute}/${urlParam}${postUrlRoute ? `/${postUrlRoute}` : ''}`, 'GET');
  }

  protected createStandardRequest<TResponse, TQuery = null>(apiSubRoute: string, method: Method) {
    return this.createStandardFetch<TResponse, TQuery>(`/${apiSubRoute}`, method);
  }
}
