import * as React from 'react';

import { SelectOption } from '../../types/util';

import { FormButtons } from './FormButtons';
import { SelectField } from './SelectField';

type EntitySelectorProps = {
  handleChange: (input: SelectOption) => void,
  value: SelectOption | null,
  handleSubmit?: () => void,
  handleClear?: () => void,
  objectList: SelectOption[],
  title: string,
  isClearable?: boolean;
  disabled?: boolean;
};

export const EntitySelector: React.FC<EntitySelectorProps> = (
  {
    handleChange,
    value,
    handleSubmit,
    handleClear,
    objectList,
    title,
    isClearable = true,
    disabled = false
  }
) => {
  return (
    <>
      <SelectField
        placeholder={title}
        value={value}
        handleChange={(input: SelectOption) => handleChange(input)}
        isSearchable={true}
        options={objectList}
        isClearable={isClearable}
        isDisabled={disabled}
      />
      {handleClear && handleSubmit &&
        <FormButtons handleSubmit={handleSubmit} handleClear={handleClear} />}
    </>
  );
};
