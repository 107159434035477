import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';
import { MLServiceState } from '../types';

import * as mlServiceActions from './actions';

const INITIAL_STATE: MLServiceState = {
  createOpened: false,
  dataVersion: 0,
  services: [],
  promoteTargetServices: [],
  availableQuestions: [],
  errorStack: [],
  messages: []
};

export const mlServiceReducer = createReducer<MLServiceState, RootAction>(INITIAL_STATE)
  .handleAction(mlServiceActions.fetchMLServices.success, (state, { payload }) => ({
    ...state,
    services: payload.services,
    availableQuestions: payload.availableQuestions
  }))
  .handleAction(mlServiceActions.fetchPromoteTargetMLServices.success, (state, { payload }) => ({
    ...state,
    promoteTargetServices: payload.services
  }))
  .handleAction(mlServiceActions.clearMLServices, (state) => ({
    ...state,
    services: INITIAL_STATE.services,
    availableQuestions: INITIAL_STATE.availableQuestions
  }))
  .handleAction(mlServiceActions.deleteMLService, (state, { payload }) => ({
    ...state,
    deleteInProgress: payload
  }))
  .handleAction([
    mlServiceActions.cancelDeleteMLService,
    mlServiceActions.confirmDeleteMLService
  ], ({ deleteInProgress, ...state }) => state)
  .handleAction(mlServiceActions.promoteAllMLServices, (state) => ({
    ...state,
    promoteAllInProgress: true
  }))
  .handleAction([
    mlServiceActions.cancelPromoteAllMLServices,
    mlServiceActions.confirmPromoteAllMLServices
  ], ({ promoteAllInProgress, ...state }) => state)
  .handleAction(mlServiceActions.promoteFeatureMLServices, (state, { payload }) => ({
    ...state,
    promoteFeatureInProgress: payload
  }))
  .handleAction([
    mlServiceActions.cancelPromoteFeatureMLServices,
    mlServiceActions.confirmPromoteFeatureMLServices
  ], ({ promoteFeatureInProgress, ...state }) => state)
  .handleAction(mlServiceActions.refreshMLServiceListing, (state) => ({
    ...state,
    dataVersion: state.dataVersion + 1
  }))
  .handleAction(mlServiceActions.openCreateMLService, (state) => ({
    ...state,
    createOpened: true
  }))
  .handleAction(mlServiceActions.closeCreateMLService, (state) => ({
    ...state,
    createOpened: false
  }))
  .handleAction([
    mlServiceActions.fetchMLServices.failure,
    mlServiceActions.postUpdateMLService.failure,
    mlServiceActions.postCreateMLService.failure,
    mlServiceActions.postDeleteMLService.failure,
    mlServiceActions.postPromoteFeatureMLServices.failure,
    mlServiceActions.postPromoteAllMLServices.failure,
  ], (state, { payload }) => ({
    ...state,
    errorStack: state.errorStack.concat(payload)
  }))
  .handleAction(mlServiceActions.clearAllMLServiceErrors, (state) => ({
    ...state,
    errorStack: []
  }))
  .handleAction(mlServiceActions.showMLServiceMessage, (state, { payload }) => ({
    ...state,
    messages: state.messages.concat(payload)
  }))
  .handleAction(mlServiceActions.clearAllMLServiceMessages, (state) => ({
    ...state,
    messages: []
  }));
