import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';

import { CodedCaseManagerState } from './types';

export const selectCaseManagerState = (state: RootState): CodedCaseManagerState => state.codedCaseManager;

export const selectModalState = createSelector(
  selectCaseManagerState,
  (state) => state.modal
);
