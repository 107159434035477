import { Role } from '../../types/core';

const MODULE_NAVIGATION_ITEMS = [
  {
    name: 'Domain List',
    path: '/domains',
    description: 'A list of domains and their associated functionality'
  },
  {
    name: 'Activation Manager',
    path: '/activation-manager',
    description: 'Manage the activation modules for the users'
  },
  {
    name: 'Notice Manager',
    path: '/notice-manager',
    description: 'Manage the notice module for the associated subscription'
  },
  {
    name: 'Coded Case Manager',
    path: '/coded-case-manager',
    description: 'Management of coded cases'
  },
  {
    name: 'Tag Manager',
    path: '/tag-manager',
    description: 'Manage tags'
  }
];

const ACCOUNT_NAVIGATION_ITEMS = [
  {
    name: 'Live/Production Account Manager',
    path: '/account-manager/live',
    description: 'A list of Production accounts and the ability to delete them',
    styles: {
      color: '#AF2225'
    }
  },
  {
    name: 'Beta/Internal Account Manager',
    path: '/account-manager/beta',
    description: 'A list of Beta accounts and the ability to delete them'
  }
];

const ADMIN_NAVIGATION_ITEMS = [
  {
    name: 'Feature Availability',
    path: '/feature-availability',
    description: 'Manage feature availability'
  },
  {
    name: 'ML Service Configuration',
    path: '/ml-service',
    description: 'Manage the configuration of the ML Services'
  }
];

export const NAVIGATION_ITEMS = [
  {
    name: 'App Managers',
    items: MODULE_NAVIGATION_ITEMS,
    roles: [Role.DATA_ENTRY, Role.INTERNAL]
  },
  {
    name: 'Account Managers',
    items: ACCOUNT_NAVIGATION_ITEMS,
    roles: [Role.SUPPORT, Role.ADMIN]
  },
  {
    name: 'Admin Managers',
    items: ADMIN_NAVIGATION_ITEMS,
    roles: [Role.ADMIN]
  }
];
