import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { RootState } from '../../../store/root';
import { selectUser } from '../../auth/state/selectors';
import { ModuleListComponent } from '../components/ModuleListComponent';

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state)
});

type StateProps = ReturnType<typeof mapStateToProps>;

type ModulesContainerProps = StateProps;

class ModulesContainerComponent extends React.Component<ModulesContainerProps> {
  render() {
    return (
      <Wrapper>
        <ModuleListComponent roles={this.props.user.roles} />
      </Wrapper>
    );
  }
}

export const ModulesContainer = connect(mapStateToProps)(ModulesContainerComponent);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: flex-start;
  flex: 2;
  margin: 0 20px;
  padding-top: 2rem;
  flex-wrap: wrap;
`;
