import { createSelector } from 'reselect';

import { ApiError } from '../../../lib/errors/ApiError';
import { RootState } from '../../../store/root';

import { ErrorHandlingState } from './reducer';

export const selectErrorHandlingState = (state: RootState) => state.errorHandling;

export const selectError = createSelector<RootState, ErrorHandlingState, ApiError | undefined>(
  [selectErrorHandlingState],
  (errorHandling) => errorHandling.error
);
