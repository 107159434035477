import { Typography, Box } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { findValueFromObject, mongoIdPropGetter } from '../../../lib/util';
import { RootState } from '../../../store/root';
import { selectFeatureList } from '../../domain-manager/state/selectors';
import { TranslatedStringContainer } from '../../translation/containers/TranslatedStringContainer';
import { QuestionListDataTable } from '../components/QuestionListDataTable';
import { getQuestionsForFeature } from '../state/actions';
import { selectQuestionList } from '../state/selectors';
import { QuestionEntity } from '../state/types';

type QuestionTableColumn = {
  name: keyof QuestionEntity,
  title: string,
  getCellValue?: (row: QuestionEntity) => any
};

const columns: QuestionTableColumn[] = [
  {
    name: 'id',
    title: 'ID'
  },
  {
    name: 'displayId',
    title: 'Display ID'
  },
  {
    name: 'prompt',
    title: 'Question Prompt',
    getCellValue: (row) => {
      return <TranslatedStringContainer id={row.prompt} />;
    }
  },
  {
    name: 'numReferences',
    title: '# References'
  }
];

const mapDispatchToProps = (dispatch: Dispatch) => ({
  pushQuestionReferencesList: (questionId: string) => dispatch(push(`/question-reference-manager/${questionId}`)),
  getQuestionList: (featureName: string) => dispatch(getQuestionsForFeature.request(featureName))
});

const mapStateToProps = (state: RootState) => ({
  featureList: selectFeatureList(state),
  questionList: selectQuestionList(state)
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type RouteParams = {
  featureName: string
};

type QuestionListProps = StateProps & DispatchProps & RouteComponentProps<RouteParams>;

class QuestionListComponent extends React.Component<QuestionListProps> {
  componentDidMount(): void {
    this.props.getQuestionList(this.props.match.params.featureName);
  }

  componentDidUpdate(prevProps: QuestionListProps): void {
    if (this.props.match.params.featureName !== prevProps.match.params.featureName) {
      this.props.getQuestionList(this.props.match.params.featureName);
    }
  }

  render() {
    const feature = findValueFromObject('_id', this.props.match.params.featureName, this.props.featureList);

    return (
      <>
        <Box pb={3}>
          <Typography variant="h6">
            <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={'/domains'}>Domains</BreadcrumbLink> / Question List for&nbsp;
            <strong>{feature && feature.feature}</strong>
          </Typography>
        </Box>
        <MainContainerCardComponent>
          {
            this.props.questionList &&
            (
              <QuestionListDataTable
                idPropGetter={mongoIdPropGetter}
                columns={columns}
                data={this.props.questionList}
                actions={[
                  {
                    label: 'References',
                    onClick: this.props.pushQuestionReferencesList
                  }
                ]}
                tableColumnExtensions={[
                  {
                    columnName: 'id',
                    width: '12%'
                  },
                  {
                    columnName: 'displayId',
                    width: '12%'
                  },
                  {
                    columnName: 'prompt',
                    wordWrapEnabled: true,
                    width: '50%'
                  },
                  {
                    columnName: 'numReferences',
                    width: '10%'
                  },
                  {
                    columnName: 'actions',
                    width: '16%'
                  }
                ]}
              />
            )
          }
        </MainContainerCardComponent>
      </>
    );
  }
}

export const QuestionListContainer =
  connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(QuestionListComponent);
