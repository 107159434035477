import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EntitySelector } from '../../../components/form/EntitySelector';
import { Modal } from '../../../components/ui/Modal';
import { SelectOption } from '../../../types/util';
import { fetchFeatures } from '../../base/state/actions';
import { selectClassifiersFlowsListAsSelectedOption } from '../../domain-manager/state/selectors';
import { getQuestionsForFeature, duplicateQuestionReference } from '../state/actions';
import {
  selectCurrentFeatureAsSelectOption,
  selectQuestionIdsAndPromptsAsDropdownList
} from '../state/selectors';

type Props = {
  referenceId: string;
  open: boolean;
  handleClose: () => void;
  title: string;
};

export const DuplicateQuestionReference: React.FC<Props> = ({ open, handleClose, title, referenceId }) => {
  const questionIds = useSelector(selectQuestionIdsAndPromptsAsDropdownList);
  const featureList = useSelector(selectClassifiersFlowsListAsSelectedOption);
  const currentFeature = useSelector(selectCurrentFeatureAsSelectOption);
  const [questionId, setQuestionId] = useState<SelectOption | null>(null);
  const [feature, setFeature] = useState<SelectOption | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFeatures.request());
  }, [dispatch]);

  useEffect(() => {
    if (feature !== null) {
      dispatch(getQuestionsForFeature.request(feature.value));
    }
  }, [dispatch, feature]);

  useEffect(() => {
    if (currentFeature !== null) {
      setFeature(currentFeature);
    }
  }, [currentFeature]);

  const handleSubmit = () => {
    if (questionId && feature) {
      dispatch(duplicateQuestionReference.request({ questionId: questionId.value, feature: feature.value, referenceId }));
    }
    handleClose();
  };

  return (
    <Modal
      title={`Duplicate Reference ${referenceId} - ${title}?`}
      open={open}
      onCancel={handleClose}
      onConfirm={handleSubmit}
    >
      <Box width="500px" height="500px">
        <Box width="100%" mb={3}>
          <EntitySelector
            title="Choose a question"
            value={questionId}
            handleChange={(chosen) => setQuestionId(chosen)}
            objectList={questionIds}
            isClearable={false}
          />
        </Box>
        <Box>
          <EntitySelector
            handleChange={(value) => setFeature(value)}
            value={feature}
            objectList={featureList}
            title="Choose a feature"
          />
        </Box>
      </Box>
    </Modal>
  );
};
