import { Box, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { NoticeFormComponent } from '../components/notice-form.component';
import { saveNoticeModule } from '../state/actions';
import { selectCurrentNoticeManagerSubscription } from '../state/selectors';
import { NoticeEntity, SAVE_MODE } from '../state/types';

export const AddNoticeContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [noticeModule, setNoticeModule] = React.useState<Partial<NoticeEntity>>({ enabled: false });
  const subscription = useSelector(selectCurrentNoticeManagerSubscription);
  const handleChange = (field: string, value: string | boolean | number) => {
    const updatedNoticeModule = {
      ...noticeModule,
      [field]: value
    };
    setNoticeModule(updatedNoticeModule);
  };

  React.useEffect(() => {
    if (!subscription) {
      dispatch(push('/notice-manager'));
    }
  }, [subscription, dispatch]);

  const submitNotice = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    const moduleWithSubscription = {
      ...noticeModule,
      subscription
    } as NoticeEntity;

    dispatch(saveNoticeModule(moduleWithSubscription, { mode: SAVE_MODE.CREATE }));
  }, [dispatch, noticeModule, subscription]);

  return (
    <>
      <Box pb={3}>
        <Typography variant="h6">
          <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
            <BreadcrumbLink to={'/notice-manager'}>Notice Manager </BreadcrumbLink>
            / New Notice Module
          </Typography>
      </Box>
      <MainContainerCardComponent>
        <NoticeFormComponent
          handleChange={handleChange}
          handleSubmit={submitNotice}
          noticeModule={noticeModule as NoticeEntity}
          subscription={subscription!}
        />
      </MainContainerCardComponent>
    </>
  );
}
