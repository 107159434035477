import * as R from 'ramda';
import { createSelector } from 'reselect';

import { findValueFromObject } from '../../../lib/util';
import { RootState } from '../../../store/root';
import { FeatureEntity, FeatureType, Subscription } from '../../../types/core';
import { SelectOption } from '../../../types/util';

import { DomainEntity, DomainManagerState, DomainSubscriptionEntity } from './types';

export const selectDomainManagerState = (state: RootState): DomainManagerState => state.domainManager;

export const selectSelectedDomain = createSelector<RootState, DomainManagerState, SelectOption | null>(
  [selectDomainManagerState],
  (domainManager) => domainManager.selectedDomain
);

export const selectSelectedProduct = createSelector<RootState, DomainManagerState, SelectOption | null>(
  [selectDomainManagerState],
  (domainManager) => domainManager.selectedProduct
);

export const selectDomainList = createSelector<RootState, DomainManagerState, DomainEntity[]>(
  [selectDomainManagerState],
  (domainManager) => domainManager.domainList
);

export const selectDomainsById = createSelector<RootState, DomainManagerState, Record<string, DomainEntity>>(
  selectDomainManagerState,
  (state) => state.domainsById
);

export const selectDomainListSubscriptions = createSelector<RootState, DomainEntity[], DomainSubscriptionEntity[]>(
  [selectDomainList],
  (domainList) => {
    return domainList.map((domain) => {
      return {
        domain: domain._id,
        subscriptions: R.uniq(R.flatten(R.pluck('subscriptions')(domain.features)))
      };
    });
  }
);

export const selectDomainListAsSelectedOption = createSelector<RootState, DomainEntity[], SelectOption[]>(
  [selectDomainList],
  (domainList) => domainList.map((domain) => ({value: domain._id, label: domain.id}))
);

export const selectFilteredDomainListByDomain = createSelector(
  [selectDomainList, selectSelectedDomain],
  (domainList, selectedDomain): DomainEntity[] => {
    if (selectedDomain) {
      const domain = findValueFromObject<DomainEntity>('_id', selectedDomain.value, domainList);
      return domain ? [domain] : [];
    }
    return [];
  }
);

export const selectFilteredDomainListByProduct = createSelector<RootState, DomainEntity[], SelectOption | null, DomainEntity[]>(
  [selectDomainList, selectSelectedProduct],
  (domainList, selectedProduct) => selectedProduct ?
    R.filter((domain) => {
      const subscriptions = R.flatten(R.pluck('subscriptions')(domain.features));
      return subscriptions.includes(selectedProduct.value as Subscription);
    }, domainList) : []
);

export const selectFilteredDomainList = (state: RootState) => {
  return selectSelectedDomain(state) ?
    selectFilteredDomainListByDomain(state) :
    selectSelectedProduct(state) ?
      selectFilteredDomainListByProduct(state) :
      selectDomainList(state);
};

export const selectFeatureList = createSelector<RootState, DomainManagerState, FeatureEntity[]>(
  [selectDomainManagerState],
  (domainManager) => domainManager.featureList
);

export const selectClassifiersFlowsListAsSelectedOption = createSelector<RootState, FeatureEntity[], SelectOption[]>(
  [selectFeatureList],
  (featureList) =>
    R.filter((feature) => feature.featureType === FeatureType.Classifier || feature.featureType === FeatureType.Flow || feature.featureType === FeatureType.Calculator, featureList)
      .map((feature) => ({ value: feature._id, label: feature.feature }))
);

export const createSelectDomainById = (domainId: string) => createSelector(
  selectDomainsById,
  (domains) => domains[domainId]
);
