import { AppConfig } from '../config';

import { Bookcase } from './Bookcase';
import { CuratorApi } from './CuratorApi';
import { InternalApi } from './InternalApi';

export type Services = {
  internalApi: InternalApi,
  curatorApi: CuratorApi,
  bookcase: Bookcase
};

export function createServices(appConfig: AppConfig): Services {
  return {
    internalApi: new InternalApi(appConfig.apiUrl),
    curatorApi: new CuratorApi(appConfig.curatorApiUrl),
    bookcase: new Bookcase(appConfig.bookcaseUrl)
  };
}
