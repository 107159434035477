import { createReducer } from 'typesafe-actions';

import { ApiError } from '../../../lib/errors/ApiError';
import { RootAction } from '../../../store/actions';

import * as errorHandlingActions from './actions';

export type ErrorHandlingState = {
  error: ApiError | undefined
};

export const errorHandlingInitialState: ErrorHandlingState = {
  error: undefined
};

export const errorHandlingReducer = createReducer<ErrorHandlingState, RootAction>(errorHandlingInitialState)
  .handleAction(errorHandlingActions.storeGenericError, (state, action) => {
    return {
      ...state,
      error: action.payload
    };
  })
  .handleAction(errorHandlingActions.clearGenericError, (state) => {
    return {
      ...state,
      error: undefined
    };
  });
