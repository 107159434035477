import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';

import { NoticeManagerState } from './types';

const selectNoticeManagerState = (state: RootState): NoticeManagerState => state.noticeManager;

export const selectNoticeManagerModules = createSelector(
  selectNoticeManagerState,
  (noticeManagerState) => noticeManagerState.noticeModules
);

export const selectCurrentNoticeManagerSubscription = createSelector(
  selectNoticeManagerState,
  (noticeManagerState) => noticeManagerState.subscription
);
