import * as React from 'react';

import {
  AzureFormValues,
  BaseFormValues,
  MLServiceType,
  NoMLServiceFormValues,
  ProphetFormValues,
  QuestionListItem
} from '../../../types';

import { AzureServiceTypeForm } from './AzureServiceTypeForm';
import { NoMLServiceTypeForm } from './NoMLServiceTypeForm';
import { ProphetServiceTypeForm } from './ProphetServiceTypeForm';

export type SpecificFormProps<TFormValues> = {
  formValues: TFormValues,
  onChange: (values: TFormValues) => void,
  availableQuestions: QuestionListItem[]
};

export function createMLServiceTypeForm<TFormValues extends BaseFormValues>(serviceType: MLServiceType): React.FunctionComponent<SpecificFormProps<TFormValues>> {
  return (props) => {
    switch (serviceType) {
      case MLServiceType.Azure:
        const azureProps = props as SpecificFormProps<AzureFormValues>;
        return <AzureServiceTypeForm {...azureProps} />;
      case MLServiceType.Prophet:
        const prophetProps = props as SpecificFormProps<ProphetFormValues>;
        return <ProphetServiceTypeForm {...prophetProps} />;
      case MLServiceType.NoService:
        const noMLProps = props as SpecificFormProps<NoMLServiceFormValues>;
        return <NoMLServiceTypeForm {...noMLProps} />;
    }
  };
}
