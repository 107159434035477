import { Box, Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntitySelector } from '../../../components/form/EntitySelector';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCardComponent';
import { Modal } from '../../../components/ui/Modal';
import { findValueFromObject, mongoIdPropGetter } from '../../../lib/util';
import { Subscription } from '../../../types/core';
import { SelectOption } from '../../../types/util';
import { NoticeListTable } from '../components/notice-table.component';
import { deleteNoticeModule, initializeNoticeManagerPage, selectNoticeManagerSubscription } from '../state/actions';

import { subscriptionOptions } from '../state/constants';
import { selectCurrentNoticeManagerSubscription, selectNoticeManagerModules } from '../state/selectors';
import { NoticeEntity } from '../state/types';

type NoticeListColumn = {
  name: keyof NoticeEntity,
  title: string,
  getCellValue?: (row: NoticeEntity) => any
};

const columns: NoticeListColumn[] = [
  {
    name: 'title',
    title: 'Title'
  },
  {
    name: 'body',
    title: 'Body'
  },
];

export const NoticeListContainerComponent: React.FC = () => {
  const [selectedSubscriptionOption, setSelectedSubscriptionOption] = React.useState<SelectOption | null>(null);
  const noticeModuleData = useSelector(selectNoticeManagerModules);
  const selectedSubscription = useSelector(selectCurrentNoticeManagerSubscription);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(initializeNoticeManagerPage());
  }, [dispatch]);

  React.useEffect(() => {
    if (selectedSubscription) {
      setSelectedSubscriptionOption({
        label: selectedSubscription,
        value: selectedSubscription
      });
    }
  }, [dispatch, selectedSubscription]);

  const selectSubscriptionCallback = React.useCallback((selectValue: SelectOption) => {
    setSelectedSubscriptionOption(selectValue);
    dispatch(selectNoticeManagerSubscription(selectValue.value as Subscription));
  }, [dispatch]);

  const deleteNoticeModuleCallback = React.useCallback((id: string) => {
    dispatch(deleteNoticeModule(id));
  }, [dispatch]);

  const newNoticeCallback = React.useCallback(() => {
    dispatch(push(`${window.location.pathname}/create`));
  }, [dispatch]);

  const editNoticeModuleCallback = React.useCallback((id: string) => {
    dispatch(push(`${window.location.pathname}/${id}`));
  }, [dispatch]);

  const [deleteModal, setDeleteModal] = React.useState({ open: false, id: '', title: '' });

  const handleDelete = (referenceId: string) => {
    const noticeModule = findValueFromObject('_id', referenceId, noticeModuleData);
    if (noticeModule) {
      setDeleteModal({ open: true, id: referenceId, title: noticeModule.title });
    }
  };

  const handleCancel = () => {
    setDeleteModal({ open: false, id: '', title: '' });
  };

  const handleConfirm = () => {
    deleteNoticeModuleCallback(deleteModal.id);
    setDeleteModal({ open: false, id: '', title: '' });
  };

  const deleteModalTitle = `Are you sure you want to delete ${deleteModal.id} - ${deleteModal.title}?`;

  return (
    <Box p={2}>
      <Box p={2}>
        <EntitySelector
          isClearable={false}
          title="Change Subscription"
          handleChange={selectSubscriptionCallback}
          value={selectedSubscriptionOption}
          objectList={subscriptionOptions}
        />
      </Box>
      <MainContainerCardComponent>
        <Box p={3}>
          <Button disabled={!selectedSubscription || noticeModuleData.length >= 1} variant="contained" color="primary" onClick={newNoticeCallback}>New Notice</Button>
        </Box>
        <Box>
          <Box>
            <NoticeListTable
              columns={columns}
              data={noticeModuleData}
              idPropGetter={mongoIdPropGetter}
              actions={[
                {
                  label: 'Edit',
                  onClick: editNoticeModuleCallback
                },
                {
                  label: 'Delete',
                  onClick: handleDelete,
                  color: 'default'
                }
              ]}
            />
            <Box>
              <Modal title={deleteModalTitle} onConfirm={handleConfirm} onCancel={handleCancel} open={deleteModal.open} />
            </Box>
          </Box>
        </Box>
      </MainContainerCardComponent>
    </Box>
  )
};
