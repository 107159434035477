import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';

import { selectNoticeManagerSubscription, fetchNoticeModulesForSubscription } from './actions';
import { NoticeManagerState } from './types';

const noticeManagerInitialState = {
  subscription: null,
  noticeModules: []
};

export const noticeManagerReducer = createReducer<NoticeManagerState, RootAction>(noticeManagerInitialState)
  .handleAction(selectNoticeManagerSubscription, (state, { payload }) => {
    return {
      ...state,
      subscription: payload
    };
  }).handleAction(fetchNoticeModulesForSubscription.success, (state, { payload }) => {
    return {
      ...state,
      noticeModules: payload
    };
  });
