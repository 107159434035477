import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';

import { AuthState, User } from './types';

const selectAuthState = (state: RootState): AuthState => state.auth;

export const selectUser = createSelector<RootState, AuthState, User>(
  [selectAuthState],
  (auth: AuthState) => auth.user
);
