import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';
import { BaseState } from '../types';

import * as baseActions from './actions';

const INITIAL_STATE: BaseState = {
  features: []
};

export const baseReducer = createReducer<BaseState, RootAction>(INITIAL_STATE)
  .handleAction(baseActions.fetchFeatures.success, (state, { payload }) => ({
    ...state,
    features: payload
  }));
