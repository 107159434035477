
import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import { from, of, timer } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';
import { fetchActivationModulesForSubscription } from '../../activation-manager/state/actions';

import { endPollingJobId, exportAllCodedCases, exportAllCodedCasesRequest, pollJobIdData, showCodedCaseModal, startPollingJobId } from './actions';

export const pollJobIdDataEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(pollJobIdData.request)),
    switchMap(({ payload }) => {
      return from(internalApi.pollJobId(payload)()).pipe(
        switchMap((response) => {
          const actions: any[] = [];
          if (response.status !== 'finished') {
            return response.status === 'error' ?
            [showCodedCaseModal({title: `Job - ${payload}`, message: `Error exporting CSV: ${response.result}`})] :
            [];
          }
          actions.push(endPollingJobId());
          if (response.downloadLink) {
            window.location.href = response.downloadLink;
          }
          actions.push(showCodedCaseModal({title: `Job - ${payload}`, message: 'The job completed successfully'}));
          return actions;
        }),
        catchError((err) => {
          return switchMap( () => ([
            showCodedCaseModal({title: `Job - ${payload}`, message: `Error polling the status of the export job ${err.message}`}),
            endPollingJobId()
          ]));
        })
      );
    })
  );

const startPollingIntervalEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(startPollingJobId)),
    mergeMap(({ payload }) => {
      return timer(0, 5000).pipe(
        switchMap(() => [ pollJobIdData.request(payload) ]),
        takeUntil(action$.ofType(endPollingJobId))
      );
    })
  );

export const exportCodedCasesRequestEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(exportAllCodedCasesRequest.request)),
    switchMap(() => {
      return from(internalApi.exportCodedCases())
        .pipe(
          switchMap((response) => {
            return [
              startPollingJobId(response.jobId),
              showCodedCaseModal({title: `Job Started - ${response.jobId}`, message: 'Please wait, this will take a while'})
            ];
          }),
          catchError(() => {
            toast.error('Cannot Export coded cases');
            return of(fetchActivationModulesForSubscription.failure());
          })
        );
    })
  );

export const exportCodedCasesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isActionOf(exportAllCodedCases)),
    switchMap(() => {
      return [
        exportAllCodedCasesRequest.request()
      ];
    })
  );

export const codedCaseManagerEpics = [
  exportCodedCasesEpic,
  exportCodedCasesRequestEpic,
  startPollingIntervalEpic,
  pollJobIdDataEpic
];
