import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';
import { fetchFeatures } from '../../base/state/actions';

import {
  closeCreateMLService,
  confirmDeleteMLService,
  confirmPromoteAllMLServices,
  confirmPromoteFeatureMLServices,
  createMLService,
  fetchMLServices,
  fetchPromoteTargetMLServices,
  initMLServicesFeatureList,
  loadMLServicesForFeature,
  postCreateMLService,
  postDeleteMLService,
  postPromoteAllMLServices,
  postPromoteFeatureMLServices,
  postUpdateMLService,
  refreshMLServiceListing,
  showMLServiceMessage,
  updateMLService
} from './actions';
import { selectMLServiceConfigs } from './selectors';

export const loadMLServiceFeatures: Epic<RootAction, RootAction, RootState, Services> =
  (action$) => action$.pipe(
    filter(isActionOf(initMLServicesFeatureList)),
    switchMap(() => [
      fetchFeatures.request()
    ])
  );

export const loadMLServiceConfigs: Epic<RootAction, RootAction, RootState, Services> =
  (action$) => action$.pipe(
    filter(isActionOf(loadMLServicesForFeature)),
    switchMap(({ payload: feature }) => [
      fetchMLServices.request(feature),
      fetchPromoteTargetMLServices.request(feature)
    ])
  );

export const fetchAllMLServiceConfigsEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(fetchMLServices.request)),
      switchMap(({ payload: feature }) => {
        if (!feature) {
          return [];
        }
        return from(internalApi.getMLServiceListing(feature)).pipe(
          map((data) => fetchMLServices.success(data)),
          catchError((error) => of(fetchMLServices.failure(error)))
        );
      })
    );

export const fetchPromoteTargetMLServiceConfigsEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(fetchPromoteTargetMLServices.request)),
      switchMap(({ payload: feature }) => {
        if (!feature) {
          return [];
        }
        return from(internalApi.getPromoteTargetMLServiceListing(feature)).pipe(
          map((data) => fetchPromoteTargetMLServices.success(data)),
          catchError((error) => of(fetchPromoteTargetMLServices.failure(error)))
        );
      })
    );

export const createMLServiceRequestEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(postCreateMLService.request)),
      switchMap(({ payload }) =>
        from(internalApi.createMLService(payload)).pipe(
          switchMap((response) => [
            postCreateMLService.success(response),
            closeCreateMLService(),
            refreshMLServiceListing()
          ]),
          catchError((error) => of(postCreateMLService.failure(error)))
        )
      )
    );

export const createMLServiceEpic: Epic<RootAction, RootAction> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(createMLService)),
      map(({ payload }) => postCreateMLService.request(payload))
    );

export const updateMLServiceRequestEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(postUpdateMLService.request)),
      switchMap(({ payload }) => from(internalApi.updateMLService(payload)).pipe(
        switchMap((response) => [
          postUpdateMLService.success(response),
          refreshMLServiceListing()
        ]),
        catchError((error) => of(postUpdateMLService.failure(error)))
      ))
    );

export const updateMLServiceEpic: Epic<RootAction, RootAction, RootState> =
  (action$, store$) =>
    action$.pipe(
      filter(isActionOf(updateMLService)),
      withLatestFrom(store$),
      map(([{ payload }, state]) => {
        // prevent multiple defaults (at the UI level)
        if (payload.isDefault) {
          const otherServices = selectMLServiceConfigs(state).filter((service) => !(service.feature === payload.feature && service.name === payload.name));
          if (otherServices.some(({ isDefault }) => !!isDefault)) {
            return postUpdateMLService.request({ ...payload, isDefault: false });
          }
        }
        return postUpdateMLService.request(payload);
      })
    );

export const deleteMLServiceRequestEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(postDeleteMLService.request)),
      switchMap(({ payload }) => from(internalApi.deleteMLService(payload)).pipe(
        switchMap((response) => [
          postDeleteMLService.success(response),
          showMLServiceMessage(`ML Service with _id: ${payload._id} successfully deleted`),
          refreshMLServiceListing()
        ]),
        catchError((error) => of(postDeleteMLService.failure(error)))
      ))
    );

export const confirmDeleteMLServiceEpic: Epic<RootAction, RootAction> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(confirmDeleteMLService)),
      map(({ payload: { _id } }) => postDeleteMLService.request({ _id }))
    );

export const promoteAllMLServicesRequestEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(postPromoteAllMLServices.request)),
      switchMap(() => from(internalApi.promoteAllMLServices()).pipe(
        switchMap((response) => [
          postPromoteAllMLServices.success(response),
          showMLServiceMessage(`Promote successfully complete for ALL features`)
        ]),
        catchError((error) => of(postPromoteAllMLServices.failure(error)))
      ))
    );

export const confirmPromoteAllMLServicesEpic: Epic<RootAction, RootAction> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(confirmPromoteAllMLServices)),
      map(() => postPromoteAllMLServices.request())
    );

export const promoteFeatureMLServicesRequestEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(postPromoteFeatureMLServices.request)),
      switchMap(({ payload }) => from(internalApi.promoteFeatureMLServices(payload)).pipe(
        switchMap((response) => [
          postPromoteFeatureMLServices.success(response),
          showMLServiceMessage(`Promote successfully complete for feature: ${payload.feature}`),
          refreshMLServiceListing()
        ]),
        catchError((error) => of(postPromoteFeatureMLServices.failure(error)))
      ))
    );

export const confirmPromoteFeatureMLServicesEpic: Epic<RootAction, RootAction> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(confirmPromoteFeatureMLServices)),
      map(({ payload }) => postPromoteFeatureMLServices.request(payload))
    );

export const mlServiceEpics = [
  loadMLServiceFeatures,
  loadMLServiceConfigs,
  fetchAllMLServiceConfigsEpic,
  createMLServiceRequestEpic,
  createMLServiceEpic,
  updateMLServiceRequestEpic,
  updateMLServiceEpic,
  deleteMLServiceRequestEpic,
  confirmDeleteMLServiceEpic,
  promoteAllMLServicesRequestEpic,
  confirmPromoteAllMLServicesEpic,
  promoteFeatureMLServicesRequestEpic,
  confirmPromoteFeatureMLServicesEpic,
  fetchPromoteTargetMLServiceConfigsEpic
];
