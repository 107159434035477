import { Box, Button, Divider } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { CheckCircle, HighlightOff } from '@material-ui/icons';
import * as R from 'ramda';
import React, { ComponentProps, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';

import { MLServiceConfig } from '../types';

import './MLServiceConfigDiff.css';

type Props = {
  mlServiceConfig: MLServiceConfig,
  promoteTargetConfig?: MLServiceConfig
};

const DiffButtonIcon: React.FunctionComponent<ComponentProps<typeof Button> & { noDiff: boolean }> = ({ noDiff, ...props }) => {
  const icon = noDiff ?
    <CheckCircle style={{ color: green[500] }} />
    : <HighlightOff style={{ color: red[500] }} />;

  return (
    <Button
      {...props}
      startIcon={icon}
    />
  );
};
export const MLServiceConfigDiff: React.FunctionComponent<Props> = (
  {
    mlServiceConfig,
    promoteTargetConfig
  }
) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const noDiff = R.equals(mlServiceConfig, promoteTargetConfig);

  const buttonText = expanded ? 'Hide Diff' :
    (noDiff ? 'No Diff to Prod' : 'Show Diff to Prod');

  return (
    <div>
      <Divider />
      <DiffButtonIcon
        noDiff={noDiff}
        onClick={() => setExpanded(!expanded)}
      >
        {buttonText}
      </DiffButtonIcon>
      {expanded && (
        <Box overflow="auto" className="diff-container">
          <ReactDiffViewer
            leftTitle={'PROD'}
            rightTitle={'Beta'}
            oldValue={JSON.stringify(promoteTargetConfig, null, 2)}
            newValue={JSON.stringify(mlServiceConfig, null, 2)}
            splitView={true}
            hideLineNumbers={true}
            showDiffOnly={false}
          />
        </Box>
      )}
      <Divider />
    </div>
  );
};
