import { stringify } from 'query-string';

import { ApiError } from '../lib/errors/ApiError';
import { UnauthorizedError } from '../lib/errors/UnauthorizedError';
import { AccountEntity, AccountForm, JurisdictionEntity, SectorEntity } from '../modules/account-manager/state/types';
import { ActivationEntity } from '../modules/activation-manager/state/types';
import { DomainEntity } from '../modules/domain-manager/state/types';
import { FeatureAvailabilityEntity, TransformedFeatureAvailabilityEntity } from '../modules/feature-availability/types';
import {
  CreateMLServiceConfig,
  DeleteMLServiceConfig,
  MLServicePromoteTargetResponse,
  MLServiceResponse,
  PromoteMLServiceFeature,
  UpdateMLServiceConfig
} from '../modules/ml-service-manager/types';
import { NoticeEntity } from '../modules/notice-manager/state/types';
import { QuestionEntity, QuestionReferenceEntity } from '../modules/question-reference-manager/state/types';
import {
  RelatedStatuteSection,
  BaseRelatedStatuteSection
} from '../modules/related-statute-sections-manager/types';
import { Environment, FeatureEntity, SimilarCaseQuestionConfig } from '../types/core';
import {
  AccountByIdQuery,
  DeleteActivationModuleQuery,
  DeleteNoticeModuleQuery,
  ExportCodedCaseResult,
  FeatureByNameQuery,
  GetActivationModulesBySubscriptionQuery,
  GetNoticeModulesBySubscriptionQuery,
  Method,
  PollJobIdRequest,
  PollJobIdResponse,
  QuestionDomainQuery,
  QuestionIdQuery,
  QuestionReferenceCreateQuery,
  QuestionReferenceEditQuery,
  StringQuery,
  StringResponseType
} from '../types/query-types';

export class InternalApi {
  public getAllDomains = this.createStandardRequest<DomainEntity[]>('api/domain-manager', 'get-domains', 'GET');
  public getAllStrings = this.createStandardRequest<StringResponseType, StringQuery>('api/string-manager', 'get-strings', 'POST');
  public getAllQuestionsFromDomain = this.createStandardRequest<QuestionEntity[], QuestionDomainQuery>('api/question-manager', 'get-questions', 'POST');
  public getQuestionInformationById = this.createStandardRequest<QuestionEntity, QuestionIdQuery>('api/question-manager', 'get-question', 'POST');
  public getFeatureByName = this.createStandardRequest<FeatureEntity, FeatureByNameQuery>('api/feature-manager', 'get-feature', 'POST');
  public getAllFeatures = this.createStandardRequest<FeatureEntity[]>('api/feature-manager', 'get-all-features', 'GET');
  public getAllQuestionReferencesForQuestion = this.createFetchStandardEntitiesById<QuestionReferenceEntity[], void>('bluejapp/question-reference', 'find', 'GET');
  public createNewQuestionReference = this.createStandardRequest<QuestionReferenceEntity, QuestionReferenceCreateQuery>('bluejapp/question-reference', 'create', 'POST');
  public deleteQuestionReference = this.createStandardRequest<number, Pick<QuestionReferenceEntity, 'id'>>('bluejapp/question-reference', 'delete', 'POST');
  public editQuestionReference = this.createStandardRequest<QuestionReferenceEntity, QuestionReferenceEditQuery>('bluejapp/question-reference', 'update', 'POST');
  public countQuestionReferencesByQuestion = this.createStandardRequest<Record<string, number>, { questionIds: string[] }>('bluejapp/question-reference', 'count-references', 'POST');
  public updateSimilarDecisionsWeights = this.createStandardRequest<FeatureEntity, { featureId: string; weights: SimilarCaseQuestionConfig[]; }>('api/feature-manager', 'update-similar-decisions-weights', 'POST');

  public getAllAccounts = this.createFetchStandardEntitiesByEnvironment<AccountEntity[]>('api/account-manager', 'get-accounts-with-user-count', 'GET');
  public deleteAccount = this.createFetchStandardEntitiesByEnvironment<void, AccountByIdQuery>('api/account-manager', 'delete-account', 'POST');
  public getAccount = this.createFetchStandardEntitiesByEnvironment<AccountEntity, AccountByIdQuery>('api/account-manager', 'get-account-with-user-count', 'POST');
  public createAccount = this.createFetchStandardEntitiesByEnvironment<AccountEntity, AccountForm>('api/account-manager', 'new-account', 'POST');
  public editAccount = this.createFetchStandardEntitiesByEnvironment<AccountEntity, AccountEntity>('api/account-manager', 'edit-account', 'POST');
  public getSectors = this.createFetchStandardEntitiesByEnvironment<SectorEntity[]>('api/account-manager', 'get-sectors', 'GET');
  public getJurisdictions = this.createFetchStandardEntitiesByEnvironment<JurisdictionEntity[]>('api/account-manager', 'get-jurisdictions', 'GET');

  public updateFeatureAvailabilitiesForEnvironment = this.createFetchStandardEntitiesByEnvironment<null, TransformedFeatureAvailabilityEntity[]>('bluejapp/feature-availability', 'update', 'POST');
  public getFeatureAvailabilitiesForEnvironment = this.createFetchStandardEntitiesByEnvironment<FeatureAvailabilityEntity[]>('bluejapp/feature-availability', 'all', 'GET');

  public fetchActivationModules = this.createStandardRequest<ActivationEntity[], GetActivationModulesBySubscriptionQuery>('bluejapp/activation-manager','get-activation-modules', 'POST');
  public createActivationModule = this.createStandardRequest<void, ActivationEntity>('bluejapp/activation-manager','create', 'POST');
  public deleteActivationModule = this.createStandardRequest<void, DeleteActivationModuleQuery>('bluejapp/activation-manager','delete', 'POST');
  public updateActivationModule = this.createStandardRequest<void, ActivationEntity>('bluejapp/activation-manager','update', 'POST');
  public fetchNoticeModules = this.createStandardRequest<NoticeEntity[], GetNoticeModulesBySubscriptionQuery>('bluejapp/notice-manager','get-notices', 'POST');
  public createNoticeModule = this.createStandardRequest<void, NoticeEntity>('bluejapp/notice-manager','create', 'POST');
  public deleteNoticeModule = this.createStandardRequest<void, DeleteNoticeModuleQuery>('bluejapp/notice-manager','delete', 'POST');
  public updateNoticeModule = this.createStandardRequest<void, NoticeEntity>('bluejapp/notice-manager','update', 'POST');

  public createMLService = this.createStandardRequest<void, CreateMLServiceConfig>(`bluejapp/ml-service`, 'create', 'POST');
  public updateMLService = this.createStandardRequest<void, UpdateMLServiceConfig>(`bluejapp/ml-service`, 'update', 'POST');
  public deleteMLService = this.createStandardRequest<void, DeleteMLServiceConfig>(`bluejapp/ml-service`, 'delete', 'POST');
  public promoteAllMLServices = this.createStandardRequest<void, void>('bluejapp/ml-service', 'promote-all', 'POST');
  public promoteFeatureMLServices = this.createStandardRequest<void, PromoteMLServiceFeature>('bluejapp/ml-service', 'promote', 'POST');
  public getMLServiceListing = this.createStandardParameterizedRequest<MLServiceResponse>('bluejapp/ml-service');
  public getPromoteTargetMLServiceListing = this.createStandardParameterizedRequest<MLServicePromoteTargetResponse>('bluejapp/ml-service/promote-target');

  // Related Statute Sections
  public createRelatedStatuteSection = this.createStandardRequest<RelatedStatuteSection, BaseRelatedStatuteSection>('bluejapp/related-statute-sections', 'create', 'POST');
  public updateRelatedStatuteSection = this.createStandardRequest<RelatedStatuteSection, BaseRelatedStatuteSection>('bluejapp/related-statute-sections', 'update', 'POST');
  public getRelatedStatuteSection = this.createStandardRequest<RelatedStatuteSection | null, { domain: string; }>('bluejapp/related-statute-sections', 'get', 'POST');

  // coded-case manager
  public exportCodedCases = this.createStandardRequest <ExportCodedCaseResult> ('bluejapp/coded-case-manager', 'export-coded-cases', 'GET');

  private internalApiToken: string = '';

  constructor(
    public readonly apiUrl: string
  ) {}
  public pollJobId = (jobId: string) => this.createStandardRequest<PollJobIdResponse, PollJobIdRequest>('bluejapp/coded-case-manager', `check-job?jobId=${jobId}`, 'GET');

  public setToken(token: string): void {
    this.internalApiToken = token;
  }

  protected baseHeaders(): Record<string, string> {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.internalApiToken}`
    };
  }

  protected async apiFetch(url: string, requestParams: RequestInit): Promise<Response> {
    const response = await fetch(`${this.apiUrl}${url}`, {
      ...requestParams,
      headers: {
        ...this.baseHeaders(),
        ...(requestParams.headers || {})
      }
    });

    if (!response.ok) {
      if (response.status === 401) {
        throw new UnauthorizedError(response.statusText);
      } else if (response.body) {
        const errorMessage = await response.json();
        throw new ApiError(response, errorMessage);
      }
    }
    return response;
  }

  protected createStandardFetch<TResponse, TQuery extends Record<string, any>>(relativeUri: string, method: Method) {
    return async (payload?: TQuery): Promise<TResponse> => {
      const requestParams: RequestInit = {
        method
      };

      let url = relativeUri;

      if (payload !== undefined) {
        if (method === 'POST') {
          requestParams.body = JSON.stringify(payload);
        } else if (method === 'GET') {
          url = `${url}${stringify(payload)}`;
        }
      }

      const response = await this.apiFetch(url, requestParams);
      return response.status === 204 ? null : await response.json();
    };
  }

  protected createFetchStandardEntitiesByEnvironment<TResponse, TQuery = null>(apiSubRoute: string, command: string, method: Method) {
    return (payload?: TQuery, env: Environment = Environment.BETA): Promise<TResponse> =>
      this.createStandardFetch<TResponse, TQuery>(`/${apiSubRoute}/${env}/${command}`, method)(payload);
  }

  protected createFetchStandardEntitiesById<TEntity, TQuery>(apiSubRoute: string, command: string, method: Method) {
    return async (entityId: string, payload?: TQuery): Promise<TEntity> =>
      this.createStandardFetch<TEntity, TQuery>(`/${apiSubRoute}/${command}/${entityId}`, method)(payload);
  }

  protected createStandardRequest<TResponse, TQuery = null>(apiSubRoute: string, command: string, method: Method) {
    return this.createStandardFetch<TResponse, TQuery>(`/${apiSubRoute}/${command}`, method);
  }

  protected createStandardParameterizedRequest<TResponse, TQuery = null>(apiSubRoute: string, command?: string, method: Method = 'GET') {
    return (param: string): Promise<TResponse> =>
      this.createStandardFetch<TResponse, TQuery>(`/${apiSubRoute}${command ? `/${command}`: ''}/${param}`, method)();
  }
}
