import { Box, Button, Paper } from "@material-ui/core";
import React, { useState } from "react";

import { FeatureAvailabilityConfirmationModal } from './FeatureAvailabilityConfirmationModal';
import { AvailabilityDiff } from "../types";
import { SelectOption } from "../../../types/util";


type Props = {
  commitChanges: () => void;
  confirmChanges: () => void;
  resetChanges: () => void;
  selectedEnvironment: SelectOption;
  updateDiff: AvailabilityDiff;
};

export const FeatureAvailabilityBottomToolbar: React.FunctionComponent<Props> = ({
  commitChanges,
  confirmChanges,
  resetChanges,
  selectedEnvironment,
  updateDiff
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const disabled = Object.keys(updateDiff.roles).length === 0 && Object.keys(updateDiff.enabledDisabled).length === 0;

  return (
    <Box position="fixed" bottom={0} zIndex={999} left={0} right={0}>
      <Paper>
        <Box display="flex" justifyContent="flex-end" p="20px 5px" pr="30px">
          <Box pr={1}>
            <Button
              color="primary"
              disabled={disabled}
              size="small"
              variant="contained"
              onClick={() => {
                commitChanges();
                setModalOpen(true);
              }}
            >
              Commit
            </Button>
          </Box>
          <Box>
            <Button
              disabled={disabled}
              size="small"
              variant="contained"
              onClick={() => resetChanges()}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Paper>

      <FeatureAvailabilityConfirmationModal
        confirmChanges={confirmChanges}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedEnvironment={selectedEnvironment}
        updateDiff={updateDiff}
      />
    </Box>
  );
};
