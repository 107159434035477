import {
  Box,
  Typography
} from '@material-ui/core';
import * as React from 'react';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';

type Props = {
  currentPage: string;
};

export const TagBreadcrumb: React.FC<Props> = ({ currentPage }) => {
  return (
    <Box pb={3}>
      <Typography variant="h6">
        <BreadcrumbLink to={'/'}>Home</BreadcrumbLink> /&nbsp;
        <BreadcrumbLink to={'/tag-manager'}>Tag Manager</BreadcrumbLink>
        &nbsp;/ {currentPage}
      </Typography>
    </Box>
  );
};
