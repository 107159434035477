import * as R from 'ramda';
import { createSelector } from 'reselect';

import { RootState } from '../../../store/root';

type TranslatedStringProps = {
  id?: string,
  language?: string
};

const selectTranslationState = (rootState: RootState) => rootState.translatedStringsManager;

export const selectTranslatedStrings = createSelector(
  selectTranslationState,
  (translatedState) => translatedState.strings
);

export const createSelectTranslatedString = (props: TranslatedStringProps) => createSelector(
  selectTranslatedStrings,
  (strings) => props.id ? R.pathOr(props.id, [props.id, props.language || 'en'], strings) : ''
);
