import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';

import { updateSimilarDecisionsWeights } from './actions';

const updateSimilarDecisionsWeightsEpic: Epic<RootAction, RootAction, RootState, Services> =
  (action$, _, { internalApi }) =>
    action$.pipe(
      filter(isActionOf(updateSimilarDecisionsWeights.request)),
      switchMap((action) => {
        return from(internalApi.updateSimilarDecisionsWeights({ featureId: action.payload.featureId, weights: action.payload.similarCaseWeights })).pipe(
          switchMap((res) => {
            toast.success('Similar decisions weights have successfully been updated');
            return [updateSimilarDecisionsWeights.success(res)];
          }),
          catchError((error) => {
            toast.error('Similar decisions weights has failed');
            return of(updateSimilarDecisionsWeights.failure(error));
          })
        );
      })
    );

export const similarDecisionsWeightsEpics = [
  updateSimilarDecisionsWeightsEpic
];
