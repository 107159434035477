import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';

import * as authActions from './actions';
import { AuthState } from './types';

const authReducerInitialState = {
  user: {
    email: '',
    roles: []
  }
};

export const authReducer = createReducer<AuthState, RootAction>(authReducerInitialState)
  .handleAction(authActions.setUserData, (state, action) => {
    return {
      ...state,
      user: action.payload
    };
  });
