import { createAsyncAction, createAction } from 'typesafe-actions';

import { Subscription } from '../../../types/core';
import { NoticeEntity, SAVE_MODE } from './types';

export const selectNoticeManagerSubscription = createAction(
  'BLUE_J_INTERNAL/NOTICE_MANAGER/SELECT_SUBSCRIPTION'
)<Subscription>();

export const initializeNoticeManagerPage = createAction(
  'BLUE_J_INTERNAL/NOTICE_MANAGER/INITIALIZE_NOTICE_MANAGER'
)<void>();

export const fetchNoticeModulesForSubscription = createAsyncAction(
  'BLUE_J_INTERNAL/NOTICE_MANAGER/FETCH_NOTICE_MODULES_FOR_SUBSCRIPTION/REQUEST',
  'BLUE_J_INTERNAL/NOTICE_MANAGER/FETCH_NOTICE_MODULES_FOR_SUBSCRIPTION/SUCCESS',
  'BLUE_J_INTERNAL/NOTICE_MANAGER/FETCH_NOTICE_MODULES_FOR_SUBSCRIPTION/FAILURE',
)<string, NoticeEntity[], void>();

export const deleteNoticeModule = createAction(
  'BLUE_J_INTERNAL/NOTICE_MANAGER/DELETE_NOTICE_MODULE'
)<string>();

export const deleteNoticeModuleData = createAsyncAction(
  'BLUE_J_INTERNAL/NOTICE_MANAGER/DELETE_NOTICE_MODULE_DATA/REQUEST',
  'BLUE_J_INTERNAL/NOTICE_MANAGER/DELETE_NOTICE_MODULE_DATA/SUCCESS',
  'BLUE_J_INTERNAL/NOTICE_MANAGER/DELETE_NOTICE_MODULE_DATA/FAILURE'
)<string, void, void>();

export const createNoticeModuleData = createAsyncAction(
  'BLUE_J_INTERNAL/NOTICE_MANAGER/CREATE_NOTICE_MODULE/REQUEST',
  'BLUE_J_INTERNAL/NOTICE_MANAGER/CREATE_NOTICE_MODULE/SUCCESS',
  'BLUE_J_INTERNAL/NOTICE_MANAGER/CREATE_NOTICE_MODULE/FAILURE'
)<NoticeEntity, void, void>();

export const updateNoticeModuleData = createAsyncAction(
  'BLUE_J_INTERNAL/NOTICE_MANAGER/UPDATE_NOTICE_MODULE/REQUEST',
  'BLUE_J_INTERNAL/NOTICE_MANAGER/UPDATE_NOTICE_MODULE/SUCCESS',
  'BLUE_J_INTERNAL/NOTICE_MANAGER/UPDATE_NOTICE_MODULE/FAILURE'
)<NoticeEntity, void, void>();

export const saveNoticeModule = createAction(
  'BLUE_J_INTERNAL/NOTICE_MANAGER/SAVE_NOTICE_MODULE'
)<NoticeEntity, { mode: SAVE_MODE }>();
