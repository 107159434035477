import { createReducer } from 'typesafe-actions';

import { RootAction } from '../../../store/actions';

import { resetCodedCaseModal, showCodedCaseModal } from './actions';
import { CodedCaseManagerState } from './types';

const initialState = {
  modal: {
    open: false,
    message: '',
    title: ''
  }
};

export const codedCaseManagerReducer = createReducer<CodedCaseManagerState, RootAction>(initialState)
.handleAction(showCodedCaseModal, (state, { payload }) => {
  return {
    ...state,
    modal: {
      open: true,
      ...payload
    }
  };
})
.handleAction(resetCodedCaseModal, (state) => {
  return {
    ...state,
    modal: initialState.modal
  };
});
