import * as R from 'ramda';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../../../store/actions';
import { RootState } from '../../../store/root';
import { fetchFeatures } from '../../base/state/actions';

import {
  changeEnvironment,
  confirmChanges,
  fetchFeatureAvailabilities,
  loadFeatureAvailabilityList,
  updateFeatureAvailabilities
} from './actions';

export const fetchAllFeatureAvailabilitiesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(fetchFeatureAvailabilities.request)),
    switchMap(({ payload }) =>
      from(internalApi.getFeatureAvailabilitiesForEnvironment(null, payload)).pipe(
        map(fetchFeatureAvailabilities.success),
        catchError((error) => of(fetchFeatureAvailabilities.failure(error)))
      )
    )
  );

export const loadFeatureAvailabilityListEpic: Epic<
  RootAction,
  RootAction
> = (action$) =>
  action$.pipe(
    filter(isActionOf(loadFeatureAvailabilityList)),
    switchMap(({ payload }) => [
      fetchFeatures.request(),
      fetchFeatureAvailabilities.request(payload)
    ])
  );

export const changeEnvironmentEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(changeEnvironment)),
    switchMap(({ payload }) => [
      fetchFeatures.request(),
      fetchFeatureAvailabilities.request(payload)
    ])
  );

export const confirmChangesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { internalApi }) =>
  action$.pipe(
    filter(isActionOf(confirmChanges)),
    withLatestFrom(state$),
    switchMap(([_, state]) => {
      const transformed = R.map(R.pick(['availability', 'feature']), Object.values(state.featureAvailability.availabilities.update));
      const environment = state.featureAvailability.selectedEnvironment;

      return from(internalApi.updateFeatureAvailabilitiesForEnvironment(transformed, environment)).pipe(
        map(updateFeatureAvailabilities.success),
        catchError((error) => of(updateFeatureAvailabilities.failure(error)))
      );
    }));

export const updateFeatureAvailabilitiesEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateFeatureAvailabilities.success)),
    withLatestFrom(state$),
    map(([_, state]) => loadFeatureAvailabilityList(state.featureAvailability.selectedEnvironment))
  );

export const featureAvailabilityEpics = [
  changeEnvironmentEpic,
  confirmChangesEpic,
  fetchAllFeatureAvailabilitiesEpic,
  loadFeatureAvailabilityListEpic,
  updateFeatureAvailabilitiesEpic
];
